import { createShape, ShapeProps } from './Shape';

export const Diamond = createShape(({ strokeWidth, className }: ShapeProps) => (
  <svg
    width="130"
    height="75"
    viewBox="0 0 130 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...{ strokeWidth, className }}>
    <path d="M0 37.4367L64.8438 74.8808L129.695 37.4367L64.8438 0L0 37.4367Z" fill="currentColor" />
  </svg>
));
