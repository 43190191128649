import Container from '@component/Container';
import { createBlock } from '@lib/api/fragments/blocks';
import { GraphEntry } from '@lib/api/parseGraphEntry';
import GraphBarStacked from './GraphBarStacked';
import GraphDoughnut from './GraphDoughnut';

const GraphBlock = createBlock<'infographicData_graph_BlockType'>(
  ({ graphSingle, _blockMeta: { first, inView = true } = {} }) => {
    return graphSingle ? (
      <Container marginTop={first ? undefined : '2xl'} marginBottom="2xl">
        {graphSingle.datasetType === 'single' ? (
          <GraphDoughnut show={inView} {...(graphSingle as GraphEntry<'single'>)} />
        ) : (
          <GraphBarStacked show={inView} {...(graphSingle as GraphEntry<'multiple'>)} />
        )}
      </Container>
    ) : (
      <></>
    );
  }
);

export type GraphProps = GraphEntry;

const Graph = (props?: GraphProps) => {
  return props?.datasetType === 'single' ? (
    <GraphDoughnut {...(props as GraphEntry<'single'>)} />
  ) : (
    <GraphBarStacked {...(props as GraphEntry<'multiple'>)} />
  );
};

Graph.Block = GraphBlock;

export default Graph;
