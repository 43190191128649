import "src/components/StyledImage/StyledImage.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/StyledImage/StyledImage.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62V23KbMBRF3/0VvHiGzATNObqL/Ej6iDGxaTAQLLeddPrv5eJiLkapnczwwICkpb3P1hHZZvH76wa83yvPK4tjatMiD70qySKb/kieVn9WpBuC7ZD3IM23ya/QQ3j6aAZtZ8RZWgZlZPehF6dVnCX+w2AMn4CjzbHITjZpFt8n6W5v628nWwymyPFGaLuRn+m2ITAgAhC0YZRL1IrJdfPXFmXocdG+V92i7SxbRfnxpagOYfdaS0ie/XrgcIvKwaOSKKOYFpKCQQGMtYwseekRDRrWS7RvfiBghNNjnBjQOBLDtDFKU2QClJrCNoW1xcHF8wPU60dPETqCRi6NSKSWikujlRGoQV08FTDTe9XSQI093bhqiIRRwQApcI2G6lnZ/skUYlHnsz+xNXYQDSND4NnXa0R0EfmYuL0zp/B/KZ3oSxw0KYjSDC+uLuWGuuQFdEzcfSKoH5wKP2BwJaV7l6OSyPn57v28SqnlPnrT85feG81OZG/lsrpZ7b5/STaXa+cHvLEThszXlnmIql2aB328m033Q3LHtlARDsAbDxRFVPxqhdFlwtT4woHjlJw5Z+i8l/e9b9EF1pR7Ai1djY8RQQ2vqZIrEFpPzminVtyi8e32njDSdXNbqD7TFi4tvl/PuvwyhKJGkMbUZnF1DkWXBOfle4yjrLkK8WEohY7vjNNyv2GCoBaD556LsUkHdu79BaFAGMQWCQAA\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var image = 'dlczkb3';
export var imageWrap = 'dlczkb1';
export var root = 'dlczkb0';
export var shapesLayout1 = {imageWrap:'dlczkb2',shapeSemicircles:'dlczkb6 dlczkb4',shapeSemicircleOutline:'dlczkb7 dlczkb4',shapeCirclesOutline:'dlczkb8 dlczkb4'};
export var shapesLayout2 = {imageWrap:'dlczkb2',shapeSemicircle:'dlczkba dlczkb4',shapeDiamond:'dlczkbb dlczkb4',shapeSquiggle:'dlczkbc dlczkb4'};
export var shapesLayout3 = {imageWrap:'',shapeSemicircles:'dlczkbd dlczkb4',shapeSquiggle:'dlczkbe dlczkb4'};
export var shapesLayoutFeature = {imageWrap:'dlczkbm',shapeSemicircles:'dlczkbn dlczkb4',shapeHelix:'dlczkbo dlczkb4'};
export var shapesLayoutGraph = {imageWrap:'dlczkb2',shapeSemicircle:'dlczkbt dlczkb4',shapeCirclesOutline:'dlczkbu dlczkb4'};
export var shapesLayoutHome = {imageWrap:'dlczkb2',shapeCirclesOutline:'dlczkbg dlczkb4',shapeSun:'dlczkbh dlczkb4',shapeDiamond:'dlczkbi dlczkb4',shapeSquiggle:'dlczkbj dlczkb4',shapeSquiggle1:'dlczkbk',shapeSquiggle2:'dlczkbl'};
export var shapesLayoutSearch = {imageWrap:'',shapeCircle:'dlczkbp dlczkb4',shapeSemicircles:'dlczkbq dlczkb4',shapeSquiggle:'dlczkbr dlczkb4'};