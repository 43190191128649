import { GraphEntry, parseGraphDataMultipleTotals } from '@lib/api/parseGraphEntry';
import { colorValues } from '@theme';
import { Chart as ChartJS, ChartDataset, Tooltip } from 'chart.js';
import { Bar, ChartProps } from 'react-chartjs-2';

import { toNumber } from '@liquorice/allsorts-craftcms-nextjs';
import { BarElement, CategoryScale, LinearScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GraphLegend } from './GraphLegend';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  // Title,
  Tooltip,
  ChartDataLabels
  // Legend
);

ChartJS.defaults.font.size = 16;

export type GraphBarStackedProps = GraphEntry<'multiple'>;

const GraphBarStacked = ({ show = this, ...props }: GraphBarStackedProps) => {
  const { labels, data = [] } = props;

  // Format the original data
  const outputData = data.reduce((result, dataset) => {
    result.push({
      label: dataset.label,
      data: dataset.values.map((v) => v ?? 0),
      backgroundColor: colorValues[dataset.color ?? 'green'],
      borderWidth: 0,
      borderRadius: 12,
    });

    return result;
  }, [] as ChartProps<'bar'>['data']['datasets']);

  // Add the totals for labels
  const totals = parseGraphDataMultipleTotals(data);

  const totalsLabel = 'totals';

  const totalsDataset: ChartDataset<'bar', number[]> = {
    label: totalsLabel,

    data: totals.map(() => 0),
    minBarLength: 30,
    backgroundColor: 'rgba(0,0,0,0)',
    datalabels: {
      color: colorValues.green,
      anchor: 'end',
    },
  };

  outputData.push(totalsDataset);

  const args: ChartProps<'bar'>['data'] = {
    labels,
    datasets: show ? outputData : [],
  };

  const plugins: ChartProps<'bar'>['plugins'] = [ChartDataLabels];
  const options: ChartProps<'bar'>['options'] = {
    animation: {},
    plugins: {
      datalabels: {
        color: '#FFFFFF',
        font: {
          family: 'GothamPro, Roboto, Arial, sans-serif',
          weight: 'bold',
        },
        formatter: function (value, context) {
          const isTotal = context.dataset.label === totalsLabel;
          if (isTotal) return totals[context.dataIndex];
          return toNumber(value) === 0 ? '' : value;
        },
      },
      // title: {
      //   display: true,
      //   text: 'Chart.js Bar Chart - Stacked',
      // },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },

    responsive: true,
    aspectRatio: 0.9,
    maintainAspectRatio: true,
    layout: {
      padding: {
        top: 50,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        stacked: true,

        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <>
      <Bar plugins={plugins} options={options} data={args} />
      <GraphLegend graph={props} bgcolor="white" rounded padding="lg" marginTop="lg" />
    </>
  );
};

export default GraphBarStacked;
