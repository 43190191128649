import Grid from '@component/Grid';
import BackgroundRibbon, { backgroundRibbonWrap } from '@component/BackgroundRibbon';
import Box from '@component/Box';
import Button from '@component/Button';
import Container from '@component/Container';
import Text from '@component/Text';
import { createBlock } from '@lib/api/fragments/blocks';
import { gql } from 'graphql-request';
import React from 'react';
import { Sprinkles } from '@theme/_sprinkles.css';
gql`
  fragment simpleCtaBlock on blocks_simpleCta_BlockType {
    __typename
    heading
    textAlignment
    content: htmlContentDefault
    children {
      ...linkCtaBlock
    }
  }
  fragment colouredBoxCtaBlock on blocks_colouredBoxCta_BlockType {
    __typename
    heading
    content: htmlContentDefault
    linkCta {
      ...linkField
    }
  }
  fragment largeCtaBlock on blocks_largeCta_BlockType {
    __typename
    heading
    linkCta {
      ...linkField
    }
  }
`;

export const SimpleCtaBlock = createBlock<'blocks_simpleCta_BlockType'>(
  ({ heading, content, children, textAlignment: maybeAlignment }) => {
    const textAlignment = maybeAlignment === 'auto' ? 'center' : maybeAlignment ?? 'center';

    let alignment: Sprinkles['justifyContent'] = 'center';

    switch (textAlignment) {
      case 'left':
        alignment = 'start';
        break;
      case 'right':
        alignment = 'end';
    }

    return (
      <Container textAlign={textAlignment} marginY="2xl">
        <Grid>
          <Grid.Col
            {...{
              lg: 10,
              offsetLg: alignment === 'center' ? 1 : 'none',
            }}>
            <Text color="green" variant="h3" bold component="h2">
              {heading}
            </Text>
            <Text html mb={3}>
              {content}
            </Text>
            <Box
              stack="row"
              wrap="wrap"
              alignItems="center"
              justifyContent={alignment}
              spacing="lg">
              {children?.map((v, i) => (
                <React.Fragment key={i}>
                  {v.linkCta && (
                    <Button variant={i % 2 === 0 ? 'contained' : 'outlined'} {...v.linkCta} />
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Grid.Col>
        </Grid>
      </Container>
    );
  }
);

export const ColouredBoxCtaBlock = createBlock<'blocks_colouredBoxCta_BlockType'>(
  ({ heading, content, linkCta }) => (
    <Container marginY="2xl">
      <Box stack spacing="lg" rounded bgcolor="yellowLight" padding="xl">
        <Text color="green" variant="h3" component="h2">
          {heading}
        </Text>
        <Text html>{content}</Text>

        {linkCta && <Button variant="contained" {...linkCta} />}
      </Box>
    </Container>
  )
);

export const LargeCtaBlock = createBlock<'blocks_largeCta_BlockType'>(
  ({ heading, linkCta, _blockMeta }) => {
    const alt = (_blockMeta?.nthOfType ?? 0) % 2 === 0;

    return (
      <Box className={backgroundRibbonWrap({})}>
        <Container textAlign="center">
          <Grid>
            <Grid.Col lg={10} offsetLg={1}>
              <Text variant="h1" component="h2" marginBottom="lg" color="green">
                {heading}
              </Text>

              {linkCta && <Button variant="contained" {...linkCta} />}
            </Grid.Col>
          </Grid>
        </Container>

        <BackgroundRibbon color={alt ? 'orange' : 'pink'} fullHeight />
      </Box>
    );
  }
);
