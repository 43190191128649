import { motion } from 'framer-motion';
import { svgDraw } from './animations';
import { createShape, ShapeProps } from './Shape';

export const Helix = createShape(({ disableAnimation, className, delay = 0 }: ShapeProps) => (
  <motion.svg
    className={className}
    initial={disableAnimation ? 'visible' : 'hidden'}
    animate="visible"
    width="411"
    height="170"
    viewBox="0 0 411 170"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <motion.path
      variants={svgDraw}
      custom={delay}
      d="M409.36 167.85L326.75 35.97C298.37 -9.32003 264.65 -9.32003 236.27 35.97L175.09 133.88C146.72 179.17 112.99 179.17 84.61 133.88L2 2"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <motion.path
      variants={svgDraw}
      custom={delay + 0.5}
      d="M409.36 2L326.75 133.88C298.37 179.17 264.65 179.17 236.27 133.88L175.09 35.97C146.72 -9.32003 112.99 -9.32003 84.61 35.97L2 167.85"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
  </motion.svg>
));
