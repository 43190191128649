import createStore from 'zustand';

export type NavBarResponsiveVariant = 'mobile' | 'desktop';
export type NavBarState = {
  responsiveVariant: NavBarResponsiveVariant;
  setResponsiveVariant: (responsiveVariant: NavBarResponsiveVariant) => void;

  height?: number;
  setHeight: (height: number) => void;

  actionsHeight?: number;
  setActionsHeight: (height: number) => void;

  hidden: boolean;
  setHidden: (hidden?: boolean) => void;

  searchOpen: boolean;
  setSearchOpen: (open?: boolean) => void;

  navOpen: boolean;
  setNavOpen: (open?: boolean) => void;
};

export const useNavbarState = createStore<NavBarState>()((set) => ({
  responsiveVariant: 'desktop',
  setResponsiveVariant: (responsiveVariant: NavBarResponsiveVariant) => set({ responsiveVariant }),

  height: undefined,
  setHeight: (height?: number) => set(() => ({ height })),

  actionsHeight: undefined,
  setActionsHeight: (height?: number) => set(() => ({ height })),

  hidden: false,
  setHidden: (hidden?: boolean) => set(() => ({ hidden: !!hidden })),

  searchOpen: false,
  // searchOpen: true, // for testing
  setSearchOpen: (open?: boolean) => set(() => ({ searchOpen: !!open })),

  navOpen: false,
  setNavOpen: (open?: boolean) => set(() => ({ navOpen: !!open })),
}));
