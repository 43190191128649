import Container from '@component/Container';
import Shim from '@component/Shim';
import { createBlock } from '@lib/api/fragments/blocks';
import { useNextHydrated } from '@lib/utils/createToggleStore';
import { Ratio } from '@theme/_sprinkles.css';
import { gql } from 'graphql-request';
import ReactPlayer from 'react-player';

gql`
  fragment videoBlock on blocks_video_BlockType {
    __typename
    videoUrl
  }
`;

export type VideoProps = {
  videoUrl?: Maybe<string>;
  ratio?: Ratio;
};

const Video = ({ videoUrl, ratio = 'video' }: VideoProps) => {
  const nextHydrated = useNextHydrated();

  return videoUrl ? (
    <Shim ratio={ratio}>
      {nextHydrated && (
        <ReactPlayer
          {...{
            height: '100%',
            width: '100%',
            url: videoUrl,
            playsinline: true,
            controls: true,
            config: {
              youtube: {
                playerVars: {
                  showinfo: 0,
                  color: 'white',
                  modestbranding: 1,
                },
              },
              vimeo: {
                playerOptions: {},
              },
            },
          }}
        />
      )}
    </Shim>
  ) : (
    <></>
  );
};

const VideoBlock = createBlock<'blocks_video_BlockType'>(({ videoUrl }) => (
  <Container>
    <Video videoUrl={videoUrl} />
  </Container>
));

Video.Block = VideoBlock;

export default Video;
