import { AppNavigationMenus, getAppNavigationMenus, View } from '@api';
import { CommonLinks, getCommonLinks } from '@lib/api/getCommonLinks';
import { AppGlobals, getGlobals } from '@lib/api/getGlobals';
// import { getWidgets } from '@lib/api/getWidgets';
import { createContext, RefObject } from 'react';
import { AppIndexData } from './entryIndexes';

export type AppContext = {
  refs?: {
    layout?: RefObject<HTMLDivElement>;
  };
  nav?: AppNavigationMenus;
  commonLinks?: CommonLinks;
  view?: View | null;
  indexData?: AppIndexData | null;
  // widgets?: AppWidgets;
  globals?: AppGlobals;
};

export const getAppContext = async (defaults?: Partial<AppContext>): Promise<AppContext> => {
  const nav = await getAppNavigationMenus();
  const commonLinks = await getCommonLinks();
  // const widgets = await getWidgets();
  const globals = await getGlobals();

  return {
    ...defaults,
    nav,
    commonLinks,
    // widgets,
    globals,
  };
};

export const appContext = createContext<AppContext>({});

export const { Provider: AppContextProvider } = appContext;
