import { Button, ButtonProps } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useLoginDialog } from './Login';

export type LoginButtonProps = ButtonProps;

export const LoginButton = (props: LoginButtonProps) => {
  const toggle = useLoginDialog((s) => s.toggle);
  const t = useTranslations('actions');

  return (
    <Button variant="contained" color="secondary" onClick={() => toggle()} {...props}>
      {t('login')}
    </Button>
  );
};
