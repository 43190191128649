import BlockHeader from '@component/BlockHeader';
import Box from '@component/Box';
import Container from '@component/Container';
import Grid from '@component/Grid';
import { formatGraphValue, GraphEntry } from '@lib/api/parseGraphEntry';
import { colorValues } from '@theme';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { ChartProps, Doughnut } from 'react-chartjs-2';
import * as styles from './Graph.css';
import { GraphLegend } from './GraphLegend';

ChartJS.register(ArcElement, Tooltip, Legend);

export type GraphDoughnutProps = GraphEntry<'single'>;

const GraphDoughnut = (props: GraphDoughnutProps) => {
  const { heading, summary, data = [], valueFormat, show = true } = props;

  const args: ChartProps<'doughnut'> = {
    type: 'doughnut',
    datasetIdKey: 'id',
    data: {
      labels: data.map((v) => v.label),
      datasets: show
        ? [
            {
              data: data.map((v) => v.value ?? 0),
              backgroundColor: data.map((v) => colorValues[v.color ?? 'green']),

              borderWidth: 0,
            },
          ]
        : [],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          formatter: () => '',
        },
        tooltip: {
          caretPadding: 4,
          boxPadding: 2,
          callbacks: {
            label: ({ label, parsed }) => {
              return `${label}: ${formatGraphValue(parsed, valueFormat)}`;
            },
          },
        },
      },
    },
  };

  return (
    <Container marginY="2xl">
      <Grid>
        <Grid.Col md={8} lg={6}>
          <BlockHeader {...{ heading, content: summary }} />
        </Grid.Col>
      </Grid>
      <Grid alignItems="center" rowGutter>
        <Grid.Col md={6} xl={4} className={styles.graphDoughnutGraphCol}>
          <Doughnut {...args} />
        </Grid.Col>
        <Grid.Col md={6} xl={6}>
          <Box bgcolor="greyLighter" rounded className={styles.graphDoughnutLegendWrap}>
            <GraphLegend padding="xl" graph={props} />
          </Box>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default GraphDoughnut;
