export const themeSelectors = {};

type ThemeSelectors = typeof themeSelectors;
export type ThemeSelectorKey = keyof ThemeSelectors;

/**
 * Apply styles under a {@link ThemeSelectorKey} to an existing {@link StyleRule}
 * (or provide `null`) as the first argument to create a new rule
 */
// export const withThemeSelector = <T extends ThemeSelectorKey>(
//   currentStyle: StyleRule | null,
//   key: T,
//   style: StyleRule
// ) => {
//   const { selectors = {}, ...rest } = currentStyle ?? {};

//   const selector = themeSelectors[key];

//   const newStyle: StyleRule = {
//     ...rest,
//     selectors: {
//       ...selectors,
//       [selector]: style,
//     },
//   };

//   return newStyle;
// };
