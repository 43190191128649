/* eslint-disable @typescript-eslint/no-explicit-any */
export const imageAnimationVariants = [
  'alt1',
  'genericOrangeCircle',
  //
  'alt2',
  'genericYellowCircle',
  //
  'alt3',
  'news',
  //
  'alt4',
  'genericTransparent',
  //
  'alt5',
  'home',
  //
  'alt6',
  'genericYellowRectangle',
  //
  'alt7',
  'search',
  //
  'alt8',
  'genericPinkCircle',
  //
  'wealthDiamond',
  'lifeCircle',
  'plant',
  'australiaMap',
  'borderlessYellow',
  'borderlessPink'
];

export type ImageAnimationVariant = typeof imageAnimationVariants[number];

export const parseImageAnimationVariant = (
  data: any,
  defaultVariant?: ImageAnimationVariant
): ImageAnimationVariant | null => {
  if (data && imageAnimationVariants.includes(data)) return data;

  return defaultVariant ?? null;
};
