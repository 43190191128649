import { textStyle, TextStyleVariants } from './Text.css';

export const useTextStyle = <T extends TextStyleVariants>({
  bold,
  uppercase,
  weight,
  html,
  srOnly,
  ...rest
}: T) => {
  return {
    className: textStyle({
      bold,
      uppercase,
      weight,
      html,
      srOnly,
    }),
    rest,
  };
};
