import BlockHeader from '@component/BlockHeader';
import Box from '@component/Box';
import Button from '@component/Button';
import Container from '@component/Container';
import Grid from '@component/Grid';
import StyledImage from '@component/StyledImage';
import { createBlock } from '@lib/api/fragments/blocks';
import { useTranslations } from 'next-intl';
import * as styles from './ImageTextCtaFeatureBlock.css';

export const ImageTextCtaFeatureBlock = createBlock<'blocks_imageTextCtaFeature_BlockType'>(
  (props) => {
    const t = useTranslations('cta');
    const { heading, content, image, linkCta, _blockMeta } = props;

    const empty = !heading && !content;
    return empty ? (
      <></>
    ) : (
      // <Box marginY="2xl">
      <Box className={styles.root}>
        <Container>
          <Grid alignItems="center" justifyContent="center">
            <Grid.Col lg={10} className={styles.contentWrap}>
              <BlockHeader
                {...{
                  marginBottom: 8,
                  heading,
                  HeadingProps: {
                    variant: 'h2',
                    component: 'h2',
                    bold: false,
                    html: true,
                    textAlign: 'center',
                  },
                  content,
                  ContentProps: { variant: 'large', textAlign: 'center' },
                }}>
                <Box textAlign="center" marginTop="lg">
                  <Button {...linkCta}>{t('more')}</Button>
                </Box>
              </BlockHeader>

              <Box className={styles.imageWrap}>
                <StyledImage
                  inViewOptions={{ amount: 0.5 }}
                  image={image}
                  variant="alt6"
                  ImageProps={{
                    transform: { xs: 'squareLgCrop', mx: 'squareXlCrop' },
                    ratio: 'landscape',
                    contain: true,
                  }}
                />
              </Box>
            </Grid.Col>
          </Grid>
        </Container>
      </Box>
      // </Box>
    );
  }
);
