import Icon from '@component/Icon';
import Text, { TextProps } from '@component/Text';
import { NavMenuItem as NavMenuItemType } from '@lib/api';
import {
  Button,
  Collapse,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonBaseProps,
  ListItemText,
} from '@mui/material';
import NextLink from 'next/link';
import { useMemo, useState } from 'react';

export type NavMenuItemLinkProps = {
  item?: NavMenuItemType;
};

export const NavMenuItemLink = ({ item }: NavMenuItemLinkProps) => {
  const { href, type, classes, title, newWindow } = item ?? {};
  const hasLink = !!href && type === 'link';
  const isButton = !!classes?.includes('button');

  const linkProps = hasLink
    ? {
        href: href,
        target: newWindow ? '_blank' : undefined,
        children: title,
      }
    : null;

  return !hasLink ? (
    // Just a label
    <Text>{title}</Text>
  ) : isButton ? (
    // Appear as button
    <Button color="secondary" variant="contained" {...linkProps} />
  ) : (
    // Appear as link
    <Link {...linkProps} underline="always" />
  );
};
export type NavMenuItemProps = {
  item?: NavMenuItemType;
  depth?: number;
  TextProps?: TextProps;
  openState?: boolean;
  setOpenState?: (open: boolean) => void;
};

export const NavMenuItem = ({ item, TextProps: maybeTextProps, depth = 0, openState, setOpenState }: NavMenuItemProps) => {
  const { title, href, newWindow, type, subItems, classes } = item ?? {};

  const isRootLevel = depth === 0;
  const hasChildren = !!subItems?.length;
  const hasLink = !!href && type === 'link';
  const isButton = !!classes?.includes('button');

  const linkProps = hasLink
    ? {
        href: href,
        target: newWindow ? '_blank' : undefined,
      }
    : null;

  const [openStateLocal, setOpenStateLocal] = useState(false);
  const open = useMemo(() => openState === undefined ? openStateLocal : openState , [openState, openStateLocal])

  const handleClick = () => {
    setOpenState ? setOpenState(!openState) : setOpenStateLocal(!openStateLocal);
  };

  const labelProps: TextProps = maybeTextProps ?? {
    marginBottom: 0,
    ...(isRootLevel
      ? {
          variant: 'h4',
          color: 'primary',
          weight: 'normal',
        }
      : {
          bold: !hasLink ? true : undefined,
          variant: 'large',
          weight: 'light',
        }),
  };

  const label = (
    <ListItemText disableTypography>
      <Text {...labelProps}>{title}</Text>
    </ListItemText>
  );

  let output = label;

  const buttonProps: ListItemButtonBaseProps = {};

  if (hasChildren) {
    output = (
      <ListItemButton onClick={handleClick} {...buttonProps}>
        {output}
        <Icon name={open ? 'expandLess' : 'expandMore'} />
      </ListItemButton>
    );
  } else if (linkProps) {
    output = (
      <NextLink {...linkProps} passHref>
        {isButton ? (
          <Button color="secondary" variant="contained" {...linkProps} sx={{ mb: 2 }}>
            {title}
          </Button>
        ) : (
          <ListItemButton {...linkProps} {...buttonProps}>
            {output}
          </ListItemButton>
        )}
      </NextLink>
    );
  }

  return (
    <>
      <ListItem
        disableGutters
        disablePadding
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        {output}
      </ListItem>
      {hasChildren && (
        <Collapse in={open} unmountOnExit>
          <List>
            {subItems?.map((subItem, j) => (
              <NavMenuItem key={j} item={subItem} depth={depth + 1} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};
