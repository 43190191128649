import Box from '@component/Box';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { Semicircle } from './Semicircle.svg';
import { ComplexShapeProps } from './Shape';
import * as styles from './ComplexSemicircles.css';

export type SemicirclesProps = ComplexShapeProps<'semiCircleTop' | 'semiCircleBottom'>;

export const Semicircles = ({
  disableAnimation,
  semiCircleTopProps,
  semiCircleTopColor = 'primary',
  semiCircleBottomProps,
  semiCircleBottomColor = 'secondary',
  ...props
}: SemicirclesProps) => (
  <Box {...mergePropsClassName(props, styles.semicircles)}>
    <Semicircle
      disableAnimation={disableAnimation}
      color={semiCircleTopColor}
      {...mergePropsClassName(semiCircleTopProps, styles.semicircleTop)}
    />
    <Semicircle
      disableAnimation={disableAnimation}
      delay={0.5}
      color={semiCircleBottomColor}
      {...mergePropsClassName(semiCircleBottomProps, styles.semicircleBottom)}
    />
  </Box>
);
