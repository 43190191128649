import { useRecaptchaExecute } from '@component/Recaptcha';
import { ParsedForm } from '@formie/parse/formieParser';
import React from 'react';
import { FormieFields } from './FormieFields';
import { FormieSubmitRow } from './FormieSubmitRow';
import { createFormStore, FormContextProvider, useForm } from './useForm';

export type FormieProps = {
  form?: ParsedForm;
};

const FormieForm = ({ form }: FormieProps) => {
  const submit = useForm((s) => s.submit);
  const setRecaptchaToken = useForm((s) => s.setRecaptchaToken);
  const handleReCaptcha = useRecaptchaExecute();

  const { formFields: fields = [] } = form ?? {};

  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();

    handleReCaptcha((token) => {
      setRecaptchaToken(token);
      submit();
    });
  };

  return (
    <>
      {/* <GoogleRecaptchaExample /> */}
      <form noValidate onSubmit={handleSubmit}>
        <FormieFields fields={fields} />
        {/* <FieldErrors errors={errors} /> */}
        <FormieSubmitRow />
      </form>
    </>
  );
};

const Formie = ({ form }: FormieProps) => {
  const mutationName = form?.submissionMutationName;

  return mutationName ? (
    <FormContextProvider createStore={() => createFormStore(mutationName, form)}>
      <FormieForm form={form} />
    </FormContextProvider>
  ) : (
    <></>
  );
};

export default Formie;
