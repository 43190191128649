import { createTheme, Theme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { fontVariantRules, vars } from '..';

/*
The following are Typescript overrides for
the built-in MUI Typography variants
*/
declare module '@mui/material/styles' {
  interface TypographyVariants {
    large: React.CSSProperties;
    medium: React.CSSProperties;
    normal: React.CSSProperties;
    small: React.CSSProperties;
    tiny: React.CSSProperties;
    button: React.CSSProperties;
    buttonLg: React.CSSProperties;
    info: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    large: React.CSSProperties;
    medium: React.CSSProperties;
    normal: React.CSSProperties;
    small: React.CSSProperties;
    tiny: React.CSSProperties;
    button: React.CSSProperties;
    buttonLg: React.CSSProperties;
    info: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    large: true;
    medium: true;
    normal: true;
    small: true;
    tiny: true;
    button: true;
    buttonLg: true;
    info: true;

    // disable built-ins
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    caption: false;
    overline: false;
  }
}

const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        textAlign: 'inherit',
        variant: 'medium',
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          large: 'p',
          normal: 'p',
          small: 'p',
          tiny: 'p',
          button: 'span',
          info: 'span',
          inherit: 'span',
        },
      },
      styleOverrides: {
        root: {
          overflow: 'visible',
        },
      },
    },
  },
  typography: {
    fontFamily: vars.typography.font.body,
    h1: fontVariantRules.h1,
    h2: fontVariantRules.h2,
    h3: fontVariantRules.h3,
    h4: fontVariantRules.h4,
    h5: fontVariantRules.h5,
    h6: fontVariantRules.h6,
    large: fontVariantRules.large,
    medium: fontVariantRules.medium,
    normal: fontVariantRules.normal,
    small: fontVariantRules.small,
    tiny: fontVariantRules.tiny,
    button: fontVariantRules.button,
    buttonLg: fontVariantRules.buttonLg,
    info: fontVariantRules.info,

    // Disable built-ins
    subtitle1: undefined,
    subtitle2: undefined,
    body1: undefined,
    body2: undefined,
    caption: undefined,
    overline: undefined,
  },
});

export const applyTypography = (baseTheme: Theme) => {
  baseTheme.components = deepmerge(baseTheme.components, theme.components);
  baseTheme.typography = deepmerge(baseTheme.typography, theme.typography);
  return baseTheme;
};

export default theme;
