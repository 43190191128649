import Box from '@component/Box';
import Image from '@component/Image';
import Text from '@component/Text';
import { useGlobals } from '@lib/store';
import * as style from './AcknowledgementOfCountry.css';

const AcknowledgementOfCountry = () => {
  const acknowledgementOfCountry = useGlobals('acknowledgementOfCountry');

  if (!acknowledgementOfCountry) return <></>;

  const { images = [], content } = acknowledgementOfCountry;

  return (
    <Box>
      {images.map((image, i) => (
        <Box key={i} className={style.aocFlag}>
          <Image key={i} ratio="landscapeWide" contain alt="" {...image} />
        </Box>
      ))}
      <Text variant="h4" html marginTop="md">
        {content}
      </Text>
    </Box>
  );
};

export default AcknowledgementOfCountry;
