/* eslint-disable @typescript-eslint/no-explicit-any */
import { CategoryTypeId } from '@lib/api/fragments/categories';
import { EntryTypeId } from '@lib/api/fragments/entries';
import {
  EntryIndexQueryProps,
  EntryIndexQueryResult,
  getEntryIndexData,
} from '@lib/api/getEntryIndexData';
import { EntryIndexTypeId } from '@lib/config';
import { mapObject } from '@liquorice/allsorts-craftcms-nextjs';

export const createEntryIndexDefaults = <E extends EntryTypeId, C extends CategoryTypeId>(
  entryType: E | E[],
  categoryType: C | C[],
  options?: Partial<EntryIndexQueryProps<E, C>>
): EntryIndexQueryProps<E, C> => ({
  entryType,
  categoryType,
  ...options,
});

// ------------------------------------------------------------------------------------------------
// ---- Create the config object ----

const entryIndexDefaults = {
  articleIndex: createEntryIndexDefaults('article', 'articleCategory'),
  caseStudyIndex: createEntryIndexDefaults('caseStudy', 'caseStudyCategory'),
  insightIndex: createEntryIndexDefaults('insight', 'insightCategory'),
  search: createEntryIndexDefaults(
    ['insight', 'article', 'caseStudy', 'caseStudyIndex', 'articleIndex', 'insightIndex', 'page'],
    [],
    {
      perPage: 6,
      orderBy: 'score DESC',
    }
  ),
} as const;

// ------------------------------------------------------------------------------------------------
// ---- Extract the Types ----

type EntryIndexSettings = typeof entryIndexDefaults;

export type EntryIndexKey = Extract<EntryIndexTypeId, keyof EntryIndexSettings>;

export type EntryIndexEntryType<T extends EntryIndexKey> =
  EntryIndexSettings[T] extends EntryIndexQueryProps<infer E, any> ? E : never;

export type EntryIndexCategoryType<T extends EntryIndexKey> =
  EntryIndexSettings[T] extends EntryIndexQueryProps<any, infer C> ? C : never;

export type AppIndexData = {
  [P in EntryIndexKey]?: EntryIndexQueryResult<EntryIndexEntryType<P>, EntryIndexCategoryType<P>>;
};

export const getEntryIndexDefaults = <T extends EntryIndexKey>(key: T) =>
  entryIndexDefaults[key] as EntryIndexSettings[T];

// ------------------------------------------------------------------------------------------------
// ---- Create the Fetchers ----

export type EntryIndexCallbacks = {
  [P in EntryIndexKey]: (
    props: Partial<EntryIndexSettings[P]>
  ) => Promise<EntryIndexQueryResult<EntryIndexEntryType<P>, EntryIndexCategoryType<P>>>;
};

export const indexDataCallbacks = mapObject(entryIndexDefaults, (defaults) => {
  return async (props?: Partial<typeof defaults>) =>
    await getEntryIndexData({
      ...defaults,
      ...props,
    });
}) as EntryIndexCallbacks;

export const isEntryIndexKey = (x?: string): x is EntryIndexKey => !!x && x in entryIndexDefaults;
export const getIndexDataCallback = <T extends EntryIndexKey>(type: T) => indexDataCallbacks[type];

// ------------------------------------------------------------------------------------------------
// ---- Create the Hooks ----

// export type EntryIndexHooks = {
//   [P in EntryIndexKey]: () => UseEntryIndexDataResult<
//     EntryIndexEntryType<P>,
//     EntryIndexCategoryType<P>
//   >;
// };

// export type UseEntryIndexDataResult<E extends EntryTypeId, C extends CategoryTypeId> = {
//   data?: EntryIndexQueryResult<E, C>;
//   isLoading: boolean;
//   trigger: (props: Partial<EntryIndexQueryProps<E, C>>) => void;
// };

// export const createEntryIndexDataHook =
//   <T extends EntryIndexKey>(entryIndex: T) =>
//   <
//     E extends EntryIndexEntryType<T>,
//     C extends EntryIndexCategoryType<T>
//   >(): UseEntryIndexDataResult<E, C> => {
//     const maybeAppContext = React.useContext(appContext) ?? {};

//     const initData = maybeAppContext.indexData
//       ? (maybeAppContext.indexData[entryIndex] as EntryIndexQueryResult<E, C>)
//       : undefined;
//     const defaults = getEntryIndexDefaults(entryIndex) as EntryIndexQueryProps<E, C>;

//     const [data, setData] = React.useState<EntryIndexQueryResult<E, C> | undefined>(initData);
//     const [isLoading, setIsLoading] = React.useState<boolean>(false);

//     const trigger = React.useCallback(
//       (props: Partial<EntryIndexQueryProps<E, C>>) => {
//         setIsLoading(true);
//         getEntryIndexData({ ...defaults, ...props }).then((res) => {
//           setData(res);
//           setIsLoading(false);
//         });
//       },
//       [defaults]
//     );

//     return { trigger, data, isLoading };
//   };

// export const indexDataHooks = mapObject(entryIndexDefaults, (_, key) => {
//   return createEntryIndexDataHook(key);
// }) as EntryIndexHooks;

// export const getIndexDataHook = <T extends EntryIndexKey>(type: T) => indexDataHooks[type];
