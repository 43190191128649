import Box, { BoxProps } from '@component/Box';
import Image, { ImageProps } from '@component/Image';
import Link, { LinkProps } from '@component/Link';
import Text from '@component/Text';
import { ImageEntry } from '@lib/api/fragments/image';
import { mergePropsClassName, parseHref } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'next-intl';
import NextLink from 'next/link';
import * as styles from './Card.css';

export type CardProps<T = null, D extends TagName = 'article'> = BoxProps<
  D,
  styles.CardVariants & {
    item?: T;
    title?: Maybe<string>;
    date?: Maybe<string | number>;
    image?: Maybe<ImageEntry>;
    ImageProps?: ImageProps;

    hideImage?: boolean;
    summary?: Maybe<string>;
    /** Destination for the card link, merged into the value of the `LinkProps` prop */
    href?: Maybe<string>;
    /** Finer control over the {@link Link} props */
    LinkProps?: Maybe<LinkProps>;
    /** Include the {@link CardFeatureFlag} */
    featured?: boolean;
    /** Children placed after the title */
    slotMeta?: React.ReactNode;
    /** Replace the image */
    slotImage?: React.ReactNode;
  }
>;

function Card<T>({
  item: _item,
  featured: _featured,
  title,
  date,
  image,
  hideImage,
  summary,
  size = 'normal',
  href: maybeHref,
  LinkProps: maybeLinkProps = {},
  ImageProps: maybeImageProps = {},
  ...props
}: CardProps<T>) {
  const t = useTranslations();

  /** If we're building the 'large' variant */
  const isLarge = size === 'large';

  const LinkProps: LinkProps = {
    href: maybeHref ?? '',
    children: t('cta.more'),
    ...maybeLinkProps,
  };

  const href = parseHref(LinkProps.href).href;
  const hasLink = !!href;

  const output = (
    <Box
      component="article"
      {...{
        rounded: true,
        ...mergePropsClassName(props, styles.root({ hasLink, size })),
      }}>
      {!hideImage && (
        <Image
          ratio="landscapeWide"
          alt={title ?? ''}
          transform={{ xs: 'landscapeLgCrop' }}
          {...image}
          {...maybeImageProps}
        />
      )}

      <Box className={styles.content}>
        {date && (
          <Text variant="h6" color="primary">
            {date}
          </Text>
        )}

        <Text variant={isLarge ? 'h2' : 'h4'} bold component="h2" color="primary">
          {title}
        </Text>

        <Text variant={isLarge ? 'large' : 'medium'} maxChars={200} mb={2}>
          {summary}
        </Text>

        {/* Disable the 'a.href' as the whole card is linked when required */}
        {hasLink && (
          <Link
            underline="none"
            variant="buttonLg"
            linkDirection="forwards"
            {...LinkProps}
            href={href}
            className={styles.link}
          />
        )}
      </Box>
    </Box>
  );

  // Wrap in a link if required
  return hasLink ? <NextLink href={href}>{output}</NextLink> : output;
}

export default Card;
