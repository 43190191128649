import Box from '@component/Box';
import Breadcrumbs from '@component/Breadcrumbs';
import Container from '@component/Container';
import Grid from '@component/Grid';
import Image from '@component/Image';
import { SwoopInvert } from '@component/Shape';
import { useViewBannerImage } from '@lib/store';
import * as styles from './PageHeaderSimple.css';
import { PageTitle } from './PageTitle';

export type PageHeaderSimpleProps = {
  hideBreadcrumbs?: boolean;
};

export const PageHeaderSimple = ({ hideBreadcrumbs }: PageHeaderSimpleProps) => {
  const bannerImage = useViewBannerImage();
  const hasImage = !!bannerImage;
  return (
    <Box className={styles.root} component="header" marginBottom="xl">
      <Container marginBottom="xl">
        <Grid>
          <Grid.Col lg={6} className={styles.contentCol}>
            {!hideBreadcrumbs && <Breadcrumbs marginY="lg" />}
            <PageTitle bold color="primary" />
          </Grid.Col>
        </Grid>
      </Container>
      {hasImage && (
        <Box className={styles.imageCol}>
          <Image
            transform="ultraWideCrop"
            // transform={{ xs: 'landscapeLgCrop', mx: 'ultraWideCrop' }}
            fixedRatio
            ShimProps={{
              // bgcolor: imageWrapColor,
              className: styles.imageWrap,
            }}
            alt=""
            priority
            {...bannerImage}
          />
          <SwoopInvert className={styles.backgroundShape} />
        </Box>
      )}
    </Box>
  );
};
