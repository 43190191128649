import { motion } from 'framer-motion';
import { svgDraw } from './animations';
import { createShape, ShapeProps } from './Shape';

export const SemicircleOutline = createShape(
  ({ disableAnimation, className, strokeWidth, delay }: ShapeProps) => (
    <motion.svg
      className={className}
      width="94"
      initial={disableAnimation ? 'visible' : 'hidden'}
      animate="visible"
      height="47"
      viewBox="0 0 94 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <motion.path
        d="M92 0C92 11.9316 87.2606 23.3751 78.8237 31.8137C70.3868 40.2524 58.9432 44.9952 47.0092 45C35.072 45 23.6236 40.2591 15.1827 31.82C6.74187 23.3808 2 11.9347 2 0"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth={strokeWidth ?? 3}
        variants={svgDraw}
        custom={delay}
      />
    </motion.svg>
  )
);
