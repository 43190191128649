import Grid from '@component/Grid';
import Text from '@component/Text';
import { DefaultSelectField } from '@formie/Fields/DefaultSelectField';
import { useTranslations } from 'next-intl';
import { ChangeEvent, useEffect } from 'react';
import { useYourIR } from './useYourIR';

import { useAnnouncementFeed } from './YourIRAnnouncementsPagination';

export const YourIRAnnouncementsFilters = () => {
  const t = useTranslations();
  const { yourIR } = useYourIR();
  const { yearMin, yearMax, year, setYear, filter, setFilter, setPageTo } = useAnnouncementFeed();

  useEffect(() => {
    const year = yourIR?.get('announcements.year') as undefined | string;
    const filter = yourIR?.get('announcements.filter') as undefined | string;
    setYear(year ?? '');
    setFilter(filter ?? '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yourIR]);

  const handleYearChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    const newYear = value === '_none_' ? '' : value;
    yourIR?.set('announcements.year', newYear);
    setYear(newYear);
    setPageTo(1);
  };

  const handleFilterChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    const newFilter = value === '_none_' ? '' : value;
    yourIR?.set('announcements.filter', newFilter);
    setFilter(newFilter);
    setPageTo(1);
  };

  return (
    <Grid>
      <Grid.Col sm={6} md={4}>
        <Text variant="h4" bold color="primary">
          {t('actions.filterByYear')}
        </Text>
        <DefaultSelectField
          // label={'Year'}
          onChange={handleYearChange}
          value={year || '_none_'}
          options={[
            { value: '_none_', label: 'All' },
            ...Array(yearMax - yearMin + 1)
              .fill(null)
              .map((_, i) => {
                const year = `${yearMax - i}`;
                return {
                  value: year,
                  label: year,
                };
              }),
          ]}
        />
      </Grid.Col>
      <Grid.Col sm={6} md={8}>
        <Text variant="h4" bold color="primary">
          {t('actions.filterByCategory')}
        </Text>
        <DefaultSelectField
          // label={'Filter'}
          onChange={handleFilterChange}
          value={filter || '_none_'}
          options={[
            { value: '_none_', label: 'None' },
            { value: 'securityHolder', label: 'Security Holder Notices' },
            { value: 'annualReports', label: 'Annual Reports' },
            { value: 'periodicReports', label: 'Periodic Reports' },
            { value: 'dividendNotices', label: 'Dividend Notices' },
            { value: 'companyAdministration', label: 'Company Administration' },
            { value: 'priceSensitive', label: 'Price Sensitive' },
            { value: 'nonProcedural', label: 'Non-Procedural' },
          ]}
        />
      </Grid.Col>
    </Grid>
  );
};
