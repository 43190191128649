import Container from '@component/Container';
import Login, { LoginButton } from '@component/Login';
import Logo from '@component/Logo';
import NavPrimary from '@component/NavPrimary';
import { SearchToggleButton } from '@component/SearchIndex';

import NavBarMenu from '@component/NavBarMenu';
import Shim from '@component/Shim';
import { AppBar } from '@mui/material';
import useSize from '@react-hook/size';
import { useBreakpointMin } from '@theme/index';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { HideOnScroll } from './HideOnScroll';
import * as styles from './NavBar.css';
import { useNavbarState } from './useNavbarState';
import AnnouncementBanner from '@component/AnnouncementBanner';

const NavBar = () => {
  const navBarRef = useRef(null);

  const { setResponsiveVariant, setHeight } = useNavbarState();
  const [, navBarHeight] = useSize(navBarRef);

  const isDesktop = useBreakpointMin('lg', true);

  useEffect(() => {
    setHeight(navBarHeight);
  }, [setHeight, navBarHeight]);

  useEffect(() => {
    setResponsiveVariant(isDesktop ? 'desktop' : 'mobile');
  }, [setResponsiveVariant, isDesktop]);

  return (
    <>
      {/* Shim for the AppBar height */}
      <Shim className={styles.shim} />

      <HideOnScroll>
        <AppBar
          ref={navBarRef}
          className={classNames(styles.root({}))}
          elevation={0}
          color="inherit">
          <Container className={styles.container}>
            <Logo homeLink className={styles.logo} />

            {isDesktop && <NavPrimary direction="row" />}
            {isDesktop && <LoginButton />}

            <SearchToggleButton />

            {!isDesktop && <NavBarMenu.Button />}
          </Container>
          <AnnouncementBanner />
        </AppBar>
      </HideOnScroll>

      {/* Login Modal */}
      <Login />

      {/* Mobile Menu Modal */}
      <NavBarMenu />
    </>
  );
};

export default NavBar;
