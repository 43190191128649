import BlockHeader from '@component/BlockHeader';
import Container from '@component/Container';
import { createBlock } from '@lib/api/fragments/blocks';
import { gql } from 'graphql-request';
import { YourIRAnnouncementsFeed } from './YourIRAnnouncementsFeed';
import { YourIRPriceChart } from './YourIRPriceChart';
import { YourIRSharePrice } from './YourIRSharePrice';
import YourIrWidget from './YourIrWidget';

gql`
  fragment yourirDataBlock on blocks_yourirData_BlockType {
    __typename
    heading
    content: htmlContentDefault
    yourirWidgetType
    code
  }
  fragment yourirAnnouncementsBlock on blocks_yourirAnnouncements_BlockType {
    __typename
  }
`;

export const YourIrBlock = createBlock<'blocks_yourirData_BlockType'>(
  ({ heading, content, yourirWidgetType, code }) => {
    let inner: JSX.Element | null;

    switch (yourirWidgetType) {
      case 'priceChart':
        inner = <YourIRPriceChart />;
        break;
      case 'sharePrice':
        inner = <YourIRSharePrice />;
        break;

      default:
        inner = code ? <YourIrWidget code={code} /> : null;
        break;
    }

    return (
      <Container marginY="2xl">
        <BlockHeader {...{ heading, content }} />
        {inner}
      </Container>
    );
  }
);
export const YourIrAnnouncementsBlock = createBlock<'blocks_yourirAnnouncements_BlockType'>(() => (
  <YourIRAnnouncementsFeed />
));
