import Icon, { AppIconName } from '@component/Icon';
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  IconButtonTypeMap as MuiIconButtonTypeMap,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';

export type IconButtonProps<
  D extends React.ElementType = MuiIconButtonTypeMap['defaultComponent'],
  P extends Props = NoProps
> = MuiIconButtonProps<
  D,
  {
    icon?: AppIconName;
    variant?: 'contained' | 'default' | 'invert';
  } & P
>;

const IconButton = <
  D extends React.ElementType = MuiIconButtonTypeMap['defaultComponent'],
  P extends Props = NoProps
>({
  icon = 'search',
  variant = 'default',
  sx: customSx,
  children,
  color = 'primary',
  ...props
}: IconButtonProps<D, P>) => {
  const palette = useTheme().palette;

  const muiColor = variant === 'default' ? color : undefined;

  let foregroundColor: string | undefined = undefined;
  let backgroundColor: string | undefined = undefined;
  let foregroundHoverColor: string | undefined = undefined;
  let backgroundHoverColor: string | undefined = undefined;

  switch (color) {
    case 'secondary':
    case 'primary':
      foregroundColor = palette[color].contrastText;
      backgroundColor = palette[color].main;
      foregroundHoverColor = palette[color].contrastText;
      backgroundHoverColor = palette[color].dark;
  }

  const sx: SxProps<Theme> = {
    ...(variant === 'contained'
      ? {
          color: foregroundColor,
          backgroundColor: backgroundColor,
          [':hover']: {
            color: foregroundHoverColor,
            backgroundColor: backgroundHoverColor,
          },
        }
      : variant === 'invert'
      ? {
          backgroundColor: foregroundColor,
          color: backgroundColor,
          [':hover']: {
            backgroundColor: foregroundHoverColor,
            color: backgroundHoverColor,
          },
        }
      : {
          // color,
        }),
    ...customSx,
  };

  return (
    <MuiIconButton color={muiColor} {...props} sx={sx}>
      {children ?? <Icon name={icon} />}
    </MuiIconButton>
  );
};

export default IconButton;
