import Button from '@component/Button';
import Grid from '@component/Grid';
import Text from '@component/Text';
import { Alert, CircularProgress, Collapse, Stack } from '@mui/material';
import { useTranslations } from 'next-intl';
import { useForm } from './useForm';
import { FieldErrors } from './Fields/FieldErrors';

export const FormieSubmitRow = () => {
  const { isLoading, isError, isSuccess, messages, recaptchaError } = useForm(
    ({ isLoading, isError, isSuccess, messages, recaptchaError }) => ({
      isLoading,
      isError,
      isSuccess,
      messages,
      recaptchaError,
    })
  );

  // const values = useForm((state) => state.values);
  // console.log(values);

  const t = useTranslations();

  const spacing = 4;
  return (
    <Stack textAlign="left">
      <FieldErrors errors={recaptchaError} />
      <Collapse in={isError} unmountOnExit>
        <Alert severity="error" sx={{ mb: spacing }}>
          {/* <AlertTitle>{t('feedback.error')}</AlertTitle> */}
          <Text html variant="small">
            {messages.error}
          </Text>
        </Alert>
      </Collapse>
      <Collapse in={isSuccess} unmountOnExit>
        <Alert severity="success" sx={{ mb: spacing }}>
          {/* <AlertTitle>{t('feedback.success')}</AlertTitle> */}
          <Text html>{messages.success}</Text>
        </Alert>
      </Collapse>
      <Grid>
        <Grid.Col textAlign="center" sm={6} offsetSm={3}>
          <Button type="submit" disabled={isLoading}>
            {t('actions.submit')}
          </Button>
        </Grid.Col>
        <Grid.Col textAlign="right" sm={3}>
          {isLoading && <CircularProgress color="primary" size={40} />}
        </Grid.Col>
      </Grid>
    </Stack>
  );
};
