import Grid from '@component/Grid';
import { createField } from '@formie/parse/formieParser';
import { useFormField } from '@formie/useForm';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { gql } from 'graphql-request';
import { DefaultSelectField } from './DefaultSelectField';
import { DefaultTextField } from './DefaultTextField';
import { FieldErrors } from './FieldErrors';

gql`
  fragment fieldAddress on Field_Address {
    ...fieldBase
    # address1
    address1Collapsed
    address1DefaultValue
    address1Enabled
    address1ErrorMessage
    address1Hidden
    address1Label
    address1Placeholder
    address1PrePopulate
    address1Required
    # address2
    address2Collapsed
    address2DefaultValue
    address2Enabled
    address2ErrorMessage
    address2Hidden
    address2Label
    address2Placeholder
    address2PrePopulate
    address2Required
    # address3
    address3Collapsed
    address3DefaultValue
    address3Enabled
    address3ErrorMessage
    address3Hidden
    address3Label
    address3Placeholder
    address3PrePopulate
    address3Required
    # city
    cityCollapsed
    cityDefaultValue
    cityEnabled
    cityErrorMessage
    cityHidden
    cityLabel
    cityPlaceholder
    cityPrePopulate
    cityRequired
    # country
    countryCollapsed
    countryDefaultValue
    countryEnabled
    countryErrorMessage
    countryHidden
    countryLabel
    countryPlaceholder
    countryPrePopulate
    countryRequired
    # state
    stateCollapsed
    stateDefaultValue
    stateEnabled
    stateErrorMessage
    stateHidden
    stateLabel
    statePlaceholder
    statePrePopulate
    stateRequired
    # zip
    zipCollapsed
    zipDefaultValue
    zipEnabled
    zipErrorMessage
    zipHidden
    zipLabel
    zipPlaceholder
    zipPrePopulate
    zipRequired
    #
    countryOptions {
      ...fieldAttribute
    }
  }
`;

export const Field_Address = createField<'Field_Address'>(({ field, ..._props }) => {
  const {
    // address1Collapsed,
    address1Enabled,
    address1ErrorMessage,
    address1Hidden,
    address1Label,
    address1Placeholder,
    // address1PrePopulate,
    address1Required,
    //
    // address2Collapsed,
    address2Enabled,
    address2ErrorMessage,
    address2Hidden,
    address2Label,
    address2Placeholder,
    // address2PrePopulate,
    address2Required,
    //
    // address3Collapsed,
    address3Enabled,
    address3ErrorMessage,
    address3Hidden,
    address3Label,
    address3Placeholder,
    // address3PrePopulate,
    address3Required,
    //
    // cityCollapsed,
    cityEnabled,
    cityErrorMessage,
    cityHidden,
    cityLabel,
    cityPlaceholder,
    // cityPrePopulate,
    cityRequired,
    //
    // countryCollapsed,
    countryEnabled,
    countryErrorMessage,
    countryHidden,
    countryLabel,
    countryOptions,
    countryPlaceholder,
    // countryPrePopulate,
    countryRequired,
    //
    // stateCollapsed,
    stateEnabled,
    stateErrorMessage,
    stateHidden,
    stateLabel,
    statePlaceholder,
    // statePrePopulate,
    stateRequired,
    // zipCollapsed,
    zipEnabled,
    zipErrorMessage,
    zipHidden,
    zipLabel,
    zipPlaceholder,
    // zipPrePopulate,
    zipRequired,
    //
    address1DefaultValue,
    address2DefaultValue,
    address3DefaultValue,
    cityDefaultValue,
    countryDefaultValue,
    stateDefaultValue,
    zipDefaultValue,
    //
    handle,
    name,
    instructions,
    labelPosition,
  } = field;

  const { value, setValue, error, errors } = useFormField(handle, 'complex', {
    ...(address1Enabled && { address1: address1DefaultValue ?? '' }),
    ...(address2Enabled && { address2: address2DefaultValue ?? '' }),
    ...(address3Enabled && { address3: address3DefaultValue ?? '' }),
    ...(cityEnabled && { city: cityDefaultValue ?? '' }),
    ...(countryEnabled && { country: countryDefaultValue ?? '' }),
    ...(stateEnabled && { state: stateDefaultValue ?? '' }),
    ...(zipEnabled && { zip: zipDefaultValue ?? '' }),
  });

  const handleChange =
    (fieldName: string) => (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue({ ...value, [fieldName]: ev.target.value });
    };

  return (
    <FormControl error={error} fullWidth>
      {/* <Stack spacing={3} mb={4}> */}
      <FormLabel component="legend" sx={{ mb: 2 }} hidden={labelPosition === 'hidden'}>
        {name}
      </FormLabel>

      {instructions && <FormHelperText>{instructions}</FormHelperText>}

      <Grid rowGutter>
        {address1Enabled && (
          <Grid.Col xs={12}>
            <DefaultTextField
              {...{
                name: 'address1',
                onChange: handleChange('address1'),
                value: value['address1'] ?? '',
                // address1Collapsed,
                // defaultValue: address1DefaultValue,
                helperText: address1ErrorMessage,
                hidden: address1Hidden,
                label: address1Label,
                placeholder: address1Placeholder,
                // address1PrePopulate,
                required: address1Required,
              }}
            />
          </Grid.Col>
        )}
        {address2Enabled && (
          <Grid.Col xs={12}>
            <DefaultTextField
              {...{
                name: 'address2',
                onChange: handleChange('address2'),
                value: value['address2'] ?? '',
                // address2Collapsed,
                // defaultValue: address2DefaultValue,
                helperText: address2ErrorMessage,
                hidden: address2Hidden,
                label: address2Label,
                placeholder: address2Placeholder,
                // address2PrePopulate,
                required: address2Required,
              }}
            />
          </Grid.Col>
        )}
        {address3Enabled && (
          <Grid.Col xs={12}>
            <DefaultTextField
              {...{
                name: 'address3',
                onChange: handleChange('address3'),
                value: value['address3'] ?? '',
                // address3Collapsed,
                // defaultValue: address3DefaultValue,
                helperText: address3ErrorMessage,
                hidden: address3Hidden,
                label: address3Label,
                placeholder: address3Placeholder,
                // address3PrePopulate,
                required: address3Required,
              }}
            />
          </Grid.Col>
        )}
        <Grid.Col xs={12} sm={6}>
          {cityEnabled && (
            <DefaultTextField
              {...{
                name: 'city',
                onChange: handleChange('city'),
                value: value['city'] ?? '',
                // cityCollapsed,
                // defaultValue: cityDefaultValue,
                helperText: cityErrorMessage,
                hidden: cityHidden,
                label: cityLabel,
                placeholder: cityPlaceholder,
                // cityPrePopulate,
                required: cityRequired,
              }}
            />
          )}
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          {stateEnabled && (
            <DefaultTextField
              {...{
                name: 'state',
                onChange: handleChange('state'),
                value: value['state'] ?? '',
                // stateCollapsed,
                // defaultValue: stateDefaultValue,
                helperText: stateErrorMessage,
                hidden: stateHidden,
                label: stateLabel,
                placeholder: statePlaceholder,
                // statePrePopulate,
                required: stateRequired,
              }}
            />
          )}
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          {zipEnabled && (
            <DefaultTextField
              {...{
                name: 'zip',
                onChange: handleChange('zip'),
                value: value['zip'] ?? '',
                // zipCollapsed,
                // defaultValue: zipDefaultValue,
                helperText: zipErrorMessage,
                hidden: zipHidden,
                label: zipLabel,
                placeholder: zipPlaceholder,
                // zipPrePopulate,
                required: zipRequired,
              }}
            />
          )}
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
          {countryEnabled && (
            <DefaultSelectField
              {...{
                name: 'country',
                onChange: handleChange('country'),
                value: value['country'] ?? '',
                // countryCollapsed,
                // defaultValue: countryDefaultValue ?? '',
                helperText: countryErrorMessage,
                hidden: countryHidden,
                label: countryLabel,
                placeholder: countryPlaceholder,
                // countryPrePopulate,
                required: countryRequired,
                options: countryOptions,
              }}
            />
          )}
        </Grid.Col>
      </Grid>

      <FieldErrors errors={errors} />
      {/* </Stack> */}
    </FormControl>
  );
});
