import Container from '@component/Container';
import Grid from '@component/Grid';
import { ENTRIES_PER_PAGE } from '@lib/constants';
import { useScrollToTop } from '@lib/utils/useScrollToElement';
import { useEffect, useRef } from 'react';
import { YourIRAnnouncementItem } from './YourIRAnnouncementItem';
import { YourIRAnnouncementsFilters } from './YourIRAnnouncementsFilters';
import {
  useAnnouncementFeed,
  YourIRAnnouncementsPagination,
} from './YourIRAnnouncementsPagination';
import YourIrWidget from './YourIrWidget';

export const YourIRAnnouncementsFeed = () => {
  const { page, yearMin } = useAnnouncementFeed(({ page, yearMin }) => ({ page, yearMin }));
  const scrollTopRef = useRef<HTMLAnchorElement>(null);

  const top = useScrollToTop(scrollTopRef);

  useEffect(() => {
    top();
  }, [page, top]);

  const pageSize = ENTRIES_PER_PAGE;

  return (
    <YourIrWidget id="archive" code={`announcements pageSize=${pageSize} startYear=${yearMin}`}>
      <Container marginY="2xl">
        <a ref={scrollTopRef} />

        <YourIRAnnouncementsFilters />
        <Grid data-yourir="items" rowGutter marginY="xl">
          <Grid.Col md={6} lg={4}>
            <YourIRAnnouncementItem />
          </Grid.Col>
        </Grid>
        <YourIRAnnouncementsPagination />
      </Container>
    </YourIrWidget>
  );
};
