import Box from '@component/Box';
import Container from '@component/Container';
import Image from '@component/Image/Image';
import Text from '@component/Text';
import { createBlock } from '@lib/api/fragments/blocks';
import { gql } from 'graphql-request';

gql`
  fragment imageWithCaptionBlock on blocks_image_BlockType {
    __typename
    image {
      ...image
    }
    caption
  }
`;

const ImageWithCaption = createBlock<'blocks_image_BlockType'>(({ image, caption }) => (
  <Container marginY="2xl">
    <Box rounded>
      <Image alt="" {...image} />
    </Box>
    <Text variant="small" marginY={2}>
      {caption}
    </Text>
  </Container>
));

export default ImageWithCaption;
