import { Button as MuiButton, ButtonProps as MuiButtonProps, ButtonTypeMap } from '@mui/material';

export type ButtonProps<
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = NoProps
> = MuiButtonProps<D, P>;

const Button = MuiButton;

export default Button;
