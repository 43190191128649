import Box, { BoxProps } from '@component/Box';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import * as style from './Divider.css';

export type DividerProps = Omit<BoxProps, 'children'> & { size?: BoxProps['marginY'] };

const Divider = ({ size, ...props }: DividerProps) => {
  return <Box marginY={size} {...mergePropsClassName(props, style.root({}))} />;
};

export default Divider;
