import { TextProps } from '@component/Text';
import { AppNavigationMenuName } from '@lib/api';
import { useNavMenu } from '@lib/store';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { Stack, StackProps } from '@mui/material';
import * as style from './NavMenu.css';
import { NavMenuItemSimple } from './NavMenuItemSimple';

export type NavMenuSimpleProps = StackProps<
  'nav',
  {
    name: AppNavigationMenuName;
    TextProps?: TextProps;
  }
>;

/**
 * Display an App NavMenu
 */
export const NavMenuSimple = ({ name, TextProps, ...props }: NavMenuSimpleProps) => {
  const { items = [] } = useNavMenu(name);

  return (
    <Stack component="nav" {...mergePropsClassName(props, style.root())}>
      {items.map((item, i) => (
        <NavMenuItemSimple key={i} TextProps={TextProps} item={item} />
      ))}
    </Stack>
  );
};
