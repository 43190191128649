import Box from '@component/Box';
import Container, { ContainerProps } from '@component/Container';
import Divider from '@component/Divider';
import Link from '@component/Link';
import Text from '@component/Text';
import { useEntryIndex } from '@lib/store/createEntryIndexStore';
import { useScrollToElement } from '@lib/utils/useScrollToElement';
import NextLink from 'next/link';
import { useEffect } from 'react';
import * as styles from './Search.css';

export type SearchIndexGridProps = ContainerProps;

export const SearchIndexGrid = (props: SearchIndexGridProps) => {
  const { hasQuery, query } = useEntryIndex((s) => ({
    hasQuery: !!s.args?.query,
    query: s.args?.query,
  }));

  const { ref, scrollToElement } = useScrollToElement();
  const entries = useEntryIndex((s) => s.entries ?? []) ?? [];

  // Scroll to results when the 'query' changes
  useEffect(() => {
    scrollToElement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return hasQuery ? (
    <Box ref={ref}>
      <Container {...props}>
        {entries.map((v, i) => (
          <NextLink key={i} href={v.uri ?? ''} passHref>
            <Box key={i} component="a" spacing="lg" stack className={styles.searchResultWrap}>
              <Link href={v.uri ?? ''} disableLink>
                <Text
                  variant="h4"
                  component="h2"
                  bold
                  marginBottom={0.5}
                  className={styles.searchResultTitle}>
                  {v.title}
                </Text>
              </Link>
              <Text html>{v.summary}</Text>
              <Divider size="xl" />
            </Box>
          </NextLink>
        ))}
      </Container>
    </Box>
  ) : (
    <></>
  );
};
