import React from 'react';
// import * as style from './TableBlock.css';
import { gql } from 'graphql-request';
import { createBlock } from '@lib/api/fragments/blocks';
import Container from '@component/Container';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import BlockHeader from '@component/BlockHeader';
import { colorVar } from '@theme/index';
import Text from '@component/Text';

gql`
  fragment tableBlock on blocks_table_BlockType {
    __typename
    heading
    content: htmlContentDefault
    table {
      columns {
        align
        heading
        width
      }
      rows
    }
  }
`;
const nbsp = <>&nbsp;</>;

const TableBlock = createBlock<'blocks_table_BlockType'>(({ heading, content, table }) => {
  const { columns = [], rows = [] } = table ?? {};

  return (
    <Container marginY="2xl">
      <BlockHeader {...{ heading, content }} />

      <TableContainer component={Paper} elevation={0}>
        <Table
          sx={
            {
              /* minWidth: 650 */
            }
          }>
          <TableHead>
            <TableRow sx={{ bgcolor: colorVar('greenLighter') }}>
              {columns.map(({ align, heading, width }, i) => (
                <TableCell key={i} sx={{ textAlign: align ?? 'left' }} width={width ?? 'auto'}>
                  <Text variant="normal" bold>
                    {heading || nbsp}
                  </Text>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i}>
                {row.map((cellContent, j) => (
                  <TableCell
                    key={j}
                    sx={{ textAlign: columns[j].align ?? 'left' }}
                    {...(!j && { component: 'th', scope: 'row' })}>
                    <Text variant="normal">{cellContent || nbsp}</Text>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
});

export default TableBlock;
