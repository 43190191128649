import * as Types from '../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
import { LinkFieldFragmentDoc } from '../../../lib/api/fragments/__generated__/linkField.generated';
export type HomePageCtaFragment = { __typename: 'homePageCtas_cta_BlockType', label?: string | null, heading?: string | null, content?: string | null, linkCta?: { __typename: 'linkField_Link', target?: string | null, text?: string | null, title?: string | null, type?: string | null, url?: string | null } | null };

export type HomePageCtasFragment = { __typename: 'homePageCtas_cta_BlockType', label?: string | null, heading?: string | null, content?: string | null, linkCta?: { __typename: 'linkField_Link', target?: string | null, text?: string | null, title?: string | null, type?: string | null, url?: string | null } | null };

export const HomePageCtaFragmentDoc = gql`
    fragment homePageCta on homePageCtas_cta_BlockType {
  __typename
  label
  heading: htmlHeading
  content: htmlContentSimple
  linkCta {
    ...linkField
  }
}
    `;
export const HomePageCtasFragmentDoc = gql`
    fragment homePageCtas on homePageCtas_NeoField {
  ...homePageCta
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;