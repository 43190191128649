import { keys } from '@liquorice/allsorts-craftcms-nextjs';
import { unpackProps } from '@liquorice/allsorts-craftcms-nextjs';
import { SprinkleKey, sprinkles, Sprinkles } from '../_sprinkles.css';

export type PropsWithSprinkles<
  P extends Props = {
    /*  */
  }
> = Sprinkles & Omit<P, SprinkleKey>;

export type PropsWithoutSprinkles<T extends PropsWithSprinkles> = Omit<T, SprinkleKey>;

export type UnpackedSprinkles<T extends PropsWithSprinkles> = {
  /**
   * ClassName produced by the sprinkles attributes
   */
  sprinklesClassName: string;
  /**
   * The combined original `className` value joined with `sprinklesClassName`
   */
  combinedClassName: string;
  /**
   * Extracted props that can be provided to the sprinkles function
   */
  sprinklesProps: Sprinkles;
  /**
   * Remaining component Props with sprinkles properties removed
   */
  rest: PropsWithoutSprinkles<T>;
};

/**
 * A Utility to extract and process Sprinkles properties from component Props
 */
export const unpackSprinkles = <T extends PropsWithSprinkles>(props: T): UnpackedSprinkles<T> => {
  const sx: { [P in SprinkleKey]: null } = {
    alignItems: null,
    backgroundColor: null,
    bgcolor: null,
    borderRadius: null,
    color: null,
    // gridColumnStart: null,
    // colSpan: null,
    // colStart: null,
    columnGap: null,
    rowGap: null,
    spacing: null,
    direction: null,
    display: null,
    filter: null,
    flexDirection: null,
    flexWrap: null,
    // gridColumn: null,
    justifyContent: null,
    margin: null,
    marginBottom: null,
    marginLeft: null,
    marginRight: null,
    marginTop: null,
    marginX: null,
    marginY: null,
    overflow: null,
    padding: null,
    paddingBottom: null,
    paddingLeft: null,
    paddingRight: null,
    paddingTop: null,
    paddingX: null,
    paddingY: null,
    ratio: null,
    textAlign: null,
    transition: null,
    wrap: null,
  };

  const { rest, unpacked: sprinklesProps } = unpackProps(props, keys(sx));

  const sprinklesClassName = sprinkles(sprinklesProps);
  const className = (rest as CommonProps).className ?? '';
  const combinedClassName = [sprinklesClassName, className].join(' ');

  return { sprinklesClassName, combinedClassName, sprinklesProps, rest };
};
