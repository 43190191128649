import { createBlock } from '@lib/api/fragments/blocks';
import { useForwardedRef } from '@lib/utils/useForwardedRef';
import { Stack } from '@mui/material';
import { motion, MotionProps } from 'framer-motion';
import { RefObject } from 'react';
import * as styles from './Infographic.css';
import { InfographicArrow } from './InfographicArrow';
import { InfographicContent, InfographicStat } from './InfographicStat';

export const InfographicItemBlock = createBlock<
  'infographicData_item_BlockType',
  {
    itemRef?: RefObject<HTMLElement>;
    MotionProps?: MotionProps;
    show?: boolean;
  }
>(({ itemRef: forwardedRef, ...props }) => {
  const {
    label,
    infographicColor: color,
    infographicItemSize: size,
    infographicLinkPrev,
    content,
    show: maybeShow = true,
    MotionProps: maybeMotionProps,
    _blockMeta,
  } = props;

  const ref = useForwardedRef(forwardedRef);

  const inView = _blockMeta?.inView ?? true;

  const { custom: maybeCustom = 0, ...motionProps } = {
    initial: 'hidden',
    animate: 'visible',
    ...maybeMotionProps,
  };

  const hasArrow = !!infographicLinkPrev;
  const hasLabel = !!label;
  const custom = hasArrow ? maybeCustom + 1 : maybeCustom;

  const show = maybeShow && inView;
  const showArrow = hasArrow && show;

  return (
    <motion.div {...motionProps}>
      <Stack
        ref={ref}
        // alignItems={'flex-end'}
        spacing={2}
        className={styles.item({ color, hasArrow, size })}>
        <InfographicStat
          className={styles.itemTitle}
          variant="h2"
          bold
          show={show}
          MotionProps={{
            custom,
            // animate: show ? 'visible' : 'hidden',
          }}>
          {label}
        </InfographicStat>
        <InfographicContent
          className={styles.itemContent}
          show={show}
          MotionProps={{
            custom: custom + (hasLabel ? 1 : 0),
            // animate: show ? 'visible' : 'hidden',
          }}
          variant="info">
          {content}
        </InfographicContent>
        {showArrow && (
          <InfographicArrow
            className={styles.itemArrow}
            shapeProps={{
              arrowWidth: 100,
              arrowHeight: 80,
            }}
            delay={3}
          />
        )}
      </Stack>
    </motion.div>
  );
});
// preceding
