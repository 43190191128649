import Text from '@component/Text';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import YourIrWidget from './YourIrWidget';

export const YourIRSharePrice = () => {
  return (
    <YourIrWidget>
      <Text color="primary" component="p" html variant="h1" mb={4}>
        <span data-yourir="price" />
        &nbsp;
      </Text>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableBody className="yourir-shareprice">
            <TableRow>
              <TableCell {...{ component: 'th', scope: 'row' }}>
                <Text bold color="primary" component="span">
                  {'Market'}
                </Text>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Text component="span" data-yourir="marketStatus" html>
                  &nbsp;
                </Text>
              </TableCell>
              <TableCell>Daily Open</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Text component="span" data-yourir="open" html>
                  &nbsp;
                </Text>
              </TableCell>
              <TableCell>Previous Close</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Text component="span" data-yourir="prevClose" html>
                  &nbsp;
                </Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell {...{ component: 'th', scope: 'row' }}>
                <Text bold color="primary" component="span">
                  {'Change'}
                </Text>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Text component="span" data-yourir="change" html>
                  &nbsp;
                </Text>
                <Text component="span" data-yourir="pctChange" html>
                  &nbsp;
                </Text>
              </TableCell>
              <TableCell>Intraday High</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Text component="span" data-yourir="high" html>
                  &nbsp;
                </Text>
              </TableCell>
              <TableCell>Intraday Low</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Text component="span" data-yourir="low" html>
                  &nbsp;
                </Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell {...{ component: 'th', scope: 'row' }}>
                <Text bold color="primary" component="span">
                  {'Volume'}
                </Text>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Text component="span" data-yourir="volume scale=false" html>
                  &nbsp;
                </Text>
              </TableCell>
              <TableCell>52 Week High</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Text component="span" data-yourir="yearHigh" html>
                  &nbsp;
                </Text>
              </TableCell>
              <TableCell>52 Week Low</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Text component="span" data-yourir="yearLow" html>
                  &nbsp;
                </Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6}>
                <Text variant="small">
                  {`Share price data as at `}
                  <span data-yourir="currentTime format='D MMMM, h:mma'" />
                  {` | Pricing delayed 20 minutes`}
                </Text>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </YourIrWidget>
  );
};
