// import { fonts } from 'theme/themeTypography';
import { MuiComponentOverrides } from '.';

const MuiButtonOverrides: MuiComponentOverrides<'MuiButton', 'MuiButton'> = {
  defaultProps: {
    variant: 'contained',
    color: 'secondary',
    size: 'medium',
    disableElevation: true,
  },
  styleOverrides: {
    root: ({ ownerState }) => ({
      padding: '11px 28px',
      borderRadius: 12,
      textTransform: 'unset',
      // fontFamily: fonts.sansSerifMedium,
      ...(ownerState.size === 'small' && {
        padding: '8px 14px',
      }),
      ...(ownerState.variant === 'text' && {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        textDecoration: 'underline',
        color: 'inherit',
      }),
      ...(ownerState.variant === 'contained' && {
        textAlign: 'center',
      }),
      ...(ownerState.variant === 'contained' &&
        ownerState.color === 'primary' && {
          ':hover': {
            backgroundColor: 'white',
          },
        }),
    }),
  },
};

export default MuiButtonOverrides;
