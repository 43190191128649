import * as Types from '../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type TableBlockFragment = { __typename: 'blocks_table_BlockType', heading?: string | null, content?: string | null, table?: { __typename?: 'table_TableMakerField', rows?: Array<Array<string | null> | null> | null, columns?: Array<{ __typename?: 'table_TableMakerField_column', align?: string | null, heading?: string | null, width?: string | null } | null> | null } | null };

export const TableBlockFragmentDoc = gql`
    fragment tableBlock on blocks_table_BlockType {
  __typename
  heading
  content: htmlContentDefault
  table {
    columns {
      align
      heading
      width
    }
    rows
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;