import BlockHeader from '@component/BlockHeader';
import Container from '@component/Container';
import { createBlock } from '@lib/api/fragments/blocks';
import { gql } from 'graphql-request';

gql`
  fragment richContentBlock on blocks_richContent_BlockType {
    __typename
    heading
    content: htmlContentRich
  }
`;

const RichContent = createBlock<'blocks_richContent_BlockType'>(
  ({ heading, content, _blockMeta: { first } = {} }) => (
    <Container marginTop={first ? undefined : '2xl'} marginBottom="2xl">
      <BlockHeader
        {...{ heading, content }}
        ContentProps={{
          withLede: first,
        }}
      />
    </Container>
  )
);

export default RichContent;
