import BlockHeader from '@component/BlockHeader';
import Container from '@component/Container';
import Formie from '@component/Formie';
import { createBlock } from '@lib/api/fragments/blocks';
import { Stack } from '@mui/material';
import { gql } from 'graphql-request';

gql`
  fragment formBlock on blocks_form_BlockType {
    __typename
    heading: formTitle
    content: htmlContentDefault
    # form {
    # ...form
    # }
    formieForm: form {
      ...formieForm
    }
  }
`;

const FormBlock = createBlock<'blocks_form_BlockType'>(({ heading, content, formieForm }) => {
  return (
    <Container marginY="2xl">
      <Stack maxWidth={550}>
        <BlockHeader {...{ heading, HeadingProps: { variant: 'h3' }, content }} />
        {/* {form && <Form form={form} />} */}
        <Formie form={formieForm ?? undefined} />
      </Stack>
    </Container>
  );
});

export default FormBlock;
