import Text from '@component/Text';
import { FieldErrors as FieldErrorsType } from '@formie/useForm';
import Warning from '@mui/icons-material/Warning';
import { Collapse, List, ListItem, ListItemIcon } from '@mui/material';

export type FieldErrorsProps = {
  errors?: FieldErrorsType;
};

export const FieldErrors = ({ errors = [] }: FieldErrorsProps) => {
  return (
    <Collapse in={!!errors.length} unmountOnExit>
      <List component="ul" disablePadding sx={{ marginY: 1 }}>
        {errors.map((v, i) => (
          <ListItem key={i} disableGutters>
            <ListItemIcon sx={{ minWidth: 25 }}>
              <Warning color="error" />
            </ListItemIcon>
            {/* <ListItemText>{v}</ListItemText> */}
            <Text variant="small" component="span">
              {v}
            </Text>
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
};
