import { toNumber } from '@liquorice/allsorts-craftcms-nextjs';
import { Pagination, Stack } from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { useYourIR } from './useYourIR';

import createStore from 'zustand';

export const useAnnouncementFeed = createStore<{
  yearMin: number;
  yearMax: number;
  pageTo?: number;
  page?: number;
  pageCount?: number;
  year?: string;
  filter?: string;
  setPageTo: (n?: number) => void;
  setPage: (n?: number) => void;
  setPageCount: (n?: number) => void;
  setYear: (value?: string) => void;
  setFilter: (value?: string) => void;
}>((set) => ({
  pageTo: undefined,
  setPageTo: (n) => set({ pageTo: n }),
  page: undefined,
  setPage: (n) => set({ page: n }),
  pageCount: undefined,
  setPageCount: (n) => set({ pageCount: n }),
  yearMin: 2010,
  yearMax: new Date().getFullYear(),
  year: undefined,
  setYear: (year) => set({ year }),
  filter: undefined,
  setFilter: (filter) => set({ filter }),
}));

export const YourIRAnnouncementsPagination = () => {
  const { yourIR } = useYourIR();
  const { year, pageTo, page, pageCount, setPageTo, setPage, setPageCount } = useAnnouncementFeed();

  useEffect(() => {
    const pageStr = yourIR?.get('announcements.page') as undefined | string;
    const newPage = pageStr ? toNumber(pageStr) : undefined;
    setPage(newPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yourIR, pageTo, year]);

  useEffect(() => {
    setTimeout(() => {
      const countStr = yourIR?.get('announcements.pageCount') as undefined | string;
      const newCount = countStr ? toNumber(countStr) : undefined;
      setPageCount(newCount);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yourIR, page, year]);

  const handlePaginationChange = (_ev: ChangeEvent<unknown>, n: number) => {
    yourIR?.set('announcements.page', n);
    setPageTo(n);
  };

  return pageCount && pageCount > 1 ? (
    <Stack alignItems="center" marginTop={6}>
      <Pagination onChange={handlePaginationChange} page={page ?? 1} count={pageCount ?? 1} />
    </Stack>
  ) : (
    <></>
  );
};
