import Box from '@component/Box';
import Container from '@component/Container';
import Grid from '@component/Grid';
import Text from '@component/Text';
import { createBlock } from '@lib/api/fragments/blocks';
import { gql } from 'graphql-request';
import FeatureListItem from './FeatureListItem';
import * as styles from './FeatureList.css';

gql`
  fragment featureListBlock on blocks_featureList_BlockType {
    __typename
    htmlHeading
    subheading
    darkTheme
    content: htmlContentDefault
    children {
      ...featureListItemBlock
    }
  }
`;

const FeatureList = createBlock<'blocks_featureList_BlockType'>((props) => {
  const { htmlHeading: heading, subheading, content, children: listItems } = props;

  const darkTheme = props.darkTheme ?? true;

  return (
    <Box paddingY="2xl" className={styles.root({ darkTheme })}>
      <Container>
        <Grid marginBottom="xl">
          <Grid.Col lg={10}>
            <Text
              color={styles.rootVars.headingColor}
              variant="h2"
              component="h2"
              html
              marginBottom="lg">
              {heading}
            </Text>
            <Text variant="h3" component="p" marginBottom="lg">
              {subheading}
            </Text>

            <Text html>{content}</Text>
          </Grid.Col>
        </Grid>

        <Grid rowGap="2xl">
          {listItems?.map((item, i) => (
            <FeatureListItem key={i} {...item} variant={darkTheme ? 'large' : 'small'} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
});

export default FeatureList;
