/* eslint-disable @typescript-eslint/no-explicit-any */

import { cleanHtml } from '@lib/utils/htmlHelpers';
import {
  createPropSanitiser,
  SanitiserTypes,
  toBoolean,
  toId,
  toStringOrNull,
  Typename,
} from '@liquorice/allsorts-craftcms-nextjs';

import {
  parseInfographicColor,
  parseInfographicItemSize,
} from '@component/Infographic/parseInfographic';
import { InfographicEntryFragment } from '@component/Infographic/__generated__/infographic.generated';
import { parseImageAnimationVariant } from '@component/StyledImage/parseImageAnimationVariant';
import { parseFormieForm } from '@formie/parse/formieParser';
import { EntriesFragment } from 'gql/__generated__/entries.generated';
import { parseAlignment, parseTable, parseTextAlignment, sanitiseHeading } from '../parse';
import { parseGraphEntry } from '../parseGraphEntry';
import { parseSeo } from '../parseSeo';
import { RawBlocks } from './blocks';
import { RawGlobals } from './globals';
import { parseImage, parseImageMany, parseStyledImageStyle } from './image';
import { parseDocument, parseLinkField } from './linkField';
import { parseAncestors, parsePagesTree } from './siteMap';
import { CategoryFragment } from './__generated__/categoryBase.generated';

// import { RawWidgets } from './widgets';

/**
 * Union of all Typed Elements that may be passed to the sanitiser
 */
type RawElements =
  | RawBlocks
  | RawGlobals
  | CategoryFragment
  | EntriesFragment
  | InfographicEntryFragment;

/**
 * __typename of top level Elements
 */
type ElementTypename = Typename<RawElements>;

// ----------------------------------------------------------------------------------------------------
// --- Define the callbacks ---

export const sanitiseAnything = createPropSanitiser((): RawElements | null => null, {
  alignment: parseAlignment,
  textAlignment: parseTextAlignment,
  ancestors: parseAncestors,
  // brandIcon: toStringOrNull,
  content: cleanHtml,
  customImage: parseImage,
  customSummary: cleanHtml,
  customTitle: toStringOrNull,
  // postDate: toString,
  downloadCta: parseLinkField,
  heading: toStringOrNull,
  id: toId,
  image: parseImage,
  images: parseImageMany,
  imageStyle: parseStyledImageStyle,
  documentSingle: parseDocument,
  bannerImage: parseImage,
  imageAnimation: parseImageAnimationVariant,
  isFeatured: toBoolean,
  table: parseTable,
  // label: toStringOrNull,
  linkCta: parseLinkField,
  // mdIcon: parseMaterialIcon,
  pagesBranch: parsePagesTree,
  title: toStringOrNull,
  pageTitle: sanitiseHeading,
  htmlHeading: sanitiseHeading,
  // form: parseForm,
  //
  graphSingle: parseGraphEntry,

  formieForm: parseFormieForm,
  //  parseEvent
  seomatic: parseSeo,

  /**
   * Recursively sanitise child elements
   */
  blocks: 'sanitise',
  socialLinks: 'sanitiseSingle',
  contactLinks: 'sanitiseSingle',
  media: 'sanitise',
  children: 'sanitise',
  articleSingle: 'sanitiseSingle',
  profileSingle: 'sanitiseSingle',
  insightSingle: 'sanitiseSingle',
  entrySingle: 'sanitiseSingle',
  pageNotices: 'sanitise',
  relatedEntries: 'sanitise',
  // Categories
  articleCategory: 'sanitise',
  insightCategory: 'sanitise',
  caseStudyCategory: 'sanitise',

  userAuthor: 'sanitiseSingle',
  author: 'sanitiseSingle',

  // propertyType: 'sanitise',
  /**
   * Infographic stuff
   */
  infographicSingle: 'sanitiseSingle',
  infographicData: 'sanitise',
  infographicColor: parseInfographicColor,
  infographicItemSize: parseInfographicItemSize,

  /**
   * Homepage stuff
   */
  homePageCtas: 'sanitise',
});

// ----------------------------------------------------------------------------------------------------
// --- Extracted sanitised types ---

type SanitiserReturnMap = SanitiserTypes<typeof sanitiseAnything, 'ReturnMap'>;

export type SanitisedElement<T extends ElementTypename = ElementTypename> = SanitiserReturnMap[T];

// ----------------------------------------------------------------------------------------------------
// --- Type guards ---

export const isSanitisedElement = <T extends ElementTypename>(
  x: any,
  typename: T
): x is SanitisedElement<T> => {
  return !!x && x.__typename === typename;
};
