import { createTheme } from '@mui/material';
import { vars, colorValues } from '@theme';
import componentOverrides from './componentOverrides';
import { applyTypography } from './muiThemeTypography';

const themeBase = createTheme({
  // status: {
  //   // danger: orange[500],
  // },

  palette: {
    primary: {
      light: colorValues.greenLight,
      main: colorValues.green,
      dark: colorValues.greenDarker,
    },
    secondary: {
      light: colorValues.pinkLight,
      main: colorValues.pink,
      dark: colorValues.pinkDarker,
    },
  },
  components: componentOverrides,
});

export const muiTheme = applyTypography(themeBase);
