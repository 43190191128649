import { getPublicEntryTypeIds, PublicEntryTypeId } from '@lib/config';
import { parseHref, trimSlashes } from '@liquorice/allsorts-craftcms-nextjs';
import { gql } from 'graphql-request';
import { GetStaticPathsResult } from 'next';
import client from './client';
import { getSdk } from './__generated__/getStaticPaths.generated';

gql`
  query listStaticPaths($entrySection: [String] = "", $categoryGroup: [String] = "") {
    categories(group: $categoryGroup, limit: 1000) {
      __typename
      uri
    }
    entries(section: $entrySection, limit: 1000) {
      __typename
      uri
    }
  }
`;

/**
 * Query the Craft API for all public static URIs
 * @see https://nextjs.org/docs/basic-features/data-fetching/get-static-paths
 */
export const getStaticPaths = async (
  args?: {
    entrySection?: PublicEntryTypeId;
    // categoryGroup?: PublicCategoryType | PublicCategoryType[];
  },
  fallback: GetStaticPathsResult['fallback'] = 'blocking'
): Promise<GetStaticPathsResult> => {
  // return { paths: ['/'], fallback };
  const entrySections = getPublicEntryTypeIds();
  const categoryGroups = ''; //getPublicCategory();

  const sdk = getSdk(client);
  const data =
    (await sdk.listStaticPaths(
      args ?? {
        entrySection: entrySections,
        categoryGroup: categoryGroups,
      }
    )) ?? [];

  const joinedData = [...(data.entries ?? []), ...(data.categories ?? [])];

  const blacklist = ['404', '__home__', 'search'];

  const paths = joinedData.reduce((results, item) => {
    const { href, external } = parseHref(item?.uri) || {};

    if (!href || external) return results;

    if (blacklist.includes(trimSlashes(href))) return results;

    return results.concat(href);
  }, [] as string[]);

  // console.log(paths, { nPages: paths.length });

  return { paths, fallback };
};
