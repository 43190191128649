import { popLeft, textReveal } from '@component/Shape/animations';
import Text, { TextProps } from '@component/Text';
import { stripHtmlTags } from '@lib/utils/htmlHelpers';
import { isString, mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { motion, MotionProps, useInView } from 'framer-motion';
import React, { useRef } from 'react';
import * as styles from './Infographic.css';

export type InfographicStatProps = TextProps & {
  MotionProps?: MotionProps;
  show?: boolean;
};

export const InfographicStat = ({
  children,
  show: maybeShow = true,
  MotionProps: maybeMotionProps,
  ...props
}: InfographicStatProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const inView = useInView(ref, {
    once: false,
    amount: 1,
    // margin: '-100px',
  });

  const show = maybeShow && inView;

  const motionProps = {
    variants: textReveal,
    // ...(!show && { animate: 'hidden' : 'visible' }),
    animate: show ? 'visible' : 'hidden',
    ...maybeMotionProps,
  };

  return (
    <div ref={ref} key={22} className={styles.stat}>
      <motion.div {...motionProps}>
        <Text variant="inherit" component="span" {...mergePropsClassName(props, styles.statText)}>
          {children}
        </Text>
      </motion.div>
      {/* {inView ? 'VISIBLE' : 'HIDDEN'} */}
    </div>
  );
};

export type InfographicContentProps = TextProps & {
  MotionProps?: MotionProps;
  show?: boolean;
};

export const InfographicContent = ({
  children,
  show: maybeShow = true,
  MotionProps: maybeMotionProps,
  ...props
}: InfographicContentProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const inView = useInView(ref, {
    once: false,
    amount: 1,
    // margin: '-100px',
  });

  const show = maybeShow && inView;

  const motionProps = {
    variants: popLeft,
    initial: 'hidden',
    animate: show ? 'visible' : 'hidden',
    ...maybeMotionProps,
  };

  const html = isString(children) ? stripHtmlTags(children, ['strong']) : null;

  const regex = /(<strong>[^<]+<\/strong>)/gs;

  let statCount = 0;

  const statCustom = motionProps.custom + 1;

  const content = !html
    ? children
    : html.split(regex).reduce((results, str, i) => {
        const isBold = regex.test(str);

        results.push(
          isBold ? (
            <React.Fragment key={i}>
              {` `}
              <InfographicStat
                key={i}
                bold
                show={show}
                MotionProps={{ custom: statCustom + statCount }}>
                {stripHtmlTags(str, [])}
              </InfographicStat>
              {` `}
            </React.Fragment>
          ) : (
            <React.Fragment key={i}>{str}</React.Fragment>
          )
        );

        if (isBold) statCount++;

        return results;
      }, [] as JSX.Element[]);

  return (
    <div ref={ref}>
      <motion.div {...motionProps}>
        <Text {...props}>{content}</Text>
      </motion.div>
    </div>
  );
};
