import * as Types from '../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
import { EntryBaseFragmentDoc, AuthorFragmentDoc } from '../../../gql/__generated__/entries.generated';
export type DatasetSingleFragment = { __typename: 'graphData_dataset_BlockType', label?: string | null, dataValue?: any | null, color?: { __typename?: 'color', label?: string | null } | null };

export type DatasetMultipleFragment = { __typename: 'graphData_datasetMultiple_BlockType', label?: string | null, color?: { __typename?: 'color', label?: string | null } | null, dataValues?: Array<{ __typename?: 'dataValues_TableRow', values?: any | null } | null> | null };

export type GraphEntryFragment = { __typename: 'graph_default_Entry', heading?: string | null, summary?: string | null, valueFormat?: string | null, datasetType?: string | null, typeHandle: string, searchScore?: number | null, id?: string | null, uri?: string | null, url?: string | null, title?: string | null, sectionHandle: string, postDate?: any | null, content?: string | null, labels?: Array<{ __typename?: 'labels_TableRow', label?: string | null } | null> | null, graphData?: Array<{ __typename: 'graphData_datasetMultiple_BlockType', label?: string | null, color?: { __typename?: 'color', label?: string | null } | null, dataValues?: Array<{ __typename?: 'dataValues_TableRow', values?: any | null } | null> | null } | { __typename: 'graphData_dataset_BlockType', label?: string | null, dataValue?: any | null, color?: { __typename?: 'color', label?: string | null } | null } | null> | null, author?: { __typename: 'User', displayName?: string | null } | null };

export const DatasetSingleFragmentDoc = gql`
    fragment datasetSingle on graphData_dataset_BlockType {
  __typename
  label
  color {
    label
  }
  dataValue
}
    `;
export const DatasetMultipleFragmentDoc = gql`
    fragment datasetMultiple on graphData_datasetMultiple_BlockType {
  __typename
  label
  color {
    label
  }
  dataValues {
    values
  }
}
    `;
export const GraphEntryFragmentDoc = gql`
    fragment graphEntry on graph_default_Entry {
  ...entryBase
  heading
  summary
  content: htmlContentSimple
  valueFormat
  datasetType
  labels {
    label
  }
  graphData {
    ...datasetSingle
    ...datasetMultiple
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;