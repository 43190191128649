import { RibbonSvg } from '@component/Shape';
import classNames from 'classnames';
import * as styles from './BackgroundRibbon.css';

export type BackgroundRibbonProps = {
  className?: string;
  disableAnimation?: boolean;
} & styles.BackgroundRibbonVariants;

const BackgroundRibbon = ({
  disableAnimation,
  className,
  color = 'orange',
  fullHeight,
}: BackgroundRibbonProps) => {
  return (
    <RibbonSvg
      disableAnimation={disableAnimation}
      className={classNames(styles.root({ color, fullHeight }), className)}
    />
  );
};

export default BackgroundRibbon;
