import { createShape, ShapeProps } from './Shape';

export const Swoop = createShape(({ strokeWidth, className }: ShapeProps) => (
  <svg
    width="1"
    height="1"
    viewBox="0 0 1 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...{ strokeWidth, className }}>
    <path
      d="M0.618919 0.347568C0.547568 0.347568 0.493533 0.347568 0.453514 0.347568H0C0.477297 0.0248649 0.461081 0 0.740019 0H1V0.347568H0.618919Z"
      fill="#F8D6E6"
    />
  </svg>
));
