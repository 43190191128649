/* eslint-disable @typescript-eslint/no-explicit-any */
import { BlockView, filterBlocks, parseSanitisedBlocks } from '@lib/api/fragments/blocks';
import { SanitisedElement } from '@lib/api/fragments/sanitiseElements';
import { toSnakeCase } from '@liquorice/allsorts-craftcms-nextjs';
import { InfographicColor } from '_generated/types';
import * as styles from './Infographic.css';

export const parseInfographicColor = (
  data: Maybe<InfographicColor>
): styles.InfographicColorVariant => {
  const label = data?.label ?? 'pinkGreen';

  return label in styles.infographicColorVariant
    ? (label as styles.InfographicColorVariant)
    : 'pinkGreen';
};

export type Infographic = SanitisedElement<'infographic_default_Entry'>;

export type InfographicBlockTypeName = Infographic['infographicData'][number];
export type InfographicBlock = Infographic['infographicData'][number];

export type InfographicBlockView = BlockView;
export const filterInfographicBlocks = <T extends InfographicBlock>(blocks: T[]) =>
  filterBlocks(parseSanitisedBlocks(blocks));

export const parseInfographicItemSize = (data: any): styles.InfographicSizeVariant | undefined => {
  const str = toSnakeCase(data);

  return str in styles.itemSize ? (str as styles.InfographicSizeVariant) : undefined;
};
