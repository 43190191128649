import { RecaptchaProvider } from '@component/Recaptcha';
import Layout from '@layout/Layout';
import { getDefaultMessages } from '@lib/l10n';
import { AppContextProvider } from '@lib/store';
import ThemeProvider from '@theme/ThemeProvider';
import { NextIntlProvider } from 'next-intl';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRef } from 'react';
import 'theme/gotham.css';

// FIXME
// TODO this is a hotfix for a YourIR css bug and should be removed
import 'theme/yourir.css';

export default function App(props: AppProps) {
  const { Component, pageProps } = props;

  const layoutRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <Head>
        {/* This should be overridden */}
        <title>ClearView</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      <NextIntlProvider messages={pageProps.messages ?? getDefaultMessages()}>
        <RecaptchaProvider>
          <AppContextProvider
            value={{
              ...pageProps.appContext,
              refs: {
                layout: layoutRef,
              },
            }}>
            <ThemeProvider>
              <Layout ref={layoutRef}>
                <Component {...pageProps} />
              </Layout>
            </ThemeProvider>
          </AppContextProvider>
        </RecaptchaProvider>
      </NextIntlProvider>
    </>
  );
}

// export function reportWebVitals(metric: NextWebVitalsMetric) {
//   console.log(metric);
// }
