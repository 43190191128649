import {
  Components,
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material';

import MuiInput from './MuiInput';
// import MuiAccordion from './MuiAccordion';
// import MuiAccordionDetails from './MuiAccordionDetails';
// import MuiAccordionSummary from './MuiAccordionSummary';
import MuiButton from './MuiButton';
import MuiTextField from './MuiTextField';
// import MuiChip from './MuiChip';
import MuiSelect from './MuiSelect';
// import MuiGrid from './MuiGrid';
import MuiFormHelperText from './MuiFormHelperText';

export type MuiComponentOverrides<
  C extends keyof ComponentsProps,
  D extends keyof ComponentsOverrides
> = {
  defaultProps?: ComponentsProps[C];
  styleOverrides?: ComponentsOverrides[D];
  variants?: ComponentsVariants[C];
};

const componentOverrides: Components = {
  MuiFormControl: {
    defaultProps: {
      variant: 'standard',
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: 18,
        fontWeight: 325,
      },
    },
  },
  MuiFormHelperText,
  MuiContainer: {
    defaultProps: {
      // maxWidth: 'false',
    },
  },
  MuiIcon: {
    styleOverrides: {
      root: {
        svg: {
          display: 'block',
        },
      },
    },
  },
  MuiSvgIcon: {
    defaultProps: {},
    styleOverrides: {
      root: {
        fontSize: 'inherit',
      },
    },
  },
  // MuiAccordion,
  // MuiAccordionDetails,
  // MuiAccordionSummary,
  MuiButton,
  // MuiChip,
  // MuiGrid,
  MuiInput,
  MuiTextField,
  MuiSelect,
};

export default componentOverrides;
