import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { Box } from '@mui/material';
import { useInView } from 'framer-motion';
import { useRef } from 'react';
import { Circle } from './Circle.svg';

import * as styles from './ComplexCircle.css';
import { Diamond } from './Diamond.svg';
import { SemicircleOutline } from './SemicircleOutline.svg';
import { ComplexShapeProps } from './Shape';

export type ComplexCircleProps = ComplexShapeProps<
  'diamond1' | 'diamond2' | 'semiCircleOutline1' | 'semiCircleOutline2' | 'circle'
>;

export const ComplexCircle = ({
  disableAnimation,
  diamond1Color = 'pink',
  diamond1Props,
  diamond2Color = 'orange',
  diamond2Props,
  semiCircleOutline1Color = 'yellow',
  semiCircleOutline1Props,
  semiCircleOutline2Color = 'pinkMid',
  semiCircleOutline2Props,
  circleColor = 'pink',
  circleProps,
  ...props
}: ComplexCircleProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const inView = useInView(ref, {
    amount: 1,
    once: true,
  });

  return (
    <Box ref={ref} {...mergePropsClassName(props, styles.root)}>
      {inView && (
        <>
          <SemicircleOutline
            disableAnimation={disableAnimation}
            color={semiCircleOutline1Color}
            {...mergePropsClassName(semiCircleOutline1Props, styles.semiCircleOutline1)}
          />
          <Circle
            disableAnimation={disableAnimation}
            initial={['shrink100', 'fade']}
            transitionProps={{ type: 'spring', duration: 1.5 }}
            color={circleColor}
            {...mergePropsClassName(circleProps, styles.circle)}
          />
          <SemicircleOutline
            disableAnimation={disableAnimation}
            color={semiCircleOutline2Color}
            strokeWidth={1.5}
            {...mergePropsClassName(semiCircleOutline2Props, styles.semiCircleOutline2)}
          />
          <Diamond
            disableAnimation={disableAnimation}
            initial={['shrink100', 'fade']}
            transitionProps={{ type: 'spring', delay: 1, duration: 0.5 }}
            color={diamond1Color}
            {...mergePropsClassName(diamond1Props, styles.diamond1)}
          />
          <Diamond
            disableAnimation={disableAnimation}
            initial={['shrink100', 'fade']}
            transitionProps={{ type: 'spring', delay: 1.3, duration: 0.5 }}
            color={diamond2Color}
            {...mergePropsClassName(diamond2Props, styles.diamond2)}
          />
        </>
      )}
    </Box>
  );
};
