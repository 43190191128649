/* eslint-disable @typescript-eslint/ban-types */
import { parseHtml, stripHtml } from '@lib/utils/htmlHelpers';
import { SxProps, Typography, TypographyTypeMap } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import { colorVar } from '@theme/index';
import { mixedContentClassName } from '@theme/_typography.css';
import { Color, vars } from '@theme/_vars.css';
import classNames from 'classnames';
import truncate from 'lodash/truncate';
import type { TruncateOptions } from 'lodash';
import React, { ReactNode } from 'react';
import * as styles from './Text.css';

// import style from './Text.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type TextTypeMap<P = {}, D extends React.ElementType = 'span'> = TypographyTypeMap<
  {
    /**
     * Parse any html in 'children'
     */
    html?: boolean;
    /**
     * Output when 'children' is empty
     */
    showEmpty?: boolean;
    /**
     * Character limit
     */
    maxChars?: number;

    /**
     * Provide customised truncating options
     */
    truncateOptions?: TruncateOptions;

    /**
     * Large first paragraph
     */
    withLede?: boolean;

    color?: Color;
  } & styles.TextVariants &
    P,
  D
>;

export type TextProps<
  D extends React.ElementType = TypographyTypeMap['defaultComponent'],
  P = {}
> = OverrideProps<TextTypeMap<P, D>, D>;

/**
 * A customised extension of {@link Typography} with added support for HTML parsing
 * If `html` eq `true`, the rendered element will be a `span` regardless of `component` prop
 */
const Text = <C extends React.ElementType>(
  {
    children: maybeChildren,
    showEmpty,
    bold,
    color,
    uppercase,
    html,
    maxChars,
    weight,
    truncateOptions,
    className,
    withLede,
    sx: customSx,
    ...props
  }: TextProps<C>,
  ref: React.ForwardedRef<HTMLElement>
) => {
  if (!maybeChildren && !showEmpty) return <></>;

  let output: ReactNode = '';
  let component: string | undefined;

  if (typeof maybeChildren !== 'string') {
    output = maybeChildren;
    component = 'span';
  } else if (html) {
    output = parseHtml(maybeChildren);
    component = 'div';
  } else if (maxChars || truncateOptions) {
    output = truncate(stripHtml(maybeChildren), {
      length: maxChars,
      ...truncateOptions,
    });
  } else {
    output = maybeChildren;
  }

  const sx: SxProps = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...(color && { color: colorVar(color) }),
    ...(weight && { fontWeight: vars.typography.weight[weight] }),
    ...(bold && { fontWeight: vars.typography.weight.bold }),
    ...(uppercase && { textTransform: 'uppercase' }),
  };

  const classes = classNames(className, styles.root({}), {
    [styles.withLede]: !!withLede,
    [mixedContentClassName]: !!html,
  });

  return (
    <Typography
      ref={ref}
      className={classes}
      {...{
        component,
        ...props,
        sx: { ...sx, ...customSx },
      }}>
      {output}
    </Typography>
  );
};

const TextWithRef = React.forwardRef(Text);

export default TextWithRef;
