import * as Types from '../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type EntrySeo_ArticleIndex_ArticleIndex_Entry_Fragment = { __typename?: 'articleIndex_articleIndex_Entry', seomatic?: { __typename: 'SeomaticType', metaTitleContainer?: string | null, metaTagContainer?: string | null, metaLinkContainer?: string | null } | null };

export type EntrySeo_Article_Default_Entry_Fragment = { __typename?: 'article_default_Entry', seomatic?: { __typename: 'SeomaticType', metaTitleContainer?: string | null, metaTagContainer?: string | null, metaLinkContainer?: string | null } | null };

export type EntrySeo_CaseStudyIndex_CaseStudyIndex_Entry_Fragment = { __typename?: 'caseStudyIndex_caseStudyIndex_Entry', seomatic?: { __typename: 'SeomaticType', metaTitleContainer?: string | null, metaTagContainer?: string | null, metaLinkContainer?: string | null } | null };

export type EntrySeo_CaseStudy_Default_Entry_Fragment = { __typename?: 'caseStudy_default_Entry', seomatic?: { __typename: 'SeomaticType', metaTitleContainer?: string | null, metaTagContainer?: string | null, metaLinkContainer?: string | null } | null };

export type EntrySeo_Graph_Default_Entry_Fragment = { __typename?: 'graph_default_Entry', seomatic?: { __typename: 'SeomaticType', metaTitleContainer?: string | null, metaTagContainer?: string | null, metaLinkContainer?: string | null } | null };

export type EntrySeo_Infographic_Default_Entry_Fragment = { __typename?: 'infographic_default_Entry', seomatic?: { __typename: 'SeomaticType', metaTitleContainer?: string | null, metaTagContainer?: string | null, metaLinkContainer?: string | null } | null };

export type EntrySeo_InsightIndex_InsightIndex_Entry_Fragment = { __typename?: 'insightIndex_insightIndex_Entry', seomatic?: { __typename: 'SeomaticType', metaTitleContainer?: string | null, metaTagContainer?: string | null, metaLinkContainer?: string | null } | null };

export type EntrySeo_Insight_Default_Entry_Fragment = { __typename?: 'insight_default_Entry', seomatic?: { __typename: 'SeomaticType', metaTitleContainer?: string | null, metaTagContainer?: string | null, metaLinkContainer?: string | null } | null };

export type EntrySeo_Notice_Default_Entry_Fragment = { __typename?: 'notice_default_Entry', seomatic?: { __typename: 'SeomaticType', metaTitleContainer?: string | null, metaTagContainer?: string | null, metaLinkContainer?: string | null } | null };

export type EntrySeo_Page_Default_Entry_Fragment = { __typename?: 'page_default_Entry', seomatic?: { __typename: 'SeomaticType', metaTitleContainer?: string | null, metaTagContainer?: string | null, metaLinkContainer?: string | null } | null };

export type EntrySeo_Page_Overview_Entry_Fragment = { __typename?: 'page_overview_Entry', seomatic?: { __typename: 'SeomaticType', metaTitleContainer?: string | null, metaTagContainer?: string | null, metaLinkContainer?: string | null } | null };

export type EntrySeo_Profile_Default_Entry_Fragment = { __typename?: 'profile_default_Entry', seomatic?: { __typename: 'SeomaticType', metaTitleContainer?: string | null, metaTagContainer?: string | null, metaLinkContainer?: string | null } | null };

export type EntrySeo_Search_Search_Entry_Fragment = { __typename?: 'search_search_Entry', seomatic?: { __typename: 'SeomaticType', metaTitleContainer?: string | null, metaTagContainer?: string | null, metaLinkContainer?: string | null } | null };

export type EntrySeoFragment = EntrySeo_ArticleIndex_ArticleIndex_Entry_Fragment | EntrySeo_Article_Default_Entry_Fragment | EntrySeo_CaseStudyIndex_CaseStudyIndex_Entry_Fragment | EntrySeo_CaseStudy_Default_Entry_Fragment | EntrySeo_Graph_Default_Entry_Fragment | EntrySeo_Infographic_Default_Entry_Fragment | EntrySeo_InsightIndex_InsightIndex_Entry_Fragment | EntrySeo_Insight_Default_Entry_Fragment | EntrySeo_Notice_Default_Entry_Fragment | EntrySeo_Page_Default_Entry_Fragment | EntrySeo_Page_Overview_Entry_Fragment | EntrySeo_Profile_Default_Entry_Fragment | EntrySeo_Search_Search_Entry_Fragment;

export type SeoContainersFragment = { __typename: 'SeomaticType', metaTitleContainer?: string | null, metaTagContainer?: string | null, metaLinkContainer?: string | null };

export const SeoContainersFragmentDoc = gql`
    fragment seoContainers on SeomaticInterface {
  __typename
  metaTitleContainer
  metaTagContainer
  metaLinkContainer
}
    `;
export const EntrySeoFragmentDoc = gql`
    fragment entrySeo on EntryInterface {
  seomatic(asArray: true) {
    ...seoContainers
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;