import Text, { TextProps } from '@component/Text';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export type RecaptchaProps = React.PropsWithChildren;

const RECAPTCHA_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
const USE_RECAPTCHA = !!RECAPTCHA_KEY;

export const useRecaptchaExecute = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptcha = React.useCallback(
    async (callback?: (token: string) => void) => {
      if (!USE_RECAPTCHA) return callback && callback('');

      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      const token = await executeRecaptcha('form');

      return callback && callback(token);
    },
    [executeRecaptcha]
  );

  return handleReCaptcha;
};

export const RecaptchaPolicy = (props: TextProps) => {
  return USE_RECAPTCHA ? (
    <Text html {...props}>
      {`This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and
      <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of service</a>
      apply.`}
    </Text>
  ) : null;
};

export const RecaptchaProvider = ({ children }: RecaptchaProps) => {
  return USE_RECAPTCHA ? (
    <GoogleReCaptchaProvider
      reCaptchaKey={RECAPTCHA_KEY}
      // useEnterprise
      scriptProps={{
        async: false, // optional, default to false,
        defer: true, // optional, default to false
        appendTo: 'body', // optional, default to "head", can be "head" or "body",
        nonce: undefined,
      }}
      container={{
        parameters: {
          badge: 'inline',
        },
      }}>
      {children}
    </GoogleReCaptchaProvider>
  ) : (
    <>{children}</>
  );
};
