import { useNextHydrated } from '@lib/utils/createToggleStore';
import { PropsWithChildren, useEffect } from 'react';
import 'theme/global.css';
import { defaultColorway, paper } from 'theme';

import { useBodyClassList } from './useBodyClassList';
import { useThemeClassName } from './useTheme';
import { CssBaseline } from '@mui/material';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { muiTheme } from '@theme/mui/muiTheme';
export type ThemeProviderProps = PropsWithChildren;

/**
 * Sets global styles and some client-side state
 */
const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const themeClassName = useThemeClassName([defaultColorway, paper]) ?? '';
  const setClassNames = useBodyClassList((s) => s.setClassNames);

  // allow localStorage rehydration
  useNextHydrated();

  useEffect(() => {
    setClassNames(themeClassName.split(' '));
    return () => setClassNames([]);
  }, [themeClassName, setClassNames]);

  return (
    <>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </>
  );
};

export default ThemeProvider;
