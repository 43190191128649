import Box, { BoxProps } from '@component/Box';
import Container from '@component/Container';
import IconButton from '@component/IconButton';
import Link from '@component/Link';
import Text from '@component/Text';
import { useGlobals } from '@lib/store';
import { createToggleStore } from '@lib/utils/createToggleStore';
import { firstNonNullable } from '@liquorice/allsorts-craftcms-nextjs';
import { ButtonBase } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import * as style from './AnnouncementBanner.css';

const useAnnouncementBannerState = createToggleStore(false, 'announcementBanner');

const AnnouncementBanner = () => {
  const t = useTranslations('cta');
  const announcementBanner = useGlobals('announcementBanner');
  const item = firstNonNullable(announcementBanner?.announcement);
  const { dateUpdated, active, color: maybeColor, message, linkCta, hideCta } = item ?? {};
  const {
    value: dismissed,
    toggle: setDismissed,
    setInvalidateKey,
    _hasHydrated: hasHydrated,
  } = useAnnouncementBannerState();

  const handleDismissClick = () => {
    setDismissed(true);
  };

  useEffect(() => {
    setInvalidateKey(dateUpdated ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateUpdated]);

  const show = active && hasHydrated && !dismissed;

  // -----------------------------------------------
  // Color
  const boxProps: BoxProps = {
    colorway: 'paper',
  };

  switch (maybeColor?.label) {
    case 'primary':
    case 'secondary':
      boxProps.colorway = maybeColor.label;
      boxProps.invertPaper = true;
      break;
    default:
      boxProps.colorway = 'primary';
      boxProps.paper = true;
  }

  // -----------------------------------------------
  // Link

  const { target: linkTarget, text: linkText = t('clickHere'), url: maybeUrl } = linkCta ?? {};
  const url = maybeUrl ?? undefined;
  const hasLink = !!url;

  const messageOutput = (
    <Box className={style.messageWrap}>
      <Text>{message}</Text>
      {hasLink && !hideCta && (
        <Link
          {...{
            className: style.cta,
            disableLink: true,
            color: 'inherit',
            underline: 'always',
          }}>
          {linkText}
        </Link>
      )}
    </Box>
  );

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          {...{
            style: { overflow: 'hidden' },
            initial: { height: 'auto' },
            animate: { height: 'auto' },
            exit: { height: 0 },
            transition: { duration: 0.2 },
          }}>
          <Box {...boxProps} className={style.root}>
            <Container>
              {hasLink ? (
                <ButtonBase
                  {...{
                    href: url,
                    target: linkTarget ?? undefined,
                  }}>
                  {messageOutput}
                </ButtonBase>
              ) : (
                messageOutput
              )}
              <Box className={style.dismissBtn}>
                <IconButton icon="close" color="inherit" onClick={handleDismissClick} />
              </Box>
            </Container>
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AnnouncementBanner;
