import Box, { BoxProps } from '@component/Box';
import IconButton from '@component/IconButton';
import { isNumeric, mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { SxProps } from '@mui/material';
import useEmblaCarousel, { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel-react';
import { useCallback, useEffect, useState } from 'react';
import * as styles from './Carousel.css';

export type CarouselSlideProps = PropsOverride<
  BoxProps,
  {
    /*  */
  }
>;

export const CarouselSlide = (props: CarouselSlideProps) => (
  <Box {...mergePropsClassName(props, 'embla__slide')} />
);

export type CarouselOptions = EmblaOptionsType;
export type CarouselApi = EmblaCarouselType;

export type CarouselProps = PropsOverride<
  BoxProps,
  styles.CarouselVariants & {
    onInit?: (api: CarouselApi) => void;
    onSlideChange?: (n: number) => void;
    items?: JSX.Element[];
    children?: JSX.Element[];
    options?: CarouselOptions;
    currentSlide?: number;
    ContainerProps?: BoxProps;
    SlideProps?: CarouselSlideProps;
    hideControls?: boolean;
    hideIndicators?: boolean;
  }
>;

const Carousel = ({
  onInit,
  onSlideChange,
  currentSlide = 0,
  options,
  overflow,
  items,
  children = items,
  vertical,
  SlideProps,
  ContainerProps,
  hideControls,
  hideIndicators,
  ...props
}: CarouselProps) => {
  const [ref, api] = useEmblaCarousel({
    ...(vertical && {
      axis: 'y',
      // align: 'start',
    }),
    ...options,
  });

  const [slideTo, setSlideTo] = useState<number>(currentSlide);

  // ----------------------------------------------------------------------------------------------
  // ---- Create change handler ----

  const handleSlideChange = useCallback(() => {
    if (!api) return;
    const newSlideN = api.selectedScrollSnap();
    setSlideTo(newSlideN);
    onSlideChange && onSlideChange(newSlideN);
  }, [api, onSlideChange]);

  // ----------------------------------------------------------------------------------------------
  // ---- Allow external control ----

  useEffect(() => {
    if (isNumeric(currentSlide)) api?.scrollTo(currentSlide);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide]);

  // ----------------------------------------------------------------------------------------------
  // ---- Attach handlers ----

  useEffect(() => {
    if (!api) return;

    onInit && onInit(api);

    api.on('select', handleSlideChange);

    return () => {
      api.off('select', handleSlideChange);
    };
  }, [api, onInit, handleSlideChange]);

  // ----------------------------------------------------------------------------------------------
  // ---- Create control callbacks ----

  const scrollPrev = useCallback(() => api?.scrollPrev(), [api]);

  const scrollNext = useCallback(() => api?.scrollNext(), [api]);

  // ----------------------------------------------------------------------------------------------

  const btnStyle: SxProps = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  };

  return (
    <Box
      ref={ref}
      colorway="secondary"
      {...mergePropsClassName(props, styles.root({ vertical, overflow }))}>
      <Box {...mergePropsClassName(ContainerProps, styles.container)}>
        {children?.map((v) => (
          <Carousel.Slide key={v.key} {...mergePropsClassName(SlideProps, styles.slide({}))}>
            {v}
          </Carousel.Slide>
        ))}
      </Box>

      {!hideIndicators && (
        <Box component="span" className={styles.indicators}>
          {children?.map((_, i) => (
            <Box
              component="span"
              // type="button"
              className={styles.indicator({ active: i === slideTo })}
              key={i}
              aria-label={`${i + 1}`}
            />
          ))}
        </Box>
      )}

      {!hideControls && (
        <>
          <IconButton
            sx={{ ...btnStyle, left: '1em' }}
            color="secondary"
            variant="contained"
            onClick={scrollPrev}
            icon="chevronLeft"
          />
          <IconButton
            sx={{ ...btnStyle, right: '1em' }}
            color="secondary"
            variant="contained"
            onClick={scrollNext}
            icon="chevronRight"
          />
        </>
      )}
    </Box>
  );
};

Carousel.Slide = CarouselSlide;

export default Carousel;
