import { AppNavigationMenuName, View } from '@lib/api';
import { filterBlocks, parseSanitisedBlocks, SanitisedBlock } from '@lib/api/fragments/blocks';
import { ImageEntry } from '@lib/api/fragments/image';
import { CommonLinkId, CommonLinks } from '@lib/api/getCommonLinks';
import { AppGlobals } from '@lib/api/getGlobals';
import { maybeGet, trimSlashes } from '@liquorice/allsorts-craftcms-nextjs';
import { useRouter } from 'next/router';
import React from 'react';
import { appContext } from './context';
import { EntryIndexKey } from './entryIndexes';

export const useView = () => {
  const { view } = React.useContext(appContext) ?? {};
  return view ?? ({} as Partial<View>);
};

export const useIndexData = <T extends EntryIndexKey>(indexKey: T) => {
  const { indexData } = React.useContext(appContext) ?? {};
  return indexData ? indexData[indexKey] : undefined;
};

/**
 * Check if a string matches the start of the current href path
 */
export const useIsCurrentHref = (str?: string | null) => {
  const currentPath = trimSlashes(useRouter().asPath ?? '');
  const path = trimSlashes(str ?? '');

  if (!path || !currentPath) return false;

  if (currentPath === path) return true;

  if (currentPath.startsWith(path)) return true;

  return false;
};

export const useGlobals = <T extends keyof AppGlobals>(name: T): AppGlobals[T] => {
  const { globals } = React.useContext(appContext) ?? {};
  return globals ? globals[name] : undefined;
};

export const useDefaultImage = (maybeImage?: Maybe<ImageEntry>) => {
  const { image } = useGlobals('defaults') ?? {};
  return maybeImage?.src ? maybeImage : image ?? null;
};

/* export const useWidgets = (): AppWidgets => {
  const { widgets } = React.useContext(appContext) ?? {};
  return widgets ?? {};
};

export const useWidget = <T extends WidgetTypeId>(name: T): Widget<T> | undefined => {
  const widgets = useWidgets();
  return widgets[name];
};
 */
export const useNavMenu = (name: AppNavigationMenuName) => {
  const { nav } = React.useContext(appContext) ?? {};
  return (nav && name in nav ? nav[name] : null) ?? { items: [], name };
};

export const useNavMenuBranch = () => {
  const name: AppNavigationMenuName = 'primaryNav';
  const { nav } = React.useContext(appContext) ?? {};
  return (nav && name in nav ? nav[name] : null) ?? { items: [], name };
};

export const useViewBlocks = () => {
  const { view } = React.useContext(appContext) ?? {};

  const blocks = maybeGet(view, 'blocks') ?? [];

  const parsedBlocks = parseSanitisedBlocks(blocks as SanitisedBlock[]);

  return filterBlocks(parsedBlocks);
};

export const useViewBannerImage = () => {
  const view = useView();
  const maybeBannerImage = maybeGet(view, 'bannerImage');
  const maybeImage = maybeGet(view, 'image');
  const bannerImage = maybeBannerImage ?? maybeImage;
  return bannerImage;
};

export const useAppRef = () => {
  const { refs = {} } = React.useContext(appContext) ?? {};
  return refs;
};

export const useAppLink = <T extends CommonLinkId>(typeId?: T): CommonLinks[T] | undefined => {
  const { commonLinks } = React.useContext(appContext) ?? {};

  return commonLinks && typeId ? commonLinks[typeId] : undefined;
};

// ------------------------------------------------------------------------------------------------
// ---- View Callbacks ----

export type ViewCallback<R, P> = (maybeView: Maybe<Partial<View>>, args?: P) => R;

export const createViewCallback = <R, P>(cb: ViewCallback<R, P>) => cb;

export const useIsAppPage = createViewCallback<boolean, CommonLinkId>((view, linkId) => {
  const viewId = view?.id;
  return useAppLink(linkId)?.id === viewId && !!viewId;
});

export const useIsViewHomepage = createViewCallback((view) => {
  return useIsAppPage(view, 'home');
});

export const useIsViewLandingTemplate = createViewCallback((view) => {
  return useIsViewHomepage(view) || maybeGet(view, 'typeHandle') === 'overview';
});

const viewCallbacks = {
  isAppPage: useIsAppPage,
  isHomePage: useIsViewHomepage,
  isLandingTemplate: useIsViewLandingTemplate,
};

type ViewCallbacks = typeof viewCallbacks;
type ViewCallbackKey = keyof ViewCallbacks;
type ViewCallbackProps<T extends ViewCallbackKey> = Parameters<ViewCallbacks[T]>[1];
type ViewCallbackReturnType<T extends ViewCallbackKey> = ReturnType<ViewCallbacks[T]>;

export const useViewCallback = <T extends ViewCallbackKey>(
  callbackName: ViewCallbackKey,
  args?: ViewCallbackProps<T>
) => {
  const view = useView();
  const cb = viewCallbacks[callbackName];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return cb(view, args as any) as ViewCallbackReturnType<T>;
};

// export type ViewTemplate = 'standard' | 'overview' | 'home'

// export const useViewTemplate = (
//   callbackName: ViewCallbackKey,
//   args?: ViewCallbackProps<T>
// ) => {
//   const view = useView();
//   const cb = viewCallbacks[callbackName];
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   return cb(view, args as any) as ViewCallbackReturnType<T>;
// };
