import BlockHeader from '@component/BlockHeader';
import Box from '@component/Box';
import Container from '@component/Container';
import DownloadButton from '@component/DownloadButton';
import { createBlock } from '@lib/api/fragments/blocks';
import { gql } from 'graphql-request';

gql`
  fragment downloadsBlock on blocks_downloads_BlockType {
    __typename
    heading
    children {
      ...downloadCtaBlock
    }
  }
`;

export const DownloadCtaBlock = createBlock<'blocks_downloadCta_BlockType'>(
  ({ documentSingle, label, summary }) =>
    documentSingle ? (
      <DownloadButton
        summary={summary ?? undefined}
        title={label ?? undefined}
        file={documentSingle}
      />
    ) : (
      <></>
    )
);

const DownloadsBlock = createBlock<'blocks_downloads_BlockType'>(({ heading, children }) => (
  <Container marginY="2xl">
    <BlockHeader {...{ heading /* content */ }} />
    <Box stack="column" spacing="lg">
      {children?.map((v, i) => (
        <DownloadCtaBlock key={i} {...v} />
      ))}
    </Box>
  </Container>
));

export default DownloadsBlock;
