import { motion } from 'framer-motion';
import { svgFill } from './animations';
import { createShape, ShapeProps } from './Shape';

export const Semicircle = createShape(({ disableAnimation, className, delay = 0 }: ShapeProps) => (
  <motion.svg
    className={className}
    initial={disableAnimation ? 'visible' : 'hidden'}
    animate="visible"
    width="140"
    height="70"
    viewBox="0 0 140 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <motion.path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.49846e-06 70H140C140 69.9952 140 69.9903 140 69.9855C139.993 51.4214 132.613 33.6203 119.483 20.4962C106.354 7.37203 88.5499 0 69.9859 0C51.4268 0.0074911 33.63 7.38319 20.5068 20.5065C7.3835 33.6299 0.00749106 51.4263 0 69.9855C0 69.9903 4.99496e-07 69.9952 1.49846e-06 70Z"
      fill="currentColor"
      variants={svgFill}
      custom={delay}
    />
  </motion.svg>
));
