import Box from '@component/Box';
import Grid, { GridColProps } from '@component/Grid';
import Image from '@component/Image';
import Link from '@component/Link';
import Text from '@component/Text';
import { createBlock } from '@lib/api/fragments/blocks';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { gql } from 'graphql-request';
import * as styles from './FeatureList.css';

gql`
  fragment featureListItemBlock on blocks_featureListItem_BlockType {
    __typename
    heading
    image {
      ...image
    }
    content: htmlContentSimple
    linkCta {
      ...linkField
    }
    wide: blockWidth
  }
`;

const FeatureListItem = createBlock<
  'blocks_featureListItem_BlockType',
  { variant?: 'large' | 'small' }
>((props) => {
  const { heading, image, content, linkCta, wide, variant } = props;
  const isLargeVariant = variant === 'large';

  const colProps: GridColProps = !!wide
    ? { xl: 8 }
    : {
        md: 8,
        lg: 6,
      };

  return (
    <Grid.Col {...mergePropsClassName(colProps, styles.listItemRoot({ wide: !!wide, variant }))}>
      <Box stack spacing="md" className={styles.itemInner}>
        {isLargeVariant && image && (
          <Box className={styles.itemImageWrap}>
            <Image ratio="square" contain alt="" {...image} />
          </Box>
        )}
        <Text
          color={styles.rootVars.headingColor}
          variant={isLargeVariant ? 'h3' : 'h4'}
          bold={!isLargeVariant}
          component="h3"
          html>
          {heading}
        </Text>
        <Text html>{content}</Text>
        {linkCta && (
          <Link
            {...linkCta}
            bold
            color={styles.rootVars.linkColor}
            // variant="large"
            linkDirection="forwards"
            underline="none"
          />
        )}
      </Box>
    </Grid.Col>
  );
});

export default FeatureListItem;
