import { CreateShapeProps } from '@component/Shape';
import { Arrow } from '@component/Shape/Arrow.svg';
import { forwardRef } from 'react';

export type InfographicArrowProps = CreateShapeProps;

export const InfographicArrow = forwardRef<HTMLDivElement, InfographicArrowProps>(
  function InfographicArrow(props, ref) {
    return <Arrow ref={ref} {...props} />;
  }
);
