import * as Types from '../../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type FieldBase_Field_Address_Fragment = { __typename: 'Field_Address', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Agree_Fragment = { __typename: 'Field_Agree', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Calculations_Fragment = { __typename: 'Field_Calculations', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Categories_Fragment = { __typename: 'Field_Categories', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Checkboxes_Fragment = { __typename: 'Field_Checkboxes', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Date_Fragment = { __typename: 'Field_Date', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Dropdown_Fragment = { __typename: 'Field_Dropdown', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Email_Fragment = { __typename: 'Field_Email', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Entries_Fragment = { __typename: 'Field_Entries', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_FileUpload_Fragment = { __typename: 'Field_FileUpload', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Group_Fragment = { __typename: 'Field_Group', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Heading_Fragment = { __typename: 'Field_Heading', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Hidden_Fragment = { __typename: 'Field_Hidden', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Html_Fragment = { __typename: 'Field_Html', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_MultiLineText_Fragment = { __typename: 'Field_MultiLineText', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Name_Fragment = { __typename: 'Field_Name', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Number_Fragment = { __typename: 'Field_Number', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Password_Fragment = { __typename: 'Field_Password', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Payment_Fragment = { __typename: 'Field_Payment', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Phone_Fragment = { __typename: 'Field_Phone', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Radio_Fragment = { __typename: 'Field_Radio', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Recipients_Fragment = { __typename: 'Field_Recipients', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Repeater_Fragment = { __typename: 'Field_Repeater', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Section_Fragment = { __typename: 'Field_Section', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Signature_Fragment = { __typename: 'Field_Signature', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_SingleLineText_Fragment = { __typename: 'Field_SingleLineText', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Summary_Fragment = { __typename: 'Field_Summary', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Table_Fragment = { __typename: 'Field_Table', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBase_Field_Users_Fragment = { __typename: 'Field_Users', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, defaultValue?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldBaseFragment = FieldBase_Field_Address_Fragment | FieldBase_Field_Agree_Fragment | FieldBase_Field_Calculations_Fragment | FieldBase_Field_Categories_Fragment | FieldBase_Field_Checkboxes_Fragment | FieldBase_Field_Date_Fragment | FieldBase_Field_Dropdown_Fragment | FieldBase_Field_Email_Fragment | FieldBase_Field_Entries_Fragment | FieldBase_Field_FileUpload_Fragment | FieldBase_Field_Group_Fragment | FieldBase_Field_Heading_Fragment | FieldBase_Field_Hidden_Fragment | FieldBase_Field_Html_Fragment | FieldBase_Field_MultiLineText_Fragment | FieldBase_Field_Name_Fragment | FieldBase_Field_Number_Fragment | FieldBase_Field_Password_Fragment | FieldBase_Field_Payment_Fragment | FieldBase_Field_Phone_Fragment | FieldBase_Field_Radio_Fragment | FieldBase_Field_Recipients_Fragment | FieldBase_Field_Repeater_Fragment | FieldBase_Field_Section_Fragment | FieldBase_Field_Signature_Fragment | FieldBase_Field_SingleLineText_Fragment | FieldBase_Field_Summary_Fragment | FieldBase_Field_Table_Fragment | FieldBase_Field_Users_Fragment;

export type FieldOptionFragment = { __typename: 'FieldOption', value?: string | null, label?: string | null, isDefault?: boolean | null };

export type FieldAttributeFragment = { __typename: 'FieldAttribute', value?: string | null, label?: string | null };

export const FieldBaseFragmentDoc = gql`
    fragment fieldBase on FieldInterface {
  __typename
  id
  name
  placeholder
  required
  labelPosition
  inputTypeName
  inputAttributes {
    label
    value
  }
  instructions
  handle
  defaultValue
  errorMessage
  cssClasses
  conditions
  enableConditions
  containerAttributes {
    label
    value
  }
}
    `;
export const FieldOptionFragmentDoc = gql`
    fragment fieldOption on FieldOption {
  __typename
  value
  label
  isDefault
}
    `;
export const FieldAttributeFragmentDoc = gql`
    fragment fieldAttribute on FieldAttribute {
  __typename
  value
  label
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;