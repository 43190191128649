import { makeNonNullableArray, maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import { EntriesFragment } from 'gql/__generated__/entries.generated';
import { gql } from 'graphql-request';
import { BlockExtrasQueryVariables, getSdk } from './__generated__/blockExtras.generated';
import client from './client';
import { Entry, parseEntry } from './fragments/entries';

gql`
  query blockExtras($latestNews: Boolean = false) {
    ...latestNewsBlockExtras @include(if: $latestNews)
  }
`;

/**
 * Make any subsequent queries that may be required by 'Blocks' in the current View
 * For example, the 'latest news' block requires article cards
 */
export const getBlockExtras = async (entry: Entry) => {
  const viewBlocks = maybeGet(entry, 'blocks') ?? [];

  const sdk = getSdk(client);

  // check the current view for instances of particular blocks
  const variables: BlockExtrasQueryVariables = {
    latestNews: !!viewBlocks.find((v) => v.__typename === 'blocks_latestArticlesWidget_BlockType'),
  };

  const extras = await sdk.blockExtras(variables);

  // ----------------------------------------------------------------------------------------------
  // --- Latest Article ---
  const maybeLatestArticles = makeNonNullableArray(extras.latestArticles) as EntriesFragment[];
  const latestArticles: Entry<'article'>[] =
    (maybeLatestArticles.map((v) => parseEntry(v, 'article')) as Entry<'article'>[]) ?? [];

  return { latestArticles }; // ? sanitiseAnything() : null;
};

export type BlockExtras = NonNullable<ReturnOrPromiseType<typeof getBlockExtras>>;
