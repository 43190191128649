import Box from '@component/Box';
import Grid, { GridColProps } from '@component/Grid';
import Image from '@component/Image';
import Text from '@component/Text';
import { createBlock } from '@lib/api/fragments/blocks';
import { gql } from 'graphql-request';
import Link from 'next/link';
import * as styles from './LogoGrid.css';

gql`
  fragment logoGridItemBlock on blocks_logoGridItem_BlockType {
    __typename
    url: urlString
    image {
      ...image
    }
    content: htmlContentSimple
  }
`;

const LogoGridItem = createBlock<'blocks_logoGridItem_BlockType'>((props) => {
  const { image, content, url } = props;

  const colProps: GridColProps = {
    span: 4,
    md: 4,
    lg: 2,
  };

  let imageOutput = <Image ratio="landscapeTall" transform="logo" contain alt="" {...image} />;

  if (url)
    imageOutput = (
      <Link href={url} passHref>
        <a href={url} target="_blank" rel="noreferrer">
          {imageOutput}
        </a>
      </Link>
    );

  return (
    <Grid.Col {...colProps}>
      <Box stack spacing="lg" className={styles.itemInner} alignItems="center">
        <Box className={styles.itemImageWrap}>{imageOutput}</Box>
        <Text html textAlign="center">
          {content}
        </Text>
      </Box>
    </Grid.Col>
  );
});

export default LogoGridItem;
