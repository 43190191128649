import { AppIconName } from '@component/Icon';
import { mapObject } from '@liquorice/allsorts-craftcms-nextjs';
import { toString } from '@liquorice/allsorts-craftcms-nextjs';
import { parseHref } from '@liquorice/allsorts-craftcms-nextjs';
import { gql } from 'graphql-request';
import client from './client';
import { getSdk, NavigationNodeFragment } from './__generated__/getNavigation.generated';

// ---------------------------------------------------------------------------------------------- //
// ---- GQL parts

gql`
  fragment navigationNode on NodeInterface {
    id
    uri
    url
    level
    title
    newWindow
    typeLabel
    classes
    parent {
      id
    }
    element {
      uri
      language
    }
    # ... on primaryNav_Node {
    #   mdIcon
    #   htmlContentSimple
    # }
    # ... on secondaryNav_Node {
    #   mdIcon
    # }
    # ... on contactLinks_Node {
    #   mdIcon
    # }
    # ... on intranetLink_Node {
    #   mdIcon
    # }
    # ... on footerNav_Node {
    #   mdIcon
    # }
  }
`;

gql`
  query navMenus {
    primaryNavNodes: navigationNodes(navHandle: "primaryNav") {
      ...navigationNode
    }
    policiesNavNodes: navigationNodes(navHandle: "policiesNav") {
      ...navigationNode
    }
    footerNavNodes: navigationNodes(navHandle: "footerNav") {
      ...navigationNode
    }
    loginNavNodes: navigationNodes(navHandle: "loginNav") {
      ...navigationNode
    }
    socialAccountsNavNodes: navigationNodes(navHandle: "socialAccountsNav") {
      ...navigationNode
    }
  }
`;

// ---------------------------------------------------------------------------------------------- //
// ---- Set up the Types

export type NavMenuItem = {
  order?: number;
  depth?: number;
  type: 'link' | 'passive';
  id: ID;
  parentId?: ID | null;
  title: string;
  href?: string | null;
  current?: boolean;
  currentParent?: boolean;
  descriptionHtml?: string | null;
  classes?: string[] | null;
  newWindow?: boolean;
  external?: boolean;
  icon: AppIconName | null;
  subItems?: NavMenuItem[];
};

export type NavMenuId = string;

export type NavMenu = {
  name?: string;
  items?: NavMenuItem[];
};

export type NavMenuItemCollection = { [key: ID]: NavMenuItem };

// ---------------------------------------------------------------------------------------------- //
// ---- Parsing Functions

/**
 * Recursively create a tree of NavMenuItems from the queried nodes
 */
const makeNavTree = (
  list: NavMenuItem[],
  collection: NavMenuItemCollection,
  branchId: ID | null = null,
  depth = 0
) => {
  const children = list.filter((item) => item.parentId === branchId);

  return children.map((item) => {
    (item.depth = depth), (item.subItems = makeNavTree(list, collection, item.id, depth + 1));
    return item;
  });
};

/**
 * Parsed the queried fragment
 */
const parseNavigation = (
  name?: string,
  data?: (NavigationNodeFragment | null)[] | null
): NavMenu => {
  const navItemsList = (data ?? []).reduce((results, item, order) => {
    if (!item || !item.id) return results;

    const { classes, url, uri, newWindow, typeLabel, title, element } = item;

    const { uri: elementUri /* language  */ } = element ?? {};
    // Prefer the element relative uri
    const maybeHref = elementUri ?? uri ?? url;

    const id: ID = toString(item.id);
    const parentId: ID | null = item.parent?.id ? toString(item.parent.id) : null;

    const type = typeLabel?.toLowerCase() === 'passive' ? 'passive' : 'link';

    const { external, href } = parseHref(maybeHref);

    const navItem: NavMenuItem = {
      order,
      id,
      icon: null,
      classes: toString(classes).split(' '),
      type,
      parentId,
      href,
      external,
      title: title ?? '',
      newWindow: newWindow === '1',
    };

    return results.concat(navItem);
  }, [] as NavMenuItem[]);

  const navItemsCollection = navItemsList.reduce(
    (results, item) => ({
      ...results,
      [item.id]: item,
    }),
    {} as NavMenuItemCollection
  );

  const items: NavMenuItem[] = makeNavTree(navItemsList, navItemsCollection);

  return {
    name,
    items,
  };
};

// ---------------------------------------------------------------------------------------------- //
// ---- The Getter ----

export const getAppNavigationMenus = async () => {
  const sdk = getSdk(client);
  const navMenus = await sdk.navMenus();

  const parsedMenus = mapObject(
    navMenus,
    (maybeMenu, key) => {
      if (!maybeMenu || typeof maybeMenu === 'string') return null;
      return parseNavigation(key, maybeMenu);
    },
    (v) => !!v
  );

  return {
    primaryNav: parsedMenus.primaryNavNodes,
    policiesNav: parsedMenus.policiesNavNodes,
    footerNav: parsedMenus.footerNavNodes,
    loginNav: parsedMenus.loginNavNodes,
    socialAccountsNav: parsedMenus.socialAccountsNavNodes,
  };
};

export type AppNavigationMenus = ReturnOrPromiseType<typeof getAppNavigationMenus>;

export type AppNavigationMenuName = keyof AppNavigationMenus;
