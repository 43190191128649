import Box, { BoxProps } from '@component/Box';
import Breadcrumbs from '@component/Breadcrumbs';
import Container from '@component/Container';
import Grid from '@component/Grid';
import Image from '@component/Image';
import { CreateShapeProps, Rhombus } from '@component/Shape';
import StyledImage, { StyledImageProps } from '@component/StyledImage';
import { parseImageAnimationVariant } from '@component/StyledImage/parseImageAnimationVariant';
import Text, { TextProps } from '@component/Text';
import { isSvg } from '@lib/api/fragments/image';
import { useView, useViewBannerImage, useViewCallback } from '@lib/store';
import { maybeGet, mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { Button } from '@mui/material';
import { Color } from '@theme/_vars.css';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import * as styles from './PageHeader.css';
import { PageTitle, PageTitleProps } from './PageTitle';

export type PageHeaderProps<T extends TagName = 'header'> = BoxProps<
  T,
  /* styles.PageHeaderVariants & */ {
    hideBreadcrumbs?: boolean;
    TitleProps?: PageTitleProps;
    SummaryProps?: TextProps;

    slotImage?: React.ReactNode;
    StyledImageProps?: StyledImageProps;
    BackgroundShapeProps?: CreateShapeProps;
    backgroundShapeFlip?: boolean;
  }
>;

const PageHeader = (props: PageHeaderProps) => {
  const isHomePage = useViewCallback('isHomePage');

  const {
    children,
    slotImage,
    hideBreadcrumbs = isHomePage,
    TitleProps,
    SummaryProps,
    StyledImageProps,
    BackgroundShapeProps,
    backgroundShapeFlip: maybeBackgroundShapeFlip,
    ...rest
  } = props;

  const view = useView();
  let bannerImage = useViewBannerImage();

  const imageAnimationVariant = parseImageAnimationVariant(
    StyledImageProps?.variant ?? maybeGet(view, 'imageAnimation'),
    'alt3'
  );

  const summary = maybeGet(view, 'summary');
  const linkCta = maybeGet(view, 'linkCta');

  // ----------------------------------------------------------------------------------------------
  // Decoration defaults
  /**
   * Whether to display image and background shapes
   */
  let showDecoration = true;

  let showBackgroundShape = showDecoration;
  let backgroundShapeFlip = maybeBackgroundShapeFlip ?? false;
  let backgroundShapeColor: Color = 'pinkLight';
  let alignImage: styles.PageHeaderVariants['alignImage'] = 'center';
  let large: styles.PageHeaderVariants['large'] = false;

  // ----------------------------------------------------------------------------------------------
  // ---- Handle SVGs ----

  if (isSvg(bannerImage)) {
    // Disable decoration if provided image is an SVG
    showDecoration = false;
  }

  // ----------------------------------------------------------------------------------------------
  // ---- Homepage variants ----

  switch (imageAnimationVariant) {
    case 'alt1':
    case 'genericOrangeCircle':
      //\
      break;
    case 'alt2':
    case 'genericYellowCircle':
      backgroundShapeColor = 'yellowLight';
      //
      break;
    case 'alt3':
    case 'news':
      //
      break;
    case 'alt4':
    case 'borderlessPink':
    case 'genericTransparent':
      backgroundShapeFlip = true;
      backgroundShapeColor = 'pinkLight';
      alignImage = 'bottom';
      //
      break;
    case 'borderlessYellow':
      backgroundShapeFlip = true;
      backgroundShapeColor = 'yellowLight';
      alignImage = 'bottom';
      //
      break;
    case 'alt5':
    case 'home':
      backgroundShapeColor = 'yellowLight';
      large = true;
      //
      break;
    case 'alt6':
    case 'genericYellowRectangle':
      //
      break;
    case 'alt7':
    case 'search':
      backgroundShapeFlip = true;
      backgroundShapeColor = 'yellowLight';
      bannerImage = null;
      //
      break;
    case 'alt8':
    case 'genericPinkCircle':
      backgroundShapeColor = 'yellowLight';
      //
      break;
    case 'wealthDiamond':
    case 'lifeCircle':
    case 'plant':
    case 'australiaMap':
      showBackgroundShape = false;
      break;
  }

  // ----------------------------------------------------------------------------------------------
  // ---- Misc layout ----

  const contentColFlipMobile = !backgroundShapeFlip;

  // ----------------------------------------------------------------------------------------------

  return (
    <Box
      // key={1}
      component="header"
      {...mergePropsClassName(rest, styles.root({ large, alignImage }))}>
      <Container className={styles.container}>
        <Grid rowGutter>
          <Grid.Col>
            {/* Breadcrumbs */}
            {!hideBreadcrumbs && <Breadcrumbs className={styles.breadcrumbs} />}
          </Grid.Col>

          <Grid.Col
            className={classNames(
              styles.contentCol,
              contentColFlipMobile && styles.contentColFlipMobile
            )}
            lg={5}>
            <motion.div
              // key={2}
              {...{
                initial: { opacity: 0, y: 50 },
                animate: { opacity: 1, y: 0 },
                transition: { duration: 0.6 },
              }}>
              <PageTitle color="primary" mb={3} {...TitleProps} />
            </motion.div>

            <motion.div
              {...{
                initial: { opacity: 0 },
                animate: { opacity: 1 },
                transition: { delay: 0.5, duration: 0.5 },
              }}>
              <Text variant="large" {...SummaryProps}>
                {summary}
              </Text>
              {linkCta?.href && <Button sx={{ mt: 3 }} {...linkCta} />}
              {children}
            </motion.div>
          </Grid.Col>

          <Grid.Col xs={8} offsetXs={2} md={6} offsetMd={3} lg={5} offsetLg={1}>
            {slotImage ? (
              slotImage
            ) : showDecoration ? (
              // Decorated Image Column
              <StyledImage
                {...mergePropsClassName(
                  {
                    variant: imageAnimationVariant,
                    image: bannerImage,
                    ImageProps: {
                      priority: true,
                      transform: { xs: 'squareLgCrop', mx: 'squareXlCrop' },
                    },
                    ...StyledImageProps,
                  },
                  styles.imageCol
                )}
              />
            ) : (
              <Image
                alt=""
                ratio="landscapeTall"
                ShimProps={{ marginBottom: 'xl' }}
                contain
                {...bannerImage}
              />
            )}
          </Grid.Col>
        </Grid>
        {showBackgroundShape && (
          <Rhombus
            key={0}
            initial={['fade']}
            transitionProps={{
              duration: 'xl',
              order: 2,
            }}
            {...mergePropsClassName(
              {
                color: backgroundShapeColor,
                ...BackgroundShapeProps,
              },
              styles.backgroundShape({ flip: backgroundShapeFlip })
            )}
          />
        )}
      </Container>
    </Box>
  );
};

export default PageHeader;
