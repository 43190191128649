/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteUndefined,
  firstNonNullable,
  makeNonNullableArray,
  pickFromUnion,
  toNumber,
  toString,
} from '@liquorice/allsorts-craftcms-nextjs';
import { gql } from 'graphql-request';
import { ImageFragment, ImageSize_AllFragment } from './__generated__/image.generated';

// fragment imageSize_thumb on AssetInterface {
//   size_thumb_url: url @transform(transform: "thumb")
//   size_thumb_height: height @transform(transform: "thumb")
//   size_thumb_width: width @transform(transform: "thumb")
// }

gql`
  fragment imageSize_original on AssetInterface {
    original_url: url
    original_height: width
    original_width: height
  }
  fragment imageSize_logo on AssetInterface {
    logo_url: url @transform(transform: "logo")
    logo_width: width @transform(transform: "logo")
    logo_height: height @transform(transform: "logo")
  }
  fragment imageSize_fullFit on AssetInterface {
    fullFit_url: url @transform(transform: "fullFit")
    fullFit_width: width @transform(transform: "fullFit")
    fullFit_height: height @transform(transform: "fullFit")
  }
  fragment imageSize_bannerLgCrop on AssetInterface {
    bannerLgCrop_url: url @transform(transform: "bannerLgCrop")
    bannerLgCrop_width: width @transform(transform: "bannerLgCrop")
    bannerLgCrop_height: height @transform(transform: "bannerLgCrop")
  }
  fragment imageSize_bannerXlCrop on AssetInterface {
    bannerXlCrop_url: url @transform(transform: "bannerXlCrop")
    bannerXlCrop_width: width @transform(transform: "bannerXlCrop")
    bannerXlCrop_height: height @transform(transform: "bannerXlCrop")
  }
  fragment imageSize_ultraWideCrop on AssetInterface {
    ultraWideCrop_url: url @transform(transform: "ultraWideCrop")
    ultraWideCrop_width: width @transform(transform: "ultraWideCrop")
    ultraWideCrop_height: height @transform(transform: "ultraWideCrop")
  }
  fragment imageSize_landscapeLgCrop on AssetInterface {
    landscapeLgCrop_url: url @transform(transform: "landscapeLgCrop")
    landscapeLgCrop_width: width @transform(transform: "landscapeLgCrop")
    landscapeLgCrop_height: height @transform(transform: "landscapeLgCrop")
  }
  fragment imageSize_landscapeSmCrop on AssetInterface {
    landscapeSmCrop_url: url @transform(transform: "landscapeSmCrop")
    landscapeSmCrop_width: width @transform(transform: "landscapeSmCrop")
    landscapeSmCrop_height: height @transform(transform: "landscapeSmCrop")
  }
  fragment imageSize_landscapeLgFit on AssetInterface {
    landscapeLgFit_url: url @transform(transform: "landscapeLgFit")
    landscapeLgFit_width: width @transform(transform: "landscapeLgFit")
    landscapeLgFit_height: height @transform(transform: "landscapeLgFit")
  }
  fragment imageSize_landscapeSmFit on AssetInterface {
    landscapeSmFit_url: url @transform(transform: "landscapeSmFit")
    landscapeSmFit_width: width @transform(transform: "landscapeSmFit")
    landscapeSmFit_height: height @transform(transform: "landscapeSmFit")
  }
  fragment imageSize_squareLgCrop on AssetInterface {
    squareLgCrop_url: url @transform(transform: "squareLgCrop")
    squareLgCrop_width: width @transform(transform: "squareLgCrop")
    squareLgCrop_height: height @transform(transform: "squareLgCrop")
  }
  fragment imageSize_squareXlCrop on AssetInterface {
    squareXlCrop_url: url @transform(transform: "squareXlCrop")
    squareXlCrop_width: width @transform(transform: "squareXlCrop")
    squareXlCrop_height: height @transform(transform: "squareXlCrop")
  }
  fragment imageSize_squareSmCrop on AssetInterface {
    squareSmCrop_url: url @transform(transform: "squareSmCrop")
    squareSmCrop_width: width @transform(transform: "squareSmCrop")
    squareSmCrop_height: height @transform(transform: "squareSmCrop")
  }

  fragment imageSize_all on AssetInterface {
    ...imageSize_original
    ...imageSize_logo
    ...imageSize_fullFit
    ...imageSize_bannerLgCrop
    ...imageSize_bannerXlCrop
    ...imageSize_ultraWideCrop
    ...imageSize_landscapeLgCrop
    ...imageSize_landscapeSmCrop
    ...imageSize_landscapeLgFit
    ...imageSize_landscapeSmFit
    ...imageSize_squareLgCrop
    ...imageSize_squareXlCrop
    ...imageSize_squareSmCrop
  }

  fragment image on AssetInterface {
    # __typename
    mimeType
    id
    alt
    title
    url @transform(transform: "fullFit")
    height @transform(transform: "fullFit")
    width @transform(transform: "fullFit")
    ...imageSize_all
  }
`;

export type ImageTransform = {
  url: string;
  height: number;
  width: number;
};

export type ImageTransformType = Extract<
  keyof ImageSize_AllFragment,
  `${string}_url`
> extends `${infer Size}_url`
  ? Size
  : never;

export type ImageTransforms = { [P in ImageTransformType]?: ImageTransform };

export const imageTransformKeys: ImageTransformType[] = [
  'original',
  'logo',
  'fullFit',
  'bannerLgCrop',
  'bannerXlCrop',
  'ultraWideCrop',
  'landscapeLgCrop',
  'landscapeSmCrop',
  'landscapeLgFit',
  'landscapeSmFit',
  'squareLgCrop',
  'squareXlCrop',
  'squareSmCrop',
];

export interface ImageEntry {
  noCrop?: boolean;
  mimeType: string;
  src: string;
  alt?: string;
  title?: string;
  caption?: string;
  height?: number;
  width?: number;
  srcSet?: string;
  transforms?: ImageTransforms;
}

export type ImageFragmentWithTransform = ImageFragment & ImageSize_AllFragment;

export const parseImage = (data: MaybeArrayOf<ImageFragmentWithTransform>): ImageEntry | null => {
  const image = firstNonNullable(data);

  if (!image || !image.url) return null;

  const { alt, title, url: src, height, width, mimeType } = pickFromUnion(image, []);

  const imageEntry: ImageEntry = {
    mimeType: toString(mimeType),
    src,
    height,
    width,
    alt,
    title,
    // caption,
    transforms: imageTransformKeys.reduce((result, transformType) => {
      const url = image[`${transformType}_url`];
      const width = toNumber(image[`${transformType}_width`]);
      const height = toNumber(image[`${transformType}_height`]);

      if (url)
        result[transformType] = {
          url,
          width,
          height,
        };

      return result;
    }, {} as ImageTransforms),
  };

  return deleteUndefined(imageEntry);
};

export const parseImageMany = (maybeImage: MaybeArrayOf<ImageFragmentWithTransform>) => {
  const imageArr = makeNonNullableArray(maybeImage);
  return imageArr.map(parseImage);
};

export const isSvg = (image?: Maybe<ImageEntry>) => {
  return image?.mimeType === 'image/svg+xml';
};

export type StyledImageStyle = 'default' | 'disableAnimation' | 'disableStyle';

export const parseStyledImageStyle = (data?: any): StyledImageStyle => {
  switch (data) {
    case 'disableAnimation':
    case 'disableStyle':
      return data;
    default:
      return 'default';
  }
};
