import * as Types from '../../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type ImageSize_Original_AssetsVolume_Asset_Fragment = { __typename?: 'assetsVolume_Asset', original_url?: string | null, original_height?: number | null, original_width?: number | null };

export type ImageSize_Original_DocumentsVolume_Asset_Fragment = { __typename?: 'documentsVolume_Asset', original_url?: string | null, original_height?: number | null, original_width?: number | null };

export type ImageSize_OriginalFragment = ImageSize_Original_AssetsVolume_Asset_Fragment | ImageSize_Original_DocumentsVolume_Asset_Fragment;

export type ImageSize_Logo_AssetsVolume_Asset_Fragment = { __typename?: 'assetsVolume_Asset', logo_url?: string | null, logo_width?: number | null, logo_height?: number | null };

export type ImageSize_Logo_DocumentsVolume_Asset_Fragment = { __typename?: 'documentsVolume_Asset', logo_url?: string | null, logo_width?: number | null, logo_height?: number | null };

export type ImageSize_LogoFragment = ImageSize_Logo_AssetsVolume_Asset_Fragment | ImageSize_Logo_DocumentsVolume_Asset_Fragment;

export type ImageSize_FullFit_AssetsVolume_Asset_Fragment = { __typename?: 'assetsVolume_Asset', fullFit_url?: string | null, fullFit_width?: number | null, fullFit_height?: number | null };

export type ImageSize_FullFit_DocumentsVolume_Asset_Fragment = { __typename?: 'documentsVolume_Asset', fullFit_url?: string | null, fullFit_width?: number | null, fullFit_height?: number | null };

export type ImageSize_FullFitFragment = ImageSize_FullFit_AssetsVolume_Asset_Fragment | ImageSize_FullFit_DocumentsVolume_Asset_Fragment;

export type ImageSize_BannerLgCrop_AssetsVolume_Asset_Fragment = { __typename?: 'assetsVolume_Asset', bannerLgCrop_url?: string | null, bannerLgCrop_width?: number | null, bannerLgCrop_height?: number | null };

export type ImageSize_BannerLgCrop_DocumentsVolume_Asset_Fragment = { __typename?: 'documentsVolume_Asset', bannerLgCrop_url?: string | null, bannerLgCrop_width?: number | null, bannerLgCrop_height?: number | null };

export type ImageSize_BannerLgCropFragment = ImageSize_BannerLgCrop_AssetsVolume_Asset_Fragment | ImageSize_BannerLgCrop_DocumentsVolume_Asset_Fragment;

export type ImageSize_BannerXlCrop_AssetsVolume_Asset_Fragment = { __typename?: 'assetsVolume_Asset', bannerXlCrop_url?: string | null, bannerXlCrop_width?: number | null, bannerXlCrop_height?: number | null };

export type ImageSize_BannerXlCrop_DocumentsVolume_Asset_Fragment = { __typename?: 'documentsVolume_Asset', bannerXlCrop_url?: string | null, bannerXlCrop_width?: number | null, bannerXlCrop_height?: number | null };

export type ImageSize_BannerXlCropFragment = ImageSize_BannerXlCrop_AssetsVolume_Asset_Fragment | ImageSize_BannerXlCrop_DocumentsVolume_Asset_Fragment;

export type ImageSize_UltraWideCrop_AssetsVolume_Asset_Fragment = { __typename?: 'assetsVolume_Asset', ultraWideCrop_url?: string | null, ultraWideCrop_width?: number | null, ultraWideCrop_height?: number | null };

export type ImageSize_UltraWideCrop_DocumentsVolume_Asset_Fragment = { __typename?: 'documentsVolume_Asset', ultraWideCrop_url?: string | null, ultraWideCrop_width?: number | null, ultraWideCrop_height?: number | null };

export type ImageSize_UltraWideCropFragment = ImageSize_UltraWideCrop_AssetsVolume_Asset_Fragment | ImageSize_UltraWideCrop_DocumentsVolume_Asset_Fragment;

export type ImageSize_LandscapeLgCrop_AssetsVolume_Asset_Fragment = { __typename?: 'assetsVolume_Asset', landscapeLgCrop_url?: string | null, landscapeLgCrop_width?: number | null, landscapeLgCrop_height?: number | null };

export type ImageSize_LandscapeLgCrop_DocumentsVolume_Asset_Fragment = { __typename?: 'documentsVolume_Asset', landscapeLgCrop_url?: string | null, landscapeLgCrop_width?: number | null, landscapeLgCrop_height?: number | null };

export type ImageSize_LandscapeLgCropFragment = ImageSize_LandscapeLgCrop_AssetsVolume_Asset_Fragment | ImageSize_LandscapeLgCrop_DocumentsVolume_Asset_Fragment;

export type ImageSize_LandscapeSmCrop_AssetsVolume_Asset_Fragment = { __typename?: 'assetsVolume_Asset', landscapeSmCrop_url?: string | null, landscapeSmCrop_width?: number | null, landscapeSmCrop_height?: number | null };

export type ImageSize_LandscapeSmCrop_DocumentsVolume_Asset_Fragment = { __typename?: 'documentsVolume_Asset', landscapeSmCrop_url?: string | null, landscapeSmCrop_width?: number | null, landscapeSmCrop_height?: number | null };

export type ImageSize_LandscapeSmCropFragment = ImageSize_LandscapeSmCrop_AssetsVolume_Asset_Fragment | ImageSize_LandscapeSmCrop_DocumentsVolume_Asset_Fragment;

export type ImageSize_LandscapeLgFit_AssetsVolume_Asset_Fragment = { __typename?: 'assetsVolume_Asset', landscapeLgFit_url?: string | null, landscapeLgFit_width?: number | null, landscapeLgFit_height?: number | null };

export type ImageSize_LandscapeLgFit_DocumentsVolume_Asset_Fragment = { __typename?: 'documentsVolume_Asset', landscapeLgFit_url?: string | null, landscapeLgFit_width?: number | null, landscapeLgFit_height?: number | null };

export type ImageSize_LandscapeLgFitFragment = ImageSize_LandscapeLgFit_AssetsVolume_Asset_Fragment | ImageSize_LandscapeLgFit_DocumentsVolume_Asset_Fragment;

export type ImageSize_LandscapeSmFit_AssetsVolume_Asset_Fragment = { __typename?: 'assetsVolume_Asset', landscapeSmFit_url?: string | null, landscapeSmFit_width?: number | null, landscapeSmFit_height?: number | null };

export type ImageSize_LandscapeSmFit_DocumentsVolume_Asset_Fragment = { __typename?: 'documentsVolume_Asset', landscapeSmFit_url?: string | null, landscapeSmFit_width?: number | null, landscapeSmFit_height?: number | null };

export type ImageSize_LandscapeSmFitFragment = ImageSize_LandscapeSmFit_AssetsVolume_Asset_Fragment | ImageSize_LandscapeSmFit_DocumentsVolume_Asset_Fragment;

export type ImageSize_SquareLgCrop_AssetsVolume_Asset_Fragment = { __typename?: 'assetsVolume_Asset', squareLgCrop_url?: string | null, squareLgCrop_width?: number | null, squareLgCrop_height?: number | null };

export type ImageSize_SquareLgCrop_DocumentsVolume_Asset_Fragment = { __typename?: 'documentsVolume_Asset', squareLgCrop_url?: string | null, squareLgCrop_width?: number | null, squareLgCrop_height?: number | null };

export type ImageSize_SquareLgCropFragment = ImageSize_SquareLgCrop_AssetsVolume_Asset_Fragment | ImageSize_SquareLgCrop_DocumentsVolume_Asset_Fragment;

export type ImageSize_SquareXlCrop_AssetsVolume_Asset_Fragment = { __typename?: 'assetsVolume_Asset', squareXlCrop_url?: string | null, squareXlCrop_width?: number | null, squareXlCrop_height?: number | null };

export type ImageSize_SquareXlCrop_DocumentsVolume_Asset_Fragment = { __typename?: 'documentsVolume_Asset', squareXlCrop_url?: string | null, squareXlCrop_width?: number | null, squareXlCrop_height?: number | null };

export type ImageSize_SquareXlCropFragment = ImageSize_SquareXlCrop_AssetsVolume_Asset_Fragment | ImageSize_SquareXlCrop_DocumentsVolume_Asset_Fragment;

export type ImageSize_SquareSmCrop_AssetsVolume_Asset_Fragment = { __typename?: 'assetsVolume_Asset', squareSmCrop_url?: string | null, squareSmCrop_width?: number | null, squareSmCrop_height?: number | null };

export type ImageSize_SquareSmCrop_DocumentsVolume_Asset_Fragment = { __typename?: 'documentsVolume_Asset', squareSmCrop_url?: string | null, squareSmCrop_width?: number | null, squareSmCrop_height?: number | null };

export type ImageSize_SquareSmCropFragment = ImageSize_SquareSmCrop_AssetsVolume_Asset_Fragment | ImageSize_SquareSmCrop_DocumentsVolume_Asset_Fragment;

export type ImageSize_All_AssetsVolume_Asset_Fragment = { __typename?: 'assetsVolume_Asset', original_url?: string | null, original_height?: number | null, original_width?: number | null, logo_url?: string | null, logo_width?: number | null, logo_height?: number | null, fullFit_url?: string | null, fullFit_width?: number | null, fullFit_height?: number | null, bannerLgCrop_url?: string | null, bannerLgCrop_width?: number | null, bannerLgCrop_height?: number | null, bannerXlCrop_url?: string | null, bannerXlCrop_width?: number | null, bannerXlCrop_height?: number | null, ultraWideCrop_url?: string | null, ultraWideCrop_width?: number | null, ultraWideCrop_height?: number | null, landscapeLgCrop_url?: string | null, landscapeLgCrop_width?: number | null, landscapeLgCrop_height?: number | null, landscapeSmCrop_url?: string | null, landscapeSmCrop_width?: number | null, landscapeSmCrop_height?: number | null, landscapeLgFit_url?: string | null, landscapeLgFit_width?: number | null, landscapeLgFit_height?: number | null, landscapeSmFit_url?: string | null, landscapeSmFit_width?: number | null, landscapeSmFit_height?: number | null, squareLgCrop_url?: string | null, squareLgCrop_width?: number | null, squareLgCrop_height?: number | null, squareXlCrop_url?: string | null, squareXlCrop_width?: number | null, squareXlCrop_height?: number | null, squareSmCrop_url?: string | null, squareSmCrop_width?: number | null, squareSmCrop_height?: number | null };

export type ImageSize_All_DocumentsVolume_Asset_Fragment = { __typename?: 'documentsVolume_Asset', original_url?: string | null, original_height?: number | null, original_width?: number | null, logo_url?: string | null, logo_width?: number | null, logo_height?: number | null, fullFit_url?: string | null, fullFit_width?: number | null, fullFit_height?: number | null, bannerLgCrop_url?: string | null, bannerLgCrop_width?: number | null, bannerLgCrop_height?: number | null, bannerXlCrop_url?: string | null, bannerXlCrop_width?: number | null, bannerXlCrop_height?: number | null, ultraWideCrop_url?: string | null, ultraWideCrop_width?: number | null, ultraWideCrop_height?: number | null, landscapeLgCrop_url?: string | null, landscapeLgCrop_width?: number | null, landscapeLgCrop_height?: number | null, landscapeSmCrop_url?: string | null, landscapeSmCrop_width?: number | null, landscapeSmCrop_height?: number | null, landscapeLgFit_url?: string | null, landscapeLgFit_width?: number | null, landscapeLgFit_height?: number | null, landscapeSmFit_url?: string | null, landscapeSmFit_width?: number | null, landscapeSmFit_height?: number | null, squareLgCrop_url?: string | null, squareLgCrop_width?: number | null, squareLgCrop_height?: number | null, squareXlCrop_url?: string | null, squareXlCrop_width?: number | null, squareXlCrop_height?: number | null, squareSmCrop_url?: string | null, squareSmCrop_width?: number | null, squareSmCrop_height?: number | null };

export type ImageSize_AllFragment = ImageSize_All_AssetsVolume_Asset_Fragment | ImageSize_All_DocumentsVolume_Asset_Fragment;

export type Image_AssetsVolume_Asset_Fragment = { __typename?: 'assetsVolume_Asset', mimeType?: string | null, id?: string | null, alt?: string | null, title?: string | null, url?: string | null, height?: number | null, width?: number | null, original_url?: string | null, original_height?: number | null, original_width?: number | null, logo_url?: string | null, logo_width?: number | null, logo_height?: number | null, fullFit_url?: string | null, fullFit_width?: number | null, fullFit_height?: number | null, bannerLgCrop_url?: string | null, bannerLgCrop_width?: number | null, bannerLgCrop_height?: number | null, bannerXlCrop_url?: string | null, bannerXlCrop_width?: number | null, bannerXlCrop_height?: number | null, ultraWideCrop_url?: string | null, ultraWideCrop_width?: number | null, ultraWideCrop_height?: number | null, landscapeLgCrop_url?: string | null, landscapeLgCrop_width?: number | null, landscapeLgCrop_height?: number | null, landscapeSmCrop_url?: string | null, landscapeSmCrop_width?: number | null, landscapeSmCrop_height?: number | null, landscapeLgFit_url?: string | null, landscapeLgFit_width?: number | null, landscapeLgFit_height?: number | null, landscapeSmFit_url?: string | null, landscapeSmFit_width?: number | null, landscapeSmFit_height?: number | null, squareLgCrop_url?: string | null, squareLgCrop_width?: number | null, squareLgCrop_height?: number | null, squareXlCrop_url?: string | null, squareXlCrop_width?: number | null, squareXlCrop_height?: number | null, squareSmCrop_url?: string | null, squareSmCrop_width?: number | null, squareSmCrop_height?: number | null };

export type Image_DocumentsVolume_Asset_Fragment = { __typename?: 'documentsVolume_Asset', mimeType?: string | null, id?: string | null, alt?: string | null, title?: string | null, url?: string | null, height?: number | null, width?: number | null, original_url?: string | null, original_height?: number | null, original_width?: number | null, logo_url?: string | null, logo_width?: number | null, logo_height?: number | null, fullFit_url?: string | null, fullFit_width?: number | null, fullFit_height?: number | null, bannerLgCrop_url?: string | null, bannerLgCrop_width?: number | null, bannerLgCrop_height?: number | null, bannerXlCrop_url?: string | null, bannerXlCrop_width?: number | null, bannerXlCrop_height?: number | null, ultraWideCrop_url?: string | null, ultraWideCrop_width?: number | null, ultraWideCrop_height?: number | null, landscapeLgCrop_url?: string | null, landscapeLgCrop_width?: number | null, landscapeLgCrop_height?: number | null, landscapeSmCrop_url?: string | null, landscapeSmCrop_width?: number | null, landscapeSmCrop_height?: number | null, landscapeLgFit_url?: string | null, landscapeLgFit_width?: number | null, landscapeLgFit_height?: number | null, landscapeSmFit_url?: string | null, landscapeSmFit_width?: number | null, landscapeSmFit_height?: number | null, squareLgCrop_url?: string | null, squareLgCrop_width?: number | null, squareLgCrop_height?: number | null, squareXlCrop_url?: string | null, squareXlCrop_width?: number | null, squareXlCrop_height?: number | null, squareSmCrop_url?: string | null, squareSmCrop_width?: number | null, squareSmCrop_height?: number | null };

export type ImageFragment = Image_AssetsVolume_Asset_Fragment | Image_DocumentsVolume_Asset_Fragment;

export const ImageSize_OriginalFragmentDoc = gql`
    fragment imageSize_original on AssetInterface {
  original_url: url
  original_height: width
  original_width: height
}
    `;
export const ImageSize_LogoFragmentDoc = gql`
    fragment imageSize_logo on AssetInterface {
  logo_url: url @transform(transform: "logo")
  logo_width: width @transform(transform: "logo")
  logo_height: height @transform(transform: "logo")
}
    `;
export const ImageSize_FullFitFragmentDoc = gql`
    fragment imageSize_fullFit on AssetInterface {
  fullFit_url: url @transform(transform: "fullFit")
  fullFit_width: width @transform(transform: "fullFit")
  fullFit_height: height @transform(transform: "fullFit")
}
    `;
export const ImageSize_BannerLgCropFragmentDoc = gql`
    fragment imageSize_bannerLgCrop on AssetInterface {
  bannerLgCrop_url: url @transform(transform: "bannerLgCrop")
  bannerLgCrop_width: width @transform(transform: "bannerLgCrop")
  bannerLgCrop_height: height @transform(transform: "bannerLgCrop")
}
    `;
export const ImageSize_BannerXlCropFragmentDoc = gql`
    fragment imageSize_bannerXlCrop on AssetInterface {
  bannerXlCrop_url: url @transform(transform: "bannerXlCrop")
  bannerXlCrop_width: width @transform(transform: "bannerXlCrop")
  bannerXlCrop_height: height @transform(transform: "bannerXlCrop")
}
    `;
export const ImageSize_UltraWideCropFragmentDoc = gql`
    fragment imageSize_ultraWideCrop on AssetInterface {
  ultraWideCrop_url: url @transform(transform: "ultraWideCrop")
  ultraWideCrop_width: width @transform(transform: "ultraWideCrop")
  ultraWideCrop_height: height @transform(transform: "ultraWideCrop")
}
    `;
export const ImageSize_LandscapeLgCropFragmentDoc = gql`
    fragment imageSize_landscapeLgCrop on AssetInterface {
  landscapeLgCrop_url: url @transform(transform: "landscapeLgCrop")
  landscapeLgCrop_width: width @transform(transform: "landscapeLgCrop")
  landscapeLgCrop_height: height @transform(transform: "landscapeLgCrop")
}
    `;
export const ImageSize_LandscapeSmCropFragmentDoc = gql`
    fragment imageSize_landscapeSmCrop on AssetInterface {
  landscapeSmCrop_url: url @transform(transform: "landscapeSmCrop")
  landscapeSmCrop_width: width @transform(transform: "landscapeSmCrop")
  landscapeSmCrop_height: height @transform(transform: "landscapeSmCrop")
}
    `;
export const ImageSize_LandscapeLgFitFragmentDoc = gql`
    fragment imageSize_landscapeLgFit on AssetInterface {
  landscapeLgFit_url: url @transform(transform: "landscapeLgFit")
  landscapeLgFit_width: width @transform(transform: "landscapeLgFit")
  landscapeLgFit_height: height @transform(transform: "landscapeLgFit")
}
    `;
export const ImageSize_LandscapeSmFitFragmentDoc = gql`
    fragment imageSize_landscapeSmFit on AssetInterface {
  landscapeSmFit_url: url @transform(transform: "landscapeSmFit")
  landscapeSmFit_width: width @transform(transform: "landscapeSmFit")
  landscapeSmFit_height: height @transform(transform: "landscapeSmFit")
}
    `;
export const ImageSize_SquareLgCropFragmentDoc = gql`
    fragment imageSize_squareLgCrop on AssetInterface {
  squareLgCrop_url: url @transform(transform: "squareLgCrop")
  squareLgCrop_width: width @transform(transform: "squareLgCrop")
  squareLgCrop_height: height @transform(transform: "squareLgCrop")
}
    `;
export const ImageSize_SquareXlCropFragmentDoc = gql`
    fragment imageSize_squareXlCrop on AssetInterface {
  squareXlCrop_url: url @transform(transform: "squareXlCrop")
  squareXlCrop_width: width @transform(transform: "squareXlCrop")
  squareXlCrop_height: height @transform(transform: "squareXlCrop")
}
    `;
export const ImageSize_SquareSmCropFragmentDoc = gql`
    fragment imageSize_squareSmCrop on AssetInterface {
  squareSmCrop_url: url @transform(transform: "squareSmCrop")
  squareSmCrop_width: width @transform(transform: "squareSmCrop")
  squareSmCrop_height: height @transform(transform: "squareSmCrop")
}
    `;
export const ImageSize_AllFragmentDoc = gql`
    fragment imageSize_all on AssetInterface {
  ...imageSize_original
  ...imageSize_logo
  ...imageSize_fullFit
  ...imageSize_bannerLgCrop
  ...imageSize_bannerXlCrop
  ...imageSize_ultraWideCrop
  ...imageSize_landscapeLgCrop
  ...imageSize_landscapeSmCrop
  ...imageSize_landscapeLgFit
  ...imageSize_landscapeSmFit
  ...imageSize_squareLgCrop
  ...imageSize_squareXlCrop
  ...imageSize_squareSmCrop
}
    `;
export const ImageFragmentDoc = gql`
    fragment image on AssetInterface {
  mimeType
  id
  alt
  title
  url @transform(transform: "fullFit")
  height @transform(transform: "fullFit")
  width @transform(transform: "fullFit")
  ...imageSize_all
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;