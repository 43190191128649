import { useNavMenu } from '@lib/store';
import { Stack, StackProps } from '@mui/material';
import { NavMenuItem } from './NavMenuItem';

export type NavPrimaryProps = StackProps;

const NavPrimary = ({ ...props }: NavPrimaryProps) => {
  const { items = [] } = useNavMenu('primaryNav');

  return (
    <Stack {...props}>
      {items.map((item, index) => (
        <NavMenuItem key={index} item={item} />
      ))}
    </Stack>
  );
};

export default NavPrimary;
