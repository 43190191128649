import BackgroundRibbon, { backgroundRibbonWrap } from '@component/BackgroundRibbon';
import Box from '@component/Box';
import Container from '@component/Container';
import Grid from '@component/Grid';
import Image from '@component/Image';
import Text from '@component/Text';
import { createBlock } from '@lib/api/fragments/blocks';
import classNames from 'classnames';
import { gql } from 'graphql-request';
import * as styles from './Quote.css';

gql`
  fragment quoteBlock on blocks_quote_BlockType {
    __typename
    summary
    image {
      ...image
    }
    caption
  }
`;

const QuoteBlock = createBlock<'blocks_quote_BlockType'>(({ summary, image, caption }) => {
  return (
    <Box className={classNames(styles.root, backgroundRibbonWrap({}))}>
      <Container>
        <Grid>
          <Grid.Col xl={11}>
            <Text variant="h1" component="p" marginBottom="lg" className={styles.text}>
              {summary}
            </Text>
            <Box className={styles.ctaWrap}>
              {image && (
                <Box className={styles.imageWrap}>
                  <Image alt="" ratio="square" {...image} className={styles.image} />
                </Box>
              )}
              <Text className={styles.caption}>{caption}</Text>
            </Box>
          </Grid.Col>
        </Grid>
      </Container>
      <BackgroundRibbon color={'yellow'} fullHeight />
    </Box>
  );
});

export default QuoteBlock;
