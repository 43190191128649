import { useEntryIndex } from '@lib/store/createEntryIndexStore';
import { Pagination, PaginationProps } from '@mui/material';
import React from 'react';

export type EntryIndexPaginationProps = PaginationProps;

const EntryIndexPagination = ({ onChange, sx, ...props }: EntryIndexPaginationProps) => {
  const { trigger, page, count } = useEntryIndex(({ page, pages: count, trigger }) => ({
    page,
    count,
    trigger,
  }));

  const handleChange = (ev: React.ChangeEvent<unknown>, pageTo: number) => {
    trigger({ page: pageTo });

    onChange && onChange(ev, pageTo);
  };

  return count > 1 ? (
    <Pagination
      onChange={handleChange}
      {...{
        page,
        count,
        sx: {
          display: 'flex',
          justifyContent: 'center',
          marginY: 6,
          ...sx,
        },
        ...props,
      }}
    />
  ) : (
    <></>
  );
};

export default EntryIndexPagination;
