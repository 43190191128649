import {
  isArray,
  makeNonNullableArray,
  toTypedElementArray,
} from '@liquorice/allsorts-craftcms-nextjs';
import { toId, toString } from '@liquorice/allsorts-craftcms-nextjs';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import { parseHref } from '@liquorice/allsorts-craftcms-nextjs';
import { PageEntryFragment } from 'gql/__generated__/entries.generated';
import { gql } from 'graphql-request';
import { NavMenuItem } from '../getNavigation';
import { PageBranchFragment, PageLeafFragment } from './__generated__/siteMap.generated';

gql`
  fragment pageLeaf on page_default_Entry {
    title
    uri
    id
  }

  fragment pageBranch on page_default_Entry {
    __typename
    ...pageLeaf
    children {
      ...pageLeaf
      children {
        ...pageLeaf
      }
    }
  }
`;

export const parsePagesTreeBranch = (
  maybeTree: Maybe<PageBranchFragment | PageLeafFragment>
): NavMenuItem | null => {
  if (!maybeTree) return null;

  const { title, uri, id } = maybeTree;
  const children = (maybeGet(maybeTree, 'children') ?? []) as Maybe<
    PageBranchFragment | PageLeafFragment
  >[];

  return {
    type: 'link',
    icon: null,
    title: toString(title),
    href: parseHref(uri).href ?? '',
    id: toId(id),
    subItems: makeNonNullableArray(children.map((v) => parsePagesTreeBranch(v))),
  };
};

export const parsePagesTree = (maybeTree: Maybe<PageEntryFragment['pagesBranch']>) => {
  const items = toTypedElementArray(maybeTree);
  const item = isArray(items) ? items[0] : null;
  return item ? parsePagesTreeBranch(item) : null;
};

export const parseAncestors = (data: Maybe<PageEntryFragment['ancestors']>) => {
  return makeNonNullableArray(data).map((v) => ({
    id: toId(v.id),
    title: toString(v.title),
    uri: parseHref(v.uri).href ?? '',
  }));
};
