// import { ApolloError, isApolloError } from '@apollo/client';
import { keys } from '@liquorice/allsorts-craftcms-nextjs';
import { GraphQLClient } from 'graphql-request';

const {
  API_MODE,
  CRAFT_CMS_GRAPHQL_ENDPOINT = '/api/pass/',
  CRAFT_CMS_GRAPHQL_TOKEN = '',
} = process?.env || {};

/**
 * Graphql Client
 */

export const getClient = (queryArgs?: Record<string, string>) => {
  let endpoint = CRAFT_CMS_GRAPHQL_ENDPOINT;

  if (queryArgs)
    endpoint =
      endpoint +
      '?' +
      keys(queryArgs)
        .reduce((result, key) => [...result, `${key}=${queryArgs[key]}`], [] as string[])
        .join('&');

  // console.log({ endpoint });

  const client = new GraphQLClient(
    endpoint,
    API_MODE === 'server' // Headers change between client/server requests
      ? {
          credentials: 'include',
          mode: 'cors',
        }
      : {}
  ).setHeaders({
    /**
     * Set with ENV vars, matching the CMS setting
     */
    authorization: `Bearer ${CRAFT_CMS_GRAPHQL_TOKEN}`,
  });

  return client;
};

const client = getClient();

export default client;
