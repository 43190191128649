import { createShape, ShapeProps } from './Shape';

export const Circle = createShape(({ strokeWidth, className }: ShapeProps) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...{ strokeWidth, className }}>
    <ellipse cx="39.9983" cy="39.5998" rx="39.5998" ry="39.5998" fill="currentColor" />
  </svg>
));
