/* eslint-disable @typescript-eslint/no-explicit-any */
import entriesConfig from '@lib/entriesConfig';
import { keys } from '@liquorice/allsorts-craftcms-nextjs';
import { EntryTypeId as SanitiserEntryTypeId } from './api/fragments/entries';

/**
 * Identifying prop in a query, used for a given EntryType
 */
type QueryType = 'uri' | 'slug' | 'id';

/**
 * Variants used to inform fetching and parsing EntryType data
 */
type SectionType = 'single' | 'singleIndex' | 'entry';

const defaultQueryType: QueryType = 'id';
const defaultSectionType: SectionType = 'entry';

/**
 * Derived entry type definitions
 */
type EntryTypes = typeof entriesConfig[number];

/**
 * Union of entry type IDs available in this App
 */
type EntryTypeId = Extract<EntryTypes['typeId'], SanitiserEntryTypeId>;
// type EntryTypename<T extends EntryTypeId> = `${T}_default_Entry`;

type EntryTypeIdToEntryTypeMap = { [P in EntryTypeId]: EntryType<P> };

/**
 * Map of {@link EntryTypes}, indexed by {@link EntryTypeId}
 */
export const entryTypeIdToEntryTypeMap = entriesConfig.reduce((results, item) => {
  return {
    ...results,
    [item.typeId]: item,
  };
}, {} as EntryTypeIdToEntryTypeMap);

/**
 * Function returns the {@link EntryType} for {@link EntryTypeId} T
 */
export type EntryType<T extends EntryTypeId = EntryTypeId> = Extract<EntryTypes, { typeId: T }>;

/**
 * Retrieve a list of each {@link EntryTypeId}
 */
export const getEntryTypeIds = (): EntryTypeId[] => {
  return keys(entryTypeIdToEntryTypeMap);
};

/**
 * {@link EntryTypeId} type guard
 */
export const isEntryTypeId = (x?: string): x is EntryTypeId => {
  return !!x && x in entryTypeIdToEntryTypeMap;
};

/**
 * Provide a {@link EntryTypeId} to retrieve the related {@link EntryType} definition
 */
export const getEntryTypeById = <T extends EntryTypeId | string>(
  x: T
): T extends EntryTypeId ? EntryType<T> : null => {
  return isEntryTypeId(x) ? entryTypeIdToEntryTypeMap[x] : (null as any);
};

/**
 * Retrieve the default query method {@link QueryType} for a given {@link EntryTypeId}
 */
export const getEntryQueryType = <T extends EntryTypeId>(entryTypeId: T): QueryType => {
  return getEntryTypeById(entryTypeId)?.queryType ?? defaultQueryType;
};

/**
 * Retrieve the {@link SectionType} for a given {@link EntryTypeId}
 */
export const getEntrySectionType = <T extends EntryTypeId>(entryTypeId: T): SectionType => {
  const type = getEntryTypeById(entryTypeId);
  return 'sectionType' in type ? (type as any).sectionType : defaultSectionType;
};

// ----------------------------------------------------------------------------------------------------
// --- Types and Checks for Single Entry Types ---

export type SingleEntryTypeId = Extract<
  EntryType,
  { sectionType: 'single' | 'singleIndex' }
>['typeId'];

/**
 * {@link SingleEntryTypeId} type guard
 */
export const isSingleEntryTypeId = (x: EntryTypeId | string): x is SingleEntryTypeId => {
  return isEntryTypeId(x) && ['single', 'singleIndex'].includes(getEntrySectionType(x));
};

// ----------------------------------------------------------------------------------------------------
// --- Types and Checks for Entry Index Pages ---

export type EntryIndexTypeId = Extract<EntryType, { sectionType: 'singleIndex' }>['typeId'];

/**
 * {@link EntryIndexTypeId} type guard
 */
export const isEntryIndexTypeId = (x?: EntryTypeId | string): x is EntryIndexTypeId => {
  return isEntryTypeId(x) && ['singleIndex'].includes(getEntrySectionType(x));
};

// ----------------------------------------------------------------------------------------------------
// --- Types and Checks for entry types with a URL ---

export type PublicEntryTypeId = Extract<EntryType, { queryType: 'slug' | 'uri' }>['typeId'];

/**
 * {@link PublicEntryTypeId} type guard
 */
export const isPublicEntryTypeId = (x: EntryTypeId | string): x is PublicEntryTypeId => {
  return isEntryTypeId(x) && ['slug', 'uri'].includes(getEntryQueryType(x));
};

/**
 * Retrieve an array of {@link EntryTypeId} for entry types which
 * have a "single" page with a unique URL
 */
export const getPublicEntryTypeIds = (): PublicEntryTypeId[] =>
  getEntryTypeIds().filter(isPublicEntryTypeId);
