import Script from 'next/script';

import { useNextHydrated } from '@lib/utils/createToggleStore';
import type { AnalyticsBrowser } from '@segment/analytics-next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const SEGMENT_KEY = process.env.NEXT_PUBLIC_SEGMENT_KEY;

declare const analytics: AnalyticsBrowser;

export const SeoScripts = () => {
  const { asPath } = useRouter();
  const nextHydrated = useNextHydrated();
  // const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (typeof analytics !== 'undefined') analytics?.page(asPath);
  }, [asPath]);

  if (!nextHydrated || !SEGMENT_KEY) return <></>;

  return (
    <Script id="segment-analytics">{`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="VYgeSL26ZKOXEEhY3BSKWdULHopbJzHX";;analytics.SNIPPET_VERSION="4.15.3";
  analytics.load("${SEGMENT_KEY}");
  analytics.page();
  }}();`}</Script>
  );
};
