import Box from '@component/Box';
import Container from '@component/Container';
import Grid from '@component/Grid';
import Text from '@component/Text';
import { createBlock } from '@lib/api/fragments/blocks';
import { gql } from 'graphql-request';
import LogoGridItem from './LogoGridItem';

gql`
  fragment logoGridBlock on blocks_logoGrid_BlockType {
    __typename
    htmlHeading

    content: htmlContentSimple
    children {
      ...logoGridItemBlock
    }
  }
`;

const LogoGrid = createBlock<'blocks_logoGrid_BlockType'>((props) => {
  const { htmlHeading: heading, content, children: listItems } = props;

  return (
    <Box bgcolor="greyLight" paddingY="2xl">
      <Container>
        <Grid marginBottom="xl" textAlign="center" justifyContent="center">
          <Grid.Col lg={10}>
            <Text variant="h2" component="h2" color="primary" html marginBottom="lg">
              {heading}
            </Text>

            <Text html>{content}</Text>
          </Grid.Col>
        </Grid>
        <Grid justifyContent="center" rowGap="2xl">
          {listItems?.map((item, i) => (
            <LogoGridItem key={i} {...item} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
});

export default LogoGrid;
