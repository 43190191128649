import Close from '@mui/icons-material/Close';
import Menu from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import { useNavbarState } from '../NavBar/useNavbarState';

export const NavBarMenuToggleButton = () => {
  const { setNavOpen, navOpen } = useNavbarState(({ setNavOpen, navOpen }) => ({
    setNavOpen,
    navOpen,
  }));

  return (
    <IconButton onClick={() => setNavOpen(!navOpen)}>{navOpen ? <Close /> : <Menu />}</IconButton>
  );
};
