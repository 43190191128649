import { motion, MotionProps } from 'framer-motion';
import { createShape, ShapeProps } from './Shape';

export const RibbonSvg = ({
  strokeWidth,
  className,
  disableAnimation,
}: ShapeProps<MotionProps>) => {
  return (
    <motion.svg
      width="2186"
      height="1000"
      viewBox="0 0 2186 1000"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ strokeWidth, className }}>
      <defs>
        <motion.pattern
          id="bg"
          //
          patternUnits="userSpaceOnUse"
          // patternUnits="objectBoundingBox"
          //
          width="2186"
          height="1000">
          <path
            d="M1093.64 499.781L546.819 815.464L0 499.781L546.819 184.098L1093.64 499.781Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="10"
          />
          <path
            d="M1093.62 1000H987.746C888.035 1000 836.584 980.869 674.155 889.033L546.592 815.48L1093.41 499.797V1000H1093.62Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="10"
          />
          <path
            d="M105.877 0C221.747 0 272.559 25.9349 507.7 161.562L546.819 184.095L0 499.779V0H105.877Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="10"
          />
          <path
            d="M1092.73 499.781L1639.54 815.464L2186.36 499.781L1639.54 184.098L1092.73 499.781Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="10"
          />
          <path
            d="M1092.72 1000H1198.6C1298.31 1000 1349.76 980.869 1512.19 889.033L1639.75 815.48L1092.93 499.797V1000H1092.72Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="10"
          />
          <path
            d="M2080.49 0C1964.62 0 1913.8 25.9349 1678.66 161.562L1639.54 184.095L2186.36 499.779V0H2080.49Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="10"
          />
        </motion.pattern>
      </defs>
      <motion.rect
        y="0"
        x="-200%"
        width="500%"
        height="1000px"
        fill="url(#bg)"
        {...(!disableAnimation && {
          animate: { x: '100%' },
          transition: { repeat: Infinity, duration: 25, ease: 'linear' },
        })}
      />
    </motion.svg>
  );
};

export const Ribbon = createShape(({ strokeWidth, className }: ShapeProps) => (
  <RibbonSvg {...{ strokeWidth, className }} />
));
