/* eslint-disable import/no-anonymous-default-export */

const strings = {
  common: {
    siteName: 'ClearView',
    homePage: 'Home',
    onThisPage: 'On this page',
    relatedContent: 'You might also be interested in',
    featured: 'Featured',
    copyrightSymbol: `©`,
    copyright: `© {name} {year}. All rights reserved. `,
    top: 'Top',
    all: 'All',
    author: 'Author',
    published: 'Published',
    noResults: 'Sorry, your search produced no results',
  },
  contact: {
    emailLabel: '{value}',
    phoneLabel: '{value}',
  },
  article: {
    latest: 'Latest articles',
    name: 'Articles',
    singularName: 'Article',
    viewAll: 'View all articles',
    indexLink: 'Read more',
  },
  articleCategory: {
    name: 'Categories',
    singularName: 'Category',
    allCategories: 'All Categories',
  },
  cta: {
    discover: 'Find out more',
    more: 'Read more',
    clickHere: 'Click here',
  },
  search: {
    inputPlaceholder: 'Search',
    formCta: 'How can we help?',
    submit: 'Submit',
  },
  actions: {
    login: 'Login',
    active: 'active',
    clear: 'Clear',
    disabled: 'disabled',
    enabled: 'enabled',
    filter: 'Filter',
    filterBy: 'Filter by:',
    sortBy: 'Sort by',
    filterByCategory: 'Filter by category',
    filterByYear: 'Filter by year',
    inactive: 'inactive',
    search: 'Search',
    submit: 'Submit',
    share: 'Share',
  },
  feedback: {
    error: 'Error',
    success: 'Success',
  },
};

export default strings;
