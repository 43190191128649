import { keys } from '@liquorice/allsorts-craftcms-nextjs';
import { SeomaticType } from '_generated/types';
import { gql } from 'graphql-request';

gql`
  fragment entrySeo on EntryInterface {
    seomatic(asArray: true) {
      ...seoContainers
    }
  }

  fragment seoContainers on SeomaticInterface {
    __typename
    metaTitleContainer
    metaTagContainer
    metaLinkContainer
    # metaSiteVarsContainer
  }
`;

export type SeoTagType = 'link' | 'meta' | 'title';
export type SeoTag = {
  key?: string;
  type: SeoTagType;
  props: Record<string, string>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSeoTagProps = (x?: any): x is SeoTag['props'] => {
  if (!x || typeof x !== 'object') return false;

  if (Array.isArray(x)) return false;

  return true; //['link', 'meta', 'title'].includes(x.type);
};

export const parseSeo = (maybeData: Maybe<SeomaticType>) => {
  const metaTagStrings: Record<SeoTagType, Maybe<string>> = {
    link: maybeData?.metaLinkContainer,
    meta: maybeData?.metaTagContainer,
    title: maybeData?.metaTitleContainer,
  };

  const metaTagTypes = keys(metaTagStrings);
  const tags: SeoTag[] = [];

  metaTagTypes.forEach((tagType) => {
    const metaString = metaTagStrings[tagType];
    const maybeTagsObject = metaString ? JSON.parse(metaString) : null;

    if (!maybeTagsObject) return;

    const tagKeys = keys(maybeTagsObject);

    tagKeys.forEach((k) => {
      if (typeof k !== 'string') return;
      const tagProps = k in maybeTagsObject ? maybeTagsObject[k] : null;

      if (isSeoTagProps(tagProps))
        tags.push({
          type: tagType,
          props: tagProps,
        });
    });
  });

  return tags;
};
