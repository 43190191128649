import Icon from '@component/Icon';
import { TextStyleVariants } from '@component/Text/Text.css';
import { useTextStyle } from '@component/Text/useTextStyle';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { Link as MuiLink, LinkProps as MuiLinkProps, LinkTypeMap } from '@mui/material';
import classNames from 'classnames';
import NextLink from 'next/link';
import * as styles from './Link.css';

export type LinkProps<
  D extends React.ElementType = LinkTypeMap['defaultComponent'],
  P = NoProps
> = MuiLinkProps<
  D,
  {
    disableLink?: boolean;
    linkDirection?: 'forwards' | 'backwards' | 'up' | 'down';
  } & styles.LinkVariants &
    TextStyleVariants &
    P
>;

const Link = <D extends React.ElementType = LinkTypeMap['defaultComponent'], P = NoProps>({
  href: maybeHref,
  children,
  disableLink,
  underline,
  underlineColor,
  linkDirection,
  ...props
}: LinkProps<D, P>) => {
  const { className: textStyleClassName, rest } = useTextStyle(props);

  let iconBefore: JSX.Element | null = null;
  let iconAfter: JSX.Element | null = null;

  switch (linkDirection) {
    case 'forwards':
      iconAfter = <Icon className={styles.icon} name="arrowRight" />;
      break;
    case 'up':
      iconAfter = <Icon className={styles.icon} name="arrowUp" />;
      break;
    case 'down':
      iconAfter = <Icon className={styles.icon} name="arrowDown" />;
      break;
    case 'backwards':
      iconBefore = <Icon className={styles.icon} name="arrowLeft" />;
      break;

    default:
      break;
  }

  const href = !disableLink ? maybeHref : undefined;

  const inner = (
    <MuiLink
      component={href ? 'a' : 'span'}
      href={href}
      underline="none"
      {...mergePropsClassName(
        rest,
        classNames(textStyleClassName, styles.root({ linkDirection, underline, underlineColor }))
      )}>
      {iconBefore}
      {children}
      {iconAfter}
    </MuiLink>
  );

  return href ? <NextLink href={href}>{inner}</NextLink> : inner;
};

export default Link;
