import Link from '@component/Link';
import Text from '@component/Text';
import { Box } from '@mui/material';
import * as styles from './YourIrWidget.css';

export const YourIRAnnouncementItem = () => (
  <Box padding={4} borderRadius={4} className={styles.card}>
    <span className={styles.headingLinkWrap}>
      <span data-yourir="$cur.heading" />
    </span>
    <Text color="primary" variant="h6">
      <span data-yourir="$cur.date format='D MMMM YYYY'" />
    </Text>
    <Text color="primary" variant="h4" bold component="h2" html>
      <span data-yourir="$cur.headingText" />
    </Text>
    <Link
      variant="large"
      href="#"
      fontWeight="medium"
      disableLink
      linkDirection="down"
      underline="none">
      Download <span data-yourir="$cur.fileType" />
    </Link>
    {/* <span data-yourir="$cur.attachment">Copy link</span> */}
    {/* <a data-yourir="linkAnnouncement https://example.com/announcements">Copy link</a> */}

    {/* <a data-yourir="href /announcements/{$cur.symbol}/{$cur.fileID}; viewAnnouncementOnMobile {$cur.symbol} {$cur.fileID}; $cur.headingText"></a> */}
    {/* <span data-yourir="$cur.fileTypeName"></span> */}
  </Box>
);
