import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Close from '@mui/icons-material/Close';
import Download from '@mui/icons-material/Download';
import East from '@mui/icons-material/East';
import Error from '@mui/icons-material/Error';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import North from '@mui/icons-material/North';
import Settings from '@mui/icons-material/Settings';
import South from '@mui/icons-material/South';
import West from '@mui/icons-material/West';
import Search from '@mui/icons-material/Search';

import { SvgIconProps, SvgIconTypeMap } from '@mui/material';

const appIcons = {
  search: Search,
  arrowRight: East,
  arrowLeft: West,
  arrowUp: North,
  arrowDown: South,
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  download: Download,
  expandMore: ExpandMore,
  expandLess: ExpandLess,
  settings: Settings,
  close: Close,
  checkbox: CheckBox,
  checkboxEmpty: CheckBoxOutlineBlank,
  error: Error,
} as const;

export type AppIcons = typeof appIcons;
export type AppIcon = AppIcons[AppIconName];
export type AppIconName = keyof AppIcons;
export type { AppIconName as IconName };
export type IconProps<
  D extends React.ElementType = SvgIconTypeMap['defaultComponent'],
  P = NoProps
> = SvgIconProps<
  D,
  {
    name: AppIconName;
  } & P
>;
const Icon = ({ name, ...props }: IconProps) => {
  const Component = appIcons[name];

  return <Component {...props} />;
};

export default Icon;
