import Container from '@component/Container';
import FeaturedGraph from '@component/FeaturedGraph';
import Graph from '@component/Graph';
import Grid from '@component/Grid';
import { animationVariants } from '@component/Shape/animations';
import Text from '@component/Text';
import { Block } from '@lib/api/fragments/blocks';
import { useForwardedRef } from '@lib/utils/useForwardedRef';
import { motion, MotionProps, useInView } from 'framer-motion';
import { forwardRef, useMemo } from 'react';
import { InfographicItemBlock } from './InfographicItem';
import { filterInfographicBlocks, Infographic } from './parseInfographic';

export type InfographicProps = Infographic;

//
type InfographicBlockProps = MotionProps & {
  blockIndex: number;
  block: Block;
};

const InfographicBlock = forwardRef<HTMLDivElement, InfographicBlockProps>(
  function InfographicBlock({ block, blockIndex }, forwardedRef) {
    const ref = useForwardedRef(forwardedRef);

    const inView = useInView(ref, {
      once: false,
      // once: true,
      // amount:
      margin: '-100px',
    });

    const animate = inView ? 'visible' : 'hidden';

    if (block._blockMeta) block._blockMeta = { ...block._blockMeta, inView };

    let inner: JSX.Element = <></>;

    switch (block.__typename) {
      case 'infographicData_item_BlockType':
        return (
          <InfographicItemBlock
            itemRef={ref}
            {...block}
            MotionProps={{ animate, custom: blockIndex }}
          />
        );

      case 'infographicData_featuredGraph_BlockType':
        inner = <FeaturedGraph {...block} />;
        break;

      case 'infographicData_graph_BlockType':
        inner = <Graph.Block {...block} />;
        break;
    }

    return (
      <motion.div
        ref={ref}
        // className={gridColStyle}
        variants={animationVariants}
        initial={['fade', 'hidden']}
        animate={animate ? 'visible' : false}>
        {inner}
      </motion.div>
    );
  }
);

const Infographic = ({ pageTitle, infographicData = [] }: InfographicProps) => {
  const { blocks } = useMemo(() => filterInfographicBlocks(infographicData), [infographicData]);

  return (
    <Container marginY="2xl">
      <Text variant="h2" color="primary" mb={8} html>
        {pageTitle}
      </Text>

      <Grid alignItems="end" rowGap="lg">
        {blocks.map((v, i) => (
          <InfographicBlock key={i} blockIndex={i} block={v.block} />
        ))}
      </Grid>
    </Container>
  );
};

export default Infographic;
