/* eslint-disable @typescript-eslint/ban-types */
import Grid from '@component/Grid';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { ForwardedRef } from 'react';
import { sprinkles, unpackSprinkles } from 'theme';
import * as styles from './Box.css';
import { BoxProps } from './BoxProps';

/**
 * A customised copy of the generic MuiBox component, with some extra features
 */
const Box = <C extends TagName>(
  {
    component = 'div' as C,
    className: providedClassName,
    visuallyHidden,
    children,
    grid,
    paper,
    invertPaper,
    elevation,
    rounded,
    container,
    stack,
    invertColors,
    colorway,
    ...props
  }: BoxProps<C>,
  ref: ForwardedRef<HTMLElement>
) => {
  const {
    /** inline values for sprinkles */
    sprinklesProps,

    /** remaining component props */
    rest,
  } = unpackSprinkles(props);

  const className = classNames([
    // container && styles.container,
    // grid && styles.grid,
    styles.root({
      colorway,
      invertPaper,
      paper,
      invertColors,
      rounded,
      elevation,
      container,
      grid,
      stack,
      visuallyHidden,
    }),
    providedClassName,
    sprinkles(sprinklesProps),
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Component = (motion as any)[component];

  const aria: React.AriaAttributes = {
    'aria-hidden': visuallyHidden || props.hidden,
  };

  if (grid) children = <Grid>{children}</Grid>;

  return (
    <Component
      ref={ref}
      {...aria}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(rest as any)}
      className={className}>
      {children}
    </Component>
  );
};

const BoxWithRef = React.forwardRef(Box);

export default BoxWithRef;
