import * as Types from '../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type RichContentBlockFragment = { __typename: 'blocks_richContent_BlockType', heading?: string | null, content?: string | null };

export const RichContentBlockFragmentDoc = gql`
    fragment richContentBlock on blocks_richContent_BlockType {
  __typename
  heading
  content: htmlContentRich
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;