import BlockHeader from '@component/BlockHeader';
import Carousel from '@component/Carousel';
import Container from '@component/Container';
import Image from '@component/Image';
import { createBlock } from '@lib/api/fragments/blocks';
import { gql } from 'graphql-request';

gql`
  fragment imageCarouselBlock on blocks_imageCarousel_BlockType {
    __typename
    heading
    children {
      ...imageWithCaptionBlock
    }
  }
`;

/**
 * Carousel of {@link ImageWithCaption} with optional heading
 *
 */
const ImageCarouselBlock = createBlock<'blocks_imageCarousel_BlockType'>(
  ({ heading, children }) => (
    <Container marginY="2xl">
      <BlockHeader {...{ heading }} />
      <Carousel options={{ loop: true }} borderRadius="md">
        {children?.map((v, i) => (
          <Carousel.Slide key={i}>
            <Image ratio="landscape" alt="" {...v.image} />
          </Carousel.Slide>
        ))}
      </Carousel>
    </Container>
  )
);

export default ImageCarouselBlock;
