import Button, { ButtonProps } from '@component/Button';
import Icon from '@component/Icon';
import Text from '@component/Text';
// import type {DownloadCtaVariants} from './DownloadCta.css'
import { FileAsset } from '@lib/api/fragments/linkField';
import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import { Stack } from '@mui/material';
import { colorVar } from '@theme/index';

export type DownloadButtonProps = ButtonProps & {
  title?: string;
  summary?: string;
  file?: Partial<FileAsset>;
};

const DownloadButton = ({
  file = {},
  title: customTitle,
  summary: customSummary,
}: DownloadButtonProps) => {
  const { url: href, extension, sizeHuman } = file;

  const summary = customSummary ?? file.summary;
  const title = customTitle ?? file.title;

  const ext = extension ? extension.toUpperCase() : null;
  const size = sizeHuman ?? null;
  const meta = makeNonNullableArray([ext, size]);

  const metaText = `(${meta.join(', ')})`.toUpperCase();
  const ariaLabel = `${title} ${metaText}`;

  const showFileMeta = false;

  return !href ? (
    <></>
  ) : (
    <Button
      download={'download'}
      component="a"
      aria-label={ariaLabel}
      href={href}
      target="_blank"
      variant="outlined"
      color="inherit"
      fullWidth
      sx={{
        maxWidth: 744,
        paddingY: 2,
        paddingX: 3,
        justifyContent: 'space-between',
        backgroundColor: colorVar('greyLighter'),
        borderColor: colorVar('greyLight'),
        color: colorVar('primary'),
        columnGap: 4,
      }}>
      <Stack component="span" spacing={1} /* className={styles.inner} */>
        <Text color="primary" variant="h6" marginBottom={0} component="span" bold>
          {title}
        </Text>
        {!!summary?.length && (
          <Text variant="small" weight="normal">
            {summary}
          </Text>
        )}
        {showFileMeta && (
          <Stack direction="row" spacing={4}>
            <Text variant="medium" component="span">
              {ext}
            </Text>
            <Text variant="medium" component="span">
              {size}
            </Text>
          </Stack>
        )}
      </Stack>
      <Icon
        name="download"
        sx={{
          color: colorVar('white'),
          backgroundColor: colorVar('pink'),
          padding: '0.5em',
          borderRadius: '50%',
          height: '2em',
          width: '2em',
          fontSize: 16,
        }}
      />
    </Button>
  );
};

export default DownloadButton;
