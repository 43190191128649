import { useEntryIndex } from '@lib/store/createEntryIndexStore';
import { useForwardedRef } from '@lib/utils/useForwardedRef';
import { ScrollTopProps, useScrollToTop } from '@lib/utils/useScrollToElement';
import React, { useEffect } from 'react';

export type EntryScrollTopProps = ScrollTopProps & {
  id?: string;
};

export const EntryIndexScrollTop = React.forwardRef<HTMLAnchorElement, EntryScrollTopProps>(
  function EntryIndexLoader(props, forwardedRef) {
    const { id = 'articles', defaultTop, margin, scrollDown = false } = props;

    const ref = useForwardedRef(forwardedRef);

    const isLoading = useEntryIndex(({ isLoading }) => isLoading);

    const toTop = useScrollToTop(ref, {
      defaultTop,
      margin,
      scrollDown,
    });

    useEffect(() => {
      toTop();
    }, [isLoading, toTop]);

    return <a ref={ref} id={id} />;
  }
);
