import { SeoTag } from '@lib/api/parseSeo';
import { useView } from '@lib/store';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import Head from 'next/head';

export type LayoutSeoProps = {
  tags?: SeoTag[];
};

const LayoutSeo = ({ tags: customTags = [] }: LayoutSeoProps) => {
  const view = useView();

  const viewTags = maybeGet(view, 'seomatic') ?? [];

  const tags = [...viewTags, ...customTags];

  return (
    <Head>
      {tags.map(({ type, key: maybeKey, props }, i) => {
        const key = maybeKey ?? props.property ?? props.name ?? i;

        switch (type) {
          case 'link':
            return <link key={key} {...props} />;
          case 'meta':
            return <meta key={key} {...props} />;
          case 'title':
            return props.title ? <title key="title">{props.title}</title> : <></>;
        }
      })}
    </Head>
  );
};

export default LayoutSeo;
