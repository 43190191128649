import Blocks from '@component/Blocks';
import EntryIndexPagination from '@component/EntryIndex/EntryIndexPagination';
import PageHeader from '@component/PageHeader';
import Text from '@component/Text';
import { EntryIndexKey } from '@lib/store';
import {
  createEntryIndexStore,
  EntryIndexContextProvider,
  useEntryIndex,
} from '@lib/store/createEntryIndexStore';
import { Collapse } from '@mui/material';
import SearchForm from './SearchForm';

import { SearchIndexGrid } from './SearchIndexGrid';
import { EntryIndexScrollTop } from '@component/EntryIndex/EntryIndexScrollTop';
import React from 'react';

const SearchIndexHeader = () => {
  const { entryCount, query, isLoading } = useEntryIndex(
    ({ entryCount, args: { query }, isLoading }) => ({
      entryCount,
      query,
      isLoading,
    })
  );

  const hasQuery = !!query?.length;

  return (
    <PageHeader
      {...{
        StyledImageProps: {
          variant: 'search',
        },
        BackgroundShapeProps: {
          color: 'yellowLighter',
        },
        backgroundShapeFlip: true,
      }}>
      <Collapse in={hasQuery || isLoading} unmountOnExit>
        <Text marginBottom={2} color="primaryDark" variant="large" component="div">
          {' '}
          {isLoading ? 'Searching...' : `${entryCount} results found for "${query}"`}
        </Text>
      </Collapse>
      <SearchForm />
    </PageHeader>
  );
};

const SearchIndex = () => {
  const scrollTopRef = React.useRef<HTMLAnchorElement>(null);

  return (
    <>
      <EntryIndexContextProvider
        createStore={() => createEntryIndexStore('search' as EntryIndexKey)}>
        <SearchIndexHeader />
        <EntryIndexScrollTop ref={scrollTopRef} />

        <SearchIndexGrid marginY={'2xl'} />
        <EntryIndexPagination />
      </EntryIndexContextProvider>

      <Blocks />
    </>
  );
};

SearchIndex.Grid = SearchIndexGrid;

export default SearchIndex;
