import * as Types from '../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type YourirDataBlockFragment = { __typename: 'blocks_yourirData_BlockType', heading?: string | null, yourirWidgetType?: string | null, code?: string | null, content?: string | null };

export type YourirAnnouncementsBlockFragment = { __typename: 'blocks_yourirAnnouncements_BlockType' };

export const YourirDataBlockFragmentDoc = gql`
    fragment yourirDataBlock on blocks_yourirData_BlockType {
  __typename
  heading
  content: htmlContentDefault
  yourirWidgetType
  code
}
    `;
export const YourirAnnouncementsBlockFragmentDoc = gql`
    fragment yourirAnnouncementsBlock on blocks_yourirAnnouncements_BlockType {
  __typename
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;