/* eslint-disable @typescript-eslint/no-explicit-any */

import createStore, { StoreApi } from 'zustand';
import createContext from 'zustand/context';

import { Category } from '@lib/api/fragments/categories';
import {
  EntryIndexQueryProps,
  EntryIndexQueryResult,
  getEntryIndexData,
  parseEntryIndexArgs,
} from '@lib/api/getEntryIndexData';
import {
  EntryIndexCategoryType,
  EntryIndexEntryType,
  EntryIndexKey,
  getEntryIndexDefaults,
} from './entryIndexes';

type Results<T extends EntryIndexKey> = EntryIndexQueryResult<
  EntryIndexEntryType<T>,
  EntryIndexCategoryType<T>
>;

export type EntryIndexStoreState<T extends EntryIndexKey> = Results<T> & {
  isFiltered?: boolean;
  isLoading?: boolean;
  trigger: (
    props: Partial<EntryIndexQueryProps<EntryIndexEntryType<T>, EntryIndexCategoryType<T>>>
  ) => void;
};

export const createEntryIndexStore = <T extends EntryIndexKey>(
  entryIndex: T,
  initialState?: Maybe<Partial<EntryIndexStoreState<T>>>
) => {
  const defaultArgs = getEntryIndexDefaults(entryIndex) as EntryIndexQueryProps<
    EntryIndexEntryType<T>,
    EntryIndexCategoryType<T>
  >;

  const initArgs = parseEntryIndexArgs({ ...defaultArgs, ...initialState?.args });

  // console.log(initialState);

  return createStore<EntryIndexStoreState<T>>((set, get) => ({
    entries: [],
    featuredEntry: null,
    categories: [],
    entryCount: 0,
    pages: 1,
    page: 1,
    isFiltered: false,
    isLoading: false,
    ...initialState,
    args: initArgs,
    trigger: async (newArgs) => {
      set({ isLoading: true });

      const { args: currentArgs, categories: currentCategories } = get();

      // ------------------------------------------------------------------------------------------
      // ---- Handle sort ----

      const isNewSort = 'orderBy' in newArgs && newArgs.orderBy !== currentArgs.orderBy;

      // Reset pagination if the sort has changed;
      if (isNewSort) newArgs.page = 1;

      // ------------------------------------------------------------------------------------------
      // ---- Handle search query ----

      const currentQuery = currentArgs.query;
      const isNewQuery = 'query' in newArgs && newArgs.query !== currentQuery;

      // Reset pagination if the query has changed;
      if (isNewQuery) newArgs.page = 1;

      // ------------------------------------------------------------------------------------------

      const args = { ...currentArgs, ...newArgs };

      getEntryIndexData(args).then((res) => {
        const categories = newArgs.includeCategories ? res.categories : currentCategories;

        const { entries, pages, page, args } = res;

        const isDefaultSort = args.orderBy !== defaultArgs.orderBy;
        const hasCategorySelections = !!args.categoryIds?.length;
        const isFiltered = !isDefaultSort || hasCategorySelections;

        // console.log(entries.map((v) => ({ score: v.searchScore })));

        set({
          ...res,
          args,
          categories,
          entries,
          pages,
          page,
          isLoading: false,
          isFiltered,
        });
      });
    },
  }));
};

export const compareCategoryArrays = <T extends Category>(oldCats: T[], newCats: T[]) =>
  oldCats.length === newCats.length &&
  oldCats.at(0)?.id === newCats.at(0)?.id &&
  oldCats.at(-1)?.id === newCats.at(-1)?.id;

// ------------------------------------------------------------------------------------------------
// ---- Create the Context ----

export const { Provider: EntryIndexContextProvider, useStore: useEntryIndex } =
  createContext<StoreApi<EntryIndexStoreState<EntryIndexKey>>>();

export const useArticles = createEntryIndexStore('articleIndex');
// export const useProperties = createEntryIndexStore('propertyIndex');
// export const useServices = createEntryIndexStore('serviceIndex');
