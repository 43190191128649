import React, { ErrorInfo } from 'react';

export type ErrorBoundaryProps = React.PropsWithChildren<{
  onCatchError?: (error: Error, errorInfo: ErrorInfo) => void;
  errorChildren?: React.ReactNode;
}>;

export type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    const { onCatchError } = this.props;
    onCatchError ? onCatchError(error, errorInfo) : console.warn(error, errorInfo);
  }

  render() {
    const { children, errorChildren } = this.props;

    if (this.state.hasError) {
      return errorChildren ?? <h1>Something went wrong.</h1>;
    }

    return children;
  }
}

export default ErrorBoundary;
