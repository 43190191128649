import * as Types from '../../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type DocumentFragment = { __typename: 'documentsVolume_Asset', url?: string | null, id?: string | null, filename: string, extension: string, size?: string | null, title?: string | null, summary?: string | null };

export type AssetFragment = { __typename: 'assetsVolume_Asset', url?: string | null, id?: string | null, filename: string, extension: string, size?: string | null, title?: string | null };

export type LinkFieldFragment = { __typename: 'linkField_Link', target?: string | null, text?: string | null, title?: string | null, type?: string | null, url?: string | null };

export const DocumentFragmentDoc = gql`
    fragment document on documentsVolume_Asset {
  __typename
  url
  id
  filename
  extension
  size
  title
  summary
}
    `;
export const AssetFragmentDoc = gql`
    fragment asset on assetsVolume_Asset {
  __typename
  url
  id
  filename
  extension
  size
  title
}
    `;
export const LinkFieldFragmentDoc = gql`
    fragment linkField on linkField_Link {
  __typename
  target
  text
  title
  type
  url
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;