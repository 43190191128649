import classNames from 'classnames';
import { createToggleStore } from '@lib/utils/createToggleStore';

export const useContrastMode = createToggleStore(false, 'appContrastMode');
export const useLargeTextMode = createToggleStore(false, 'appLargeTextMode');
export const useReadingMode = createToggleStore(false, 'appReadingMode');

export const useThemeClassName = (names?: string | string[]) => {
  // const isContrastMode = useContrastMode((s) => s.value);
  // const isLargeTextMode = useLargeTextMode((s) => s.value);

  // return classNames(names, [isContrastMode && themeHiContrast, isLargeTextMode && themeLargeFonts]);
  return classNames(names);
};
