import Box, { BoxProps } from '@component/Box';
import Image, { ImageProps } from '@component/Image';
import {
  Circle,
  CirclesOutline,
  ComplexAustralia,
  ComplexCircle,
  ComplexDiamond,
  ComplexPlant,
  Diamond,
  Semicircle,
  SemicircleOutline,
  Semicircles,
  Squiggle,
} from '@component/Shape';
import { Helix } from '@component/Shape/Helix.svg';
import Shim from '@component/Shim';
import { ImageEntry, StyledImageStyle } from '@lib/api/fragments/image';
import { useForwardedRef } from '@lib/utils/useForwardedRef';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { sprinkles, Sprinkles } from '@theme/_sprinkles.css';
import { Color } from '@theme/_vars.css';
import classNames from 'classnames';
import { motion, useInView } from 'framer-motion';
import { forwardRef, useEffect, useState } from 'react';
import { CircularImage } from './CircularImage';
import { ImageAnimationVariant } from './parseImageAnimationVariant';
import * as styles from './StyledImage.css';
import { StyledImageShapes_Home } from './StyledImageShapes_Home';

export type StyledImageProps<T extends TagName = 'div'> = BoxProps<
  T,
  {
    variant?: ImageAnimationVariant;
    imageStyle?: StyledImageStyle;
    image?: Maybe<ImageEntry>;
    ImageProps?: ImageProps;
    inViewOptions?: { margin?: string; amount?: number | 'all' | 'some' };
    onAnimationComplete?: () => void;
  }
>;

const StyledImage = forwardRef<HTMLElement, StyledImageProps>(function StyledImage(
  {
    onAnimationComplete,
    variant = 'alt1',
    imageStyle = 'default',
    image,
    inViewOptions,
    ImageProps,
    ...props
  },
  forwardedRef
) {
  const ref = useForwardedRef(forwardedRef);

  const animate = imageStyle === 'default';
  const shapesEnabled = imageStyle !== 'disableStyle';

  const inView = useInView(ref, {
    amount: 0.5,
    once: true,
    ...inViewOptions,
  });

  // Set immediately to 'true' to disable animation
  const [show, setShow] = useState(!animate);
  const [showShapes, setShowShapes] = useState(!animate);

  const handleImageIn = () => {
    setShowShapes(true);
    onAnimationComplete && onAnimationComplete();
  };

  useEffect(() => {
    setShow(!animate || inView);
  }, [inView, variant, animate]);

  let inner: React.ReactNode = null;
  let shapes: React.ReactNode = <></>;
  let imageWrapClassName = styles.shapesLayout1.imageWrap;
  let imageBgColor: Color = 'transparent';
  let imageEnabled = true;

  let isCircle = true;
  const ratio: Sprinkles['ratio'] = ImageProps?.ratio ?? 'square';

  const disableAnimation = !animate;

  switch (variant) {
    case 'alt1':
    case 'genericOrangeCircle':
      // ------------------------------------------------------------------------------------------
      // ---- Alt 1

      imageBgColor = 'orange';
      imageWrapClassName = styles.shapesLayout1.imageWrap;
      shapes = (
        <>
          <Semicircles
            className={styles.shapesLayout1.shapeSemicircles}
            semiCircleTopColor="pink"
            semiCircleBottomColor="yellow"
            disableAnimation={disableAnimation}
            semiCircleTopProps={{
              initial: ['down50', 'fade'],
              transitionProps: { order: 0 },
            }}
            semiCircleBottomProps={{
              initial: ['down50', 'fade'],
              transitionProps: { order: 1 },
            }}
          />
          <SemicircleOutline
            color="orange"
            className={styles.shapesLayout1.shapeSemicircleOutline}
            disableAnimation={disableAnimation}
          />
          <CirclesOutline
            color="green"
            className={styles.shapesLayout1.shapeCirclesOutline}
            disableAnimation={disableAnimation}
          />
        </>
      );
      break;

    case 'alt2':
    case 'genericYellowCircle':
      // ------------------------------------------------------------------------------------------
      // ---- Alt 2
      // Uses same layout to 'alt1', with different colours

      imageBgColor = 'yellow';
      imageWrapClassName = styles.shapesLayout1.imageWrap;
      shapes = (
        <>
          <Semicircles
            disableAnimation={disableAnimation}
            className={styles.shapesLayout1.shapeSemicircles}
            semiCircleTopColor="pink"
            semiCircleBottomColor="orange"
            semiCircleTopProps={{
              initial: ['down50', 'fade'],
              transitionProps: { order: 0 },
            }}
            semiCircleBottomProps={{
              initial: ['down50', 'fade'],
              transitionProps: { order: 1 },
            }}
          />
          <SemicircleOutline
            disableAnimation={disableAnimation}
            color="yellow"
            className={styles.shapesLayout1.shapeSemicircleOutline}
          />
          <CirclesOutline
            disableAnimation={disableAnimation}
            color="green"
            className={styles.shapesLayout1.shapeCirclesOutline}
          />
        </>
      );
      break;

    case 'alt3': // News
    case 'news':
      // ------------------------------------------------------------------------------------------
      // ---- Alt 3
      // Used in `articleIndex` header

      imageBgColor = 'yellow';
      shapes = (
        <>
          <SemicircleOutline
            disableAnimation={disableAnimation}
            color="green"
            className={styles.shapesLayout2.shapeSemicircle}
          />
          <Diamond
            disableAnimation={disableAnimation}
            initial={['down25', 'fade']}
            animate
            transitionProps={{ duration: 'lg' }}
            color="orange"
            className={styles.shapesLayout2.shapeDiamond}
          />
          <Squiggle
            disableAnimation={disableAnimation}
            color="pink"
            className={styles.shapesLayout2.shapeSquiggle}
          />
        </>
      );
      break;
    case 'alt4':
    case 'borderlessYellow':
    case 'borderlessPink':
    case 'genericTransparent':
      // ------------------------------------------------------------------------------------------
      // ---- Alt 4
      isCircle = false;
      imageWrapClassName = styles.shapesLayout3.imageWrap;
      shapes = (
        <>
          <Semicircles
            disableAnimation={disableAnimation}
            className={styles.shapesLayout3.shapeSemicircles}
            semiCircleTopColor="pinkMid"
            semiCircleBottomColor="orange"
          />
          <Squiggle
            disableAnimation={disableAnimation}
            color="yellow"
            className={styles.shapesLayout3.shapeSquiggle}
          />
        </>
      );
      break;

    case 'alt5':
    case 'home':
      // ------------------------------------------------------------------------------------------
      // ---- Alt 5
      // Used in `home` header

      imageBgColor = 'orange';
      shapes = <StyledImageShapes_Home />;
      break;

    case 'alt6':
    case 'genericYellowRectangle':
      // ------------------------------------------------------------------------------------------
      // ---- Alt 6
      // Large rectangular 'feature' text and image on home page
      isCircle = false;
      imageBgColor = 'yellow';
      imageWrapClassName = styles.shapesLayoutFeature.imageWrap;
      shapes = (
        <>
          <Semicircles
            disableAnimation={disableAnimation}
            className={styles.shapesLayoutFeature.shapeSemicircles}
            semiCircleTopColor="pinkMid"
            semiCircleBottomColor="orange"
          />
          <Helix
            disableAnimation={disableAnimation}
            delay={4}
            className={styles.shapesLayoutFeature.shapeHelix}
            color="green"
          />
        </>
      );
      break;

    case 'alt7':
    case 'search':
      // ------------------------------------------------------------------------------------------
      // ---- Alt 7
      // Used in the Search header
      imageWrapClassName = styles.shapesLayoutSearch.imageWrap;
      shapes = (
        <>
          <Circle
            disableAnimation={disableAnimation}
            initial={['up25', 'fade']}
            animate
            transitionProps={{ duration: 'lg' }}
            color="yellow"
            className={styles.shapesLayoutSearch.shapeCircle}
          />
          <Semicircles
            disableAnimation={disableAnimation}
            className={styles.shapesLayoutSearch.shapeSemicircles}
            semiCircleTopColor="pinkMid"
            semiCircleBottomColor="orange"
            semiCircleTopProps={{
              initial: ['down50', 'fade'],
              transitionProps: { order: 0 },
            }}
            semiCircleBottomProps={{
              initial: ['down50', 'fade'],
              transitionProps: { order: 1 },
            }}
          />
          <Squiggle
            disableAnimation={disableAnimation}
            color="pink"
            className={styles.shapesLayoutSearch.shapeSquiggle}
          />
        </>
      );
      break;

    case 'alt8':
    case 'genericPinkCircle':
      // ------------------------------------------------------------------------------------------
      // ---- Alt 2
      // Uses same layout to 'alt1', with different colours

      imageBgColor = 'pink';
      imageWrapClassName = styles.shapesLayoutGraph.imageWrap;
      shapes = (
        <>
          <Semicircle
            initial={['fade', 'down100']}
            transitionProps={{ duration: 1.5 }}
            color="yellow"
            disableAnimation={disableAnimation}
            className={styles.shapesLayoutGraph.shapeSemicircle}
          />
          <CirclesOutline
            disableAnimation={disableAnimation}
            color="green"
            delay={2}
            className={styles.shapesLayoutGraph.shapeCirclesOutline}
          />
        </>
      );
      break;

    // ------------------------------------------------------------------------------------------
    // ---- SVGs only ----

    case 'wealthDiamond':
      imageEnabled = false;
      shapes = null;
      inner = <ComplexDiamond disableAnimation={disableAnimation} />;
      break;
    case 'lifeCircle':
      imageEnabled = false;
      shapes = null;
      inner = <ComplexCircle disableAnimation={disableAnimation} />;
      break;
    case 'plant':
      imageEnabled = false;
      shapes = null;
      inner = <ComplexPlant disableAnimation={disableAnimation} />;
      break;
    case 'australiaMap':
      imageEnabled = false;
      shapes = null;
      inner = <ComplexAustralia disableAnimation={disableAnimation} />;
      break;
  }

  // Override shapes if not enabled
  shapes = shapesEnabled ? shapes : null;

  const imageInner = imageEnabled ? (
    <Image
      ratio="square"
      ShimProps={{
        className: classNames(styles.imageWrap, shapesEnabled && imageWrapClassName),
      }}
      alt=""
      {...mergePropsClassName(ImageProps, styles.image)}
      {...image}
    />
  ) : null;

  inner = inner ? (
    inner
  ) : !shapesEnabled ? (
    imageInner
  ) : isCircle ? (
    <CircularImage
      {...{
        disableAnimation: !animate,
        imageBgColor,
        onAnimationComplete: handleImageIn,
        image,
        ImageProps,
        // ...props,
      }}>
      {showShapes && shapes}
    </CircularImage>
  ) : (
    <>
      <motion.div
        {...(animate && {
          initial: {
            clipPath: 'circle(0%)',
          },
          animate: inView && {
            clipPath: 'circle(100%)',
          },
          transition: {
            duration: 1,
          },
        })}
        className={sprinkles({ bgcolor: imageBgColor })}>
        {imageInner}
      </motion.div>
      {shapes}
    </>
  );

  return (
    <Box ref={ref} {...mergePropsClassName(props, styles.root)}>
      {show ? inner : <Shim ratio={ratio} />}
    </Box>
  );
});

export default StyledImage;
