import { BoxProps } from '@mui/material';
import { sprinkles } from '@theme/_sprinkles.css';
import { Color } from '@theme/_vars.css';
import classNames from 'classnames';
import { motion, MotionProps } from 'framer-motion';
import { forwardRef } from 'react';
import {
  AnimationTransitionOptions,
  animationVariants,
  AppAnimation,
  getAnimationTransition,
} from './animations';
import * as styles from './Shape.css';

export type ShapeProps<P = NoProps> = PropsWith<
  'className',
  {
    disableAnimation?: boolean;
    delay?: number;
    strokeWidth?: number;
    initial?: AppAnimation | AppAnimation[];
    animate?: AppAnimation | AppAnimation[] | boolean;
    transitionProps?: AnimationTransitionOptions;
    motionProps?: MotionProps;
    progress?: number;
    shapeProps?: P;
  }
  // & AnimationTransitionOptions
>;

export type ComplexShapeProps<Shapes extends string> = BoxProps<
  'div',
  { [P in `${Shapes}Color`]?: Color } & {
    [P in `${Shapes}Props`]?: ShapeProps;
  } & {
    disableAnimation?: boolean;
  }
>;

export type CreateShapeProps<P = NoProps> = ShapeProps<P> & {
  color?: Color;
};

export const createShape = <P extends Props = NoProps>(
  Component: (props: ShapeProps<P>) => JSX.Element
) => {
  return forwardRef<HTMLDivElement, CreateShapeProps<P>>(function Shape(
    {
      disableAnimation,
      motionProps,
      initial,
      animate: maybeAnimate = true,
      delay,
      transitionProps = {},
      color,
      strokeWidth,
      progress,
      shapeProps,
      ...props
    },
    ref
  ) {
    const animate = maybeAnimate === true ? 'visible' : maybeAnimate || undefined;

    const transition = getAnimationTransition({ order: delay, ...transitionProps });

    return (
      <div ref={ref} className={classNames(props.className, sprinkles({ color }))}>
        <motion.div
          initial={disableAnimation ? animate : initial}
          animate={animate}
          transition={transition}
          {...motionProps}
          variants={animationVariants}>
          <Component
            {...{ strokeWidth, disableAnimation, progress, delay, shapeProps }}
            className={styles.shapeComponent}
          />
        </motion.div>
      </div>
    );
  });
};
