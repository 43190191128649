import {
  deleteUndefined,
  firstNonNullable,
  humanFileSize,
  makeNonNullableArray,
  maybeGet,
  toStringOrNull,
} from '@liquorice/allsorts-craftcms-nextjs';
import { gql } from 'graphql-request';
import {
  AssetFragment,
  DocumentFragment,
  LinkFieldFragment,
} from './__generated__/linkField.generated';

// fragment imageSize_thumb on AssetInterface {
//   size_thumb_url: url @transform(transform: "thumb")
//   size_thumb_height: height @transform(transform: "thumb")
//   size_thumb_width: width @transform(transform: "thumb")
// }

gql`
  fragment document on documentsVolume_Asset {
    __typename
    url
    id
    filename
    extension
    size
    title
    summary
  }

  fragment asset on assetsVolume_Asset {
    __typename
    url
    id
    filename
    extension
    size
    title
  }

  fragment linkField on linkField_Link {
    __typename
    target
    text
    title
    type
    url
  }
`;

export type FileAsset = NonNullable<ReturnType<typeof parseDocument>>;

export interface Link extends Pick<IntrinsicProps<'a'>, 'href' | 'target' | 'title'> {
  // text?: string;
  children?: string;
  download?: boolean;
  // file?: FileAsset;
}

export const parseDocument = (
  maybeElement?: MaybeArrayOf<Partial<DocumentFragment | AssetFragment>>
) => {
  const doc = firstNonNullable(maybeElement);
  if (!doc) return null;
  const { size, ...rest } = doc;

  const summary = toStringOrNull(maybeGet(doc, 'summary')) ?? undefined;
  const title = toStringOrNull(doc.title) ?? undefined;

  return deleteUndefined({ ...rest, title, summary, size, sizeHuman: humanFileSize(size) });
};

export const parseDocuments = (maybeElements?: MaybeArrayOf<DocumentFragment>) => {
  return makeNonNullableArray(maybeElements).reduce((results, item) => {
    const asset = parseDocument(item);
    if (asset) results.push(asset);
    return results;
  }, [] as FileAsset[]);
};

// export const parseLinkFieldElementAsset = (maybeElement: Maybe<LinkFieldFragment['element']>) => {
//   if (!maybeElement || maybeElement.__typename !== 'documentsVolume_Asset') return null;
//   return parseAsset(maybeElement);
// };

export const parseLinkField = (data: MaybeArrayOf<LinkFieldFragment>): Link | null => {
  const link = firstNonNullable(data);

  if (!link || !link.url) return null;

  const { target, type, text, title, url: href } = link;

  const download = type === 'asset';

  const field: Link = {
    href: toStringOrNull(href) ?? undefined,
    target: download ? '_blank' : toStringOrNull(target) ?? undefined,
    children: toStringOrNull(text) ?? undefined,
    title: toStringOrNull(title) ?? undefined,
    download,
  };

  return deleteUndefined(field);
};
