import { firstNonNullable } from '@liquorice/allsorts-craftcms-nextjs';
import { gql } from 'graphql-request';
import client from './client';
import { AppGlobal, parseGlobals } from './fragments/globals';
import {
  getSdk,
  Globals_AnnouncementBanner_GlobalSet_Fragment,
} from './__generated__/getGlobals.generated';

// ---------------------------------------------------------------------------------------------- //
// ---- GQL parts

gql`
  fragment globalAnnouncement on announcementBanner_GlobalSet {
    __typename
    announcement {
      ... on announcement_item_BlockType {
        __typename
        dateUpdated
        active
        color {
          label
          class
        }
        message
        linkCta {
          ...linkField
        }
        hideCta
      }
    }
  }
  fragment globalDefaults on defaults_GlobalSet {
    __typename
    image {
      ...image
    }
    userAuthor {
      ...author
    }
  }

  fragment globalContent on globalContent_GlobalSet {
    __typename
    globalCopyrightEntity
    globalCopyrightInfo
    globalDisclaimer
  }

  fragment globalContactForm on contactForm_GlobalSet {
    __typename
    formTitle
    content: htmlContentSimple
    formieForm: form {
      ...formieForm
    }
  }

  fragment globalAcknowledgementOfCountry on acknowledgementOfCountry_GlobalSet {
    __typename
    content: htmlContentSimple
    images {
      ...image
    }
  }
  fragment globalYourIr on yourIr_GlobalSet {
    __typename
    apiKey
  }

  fragment globals on GlobalSetInterface {
    ...globalAnnouncement
    ...globalContent
    ...globalDefaults
    ...globalContactForm
    ...globalAcknowledgementOfCountry
    ...globalYourIr
  }

  query globals {
    globalSets {
      ...globals
    }
  }
`;

// ---------------------------------------------------------------------------------------------- //
// ---- Set up the Types

export type AppGlobals = ReturnOrPromiseType<typeof getGlobals>;

// ---------------------------------------------------------------------------------------------- //
// ---- Parsing Functions

/**
 * Parsed the queried fragment
 */

export const getGlobals = async () => {
  const sdk = getSdk(client);

  const data = await sdk.globals();

  const globals = parseGlobals(data.globalSets);

  return {
    announcementBanner: globals.find(
      ({ __typename }) => __typename === 'announcementBanner_GlobalSet'
    ) as AppGlobal<'announcementBanner_GlobalSet'> | undefined,
    globalContent: globals.find(({ __typename }) => __typename === 'globalContent_GlobalSet') as
      | AppGlobal<'globalContent_GlobalSet'>
      | undefined,
    defaults: globals.find(({ __typename }) => __typename === 'defaults_GlobalSet') as
      | AppGlobal<'defaults_GlobalSet'>
      | undefined,
    contactForm: globals.find(({ __typename }) => __typename === 'contactForm_GlobalSet') as
      | AppGlobal<'contactForm_GlobalSet'>
      | undefined,
    acknowledgementOfCountry: globals.find(
      ({ __typename }) => __typename === 'acknowledgementOfCountry_GlobalSet'
    ) as AppGlobal<'acknowledgementOfCountry_GlobalSet'> | undefined,
    globalYourIr: globals.find(({ __typename }) => __typename === 'yourIr_GlobalSet') as
      | AppGlobal<'yourIr_GlobalSet'>
      | undefined,
  };
};

// ---------------------------------------------------------------------------------------------- //
// ---- The Getter ----
