import Link, { LinkProps } from '@component/Link';
import Text, { TextProps } from '@component/Text';
import { Box, BoxProps } from '@mui/material';

export type BlockHeaderProps = BoxProps<
  'div',
  {
    heading?: Maybe<string>;
    HeadingProps?: TextProps<React.ElementType>;
    content?: Maybe<string>;
    ContentProps?: TextProps<React.ElementType>;
    LinkProps?: LinkProps;
  }
>;

const BlockHeader = ({
  heading,
  HeadingProps,
  content,
  ContentProps,
  children,
  LinkProps,
  ...props
}: BlockHeaderProps) => {
  return (
    <Box marginBottom={4} {...props}>
      <Text
        variant="h4"
        bold={!HeadingProps?.html}
        component="h2"
        color="primary"
        {...HeadingProps}>
        {heading}
      </Text>
      <Text variant="large" html {...ContentProps}>
        {content}
      </Text>
      {LinkProps && <Link {...LinkProps} />}
      {children}
    </Box>
  );
};

export default BlockHeader;
