import * as Types from '../../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Category_ArticleCategory_Category_Fragment = { __typename: 'articleCategory_Category', id?: string | null, uri?: string | null, title?: string | null, groupHandle: string };

export type Category_CaseStudyCategory_Category_Fragment = { __typename: 'caseStudyCategory_Category', id?: string | null, uri?: string | null, title?: string | null, groupHandle: string };

export type Category_InsightCategory_Category_Fragment = { __typename: 'insightCategory_Category', id?: string | null, uri?: string | null, title?: string | null, groupHandle: string };

export type CategoryFragment = Category_ArticleCategory_Category_Fragment | Category_CaseStudyCategory_Category_Fragment | Category_InsightCategory_Category_Fragment;

export const CategoryFragmentDoc = gql`
    fragment category on CategoryInterface {
  __typename
  id
  uri
  title
  groupHandle
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;