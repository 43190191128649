import { ArticleIndexGrid } from '@component/ArticleIndex/ArticleIndexGrid';
import BlockHeader from '@component/BlockHeader';
import Box from '@component/Box';
import Button, { ButtonProps } from '@component/Button';
import Container from '@component/Container';
import Grid from '@component/Grid';
import { createBlock } from '@lib/api/fragments/blocks';
import { useAppLink, useView } from '@lib/store';
import { gql } from 'graphql-request';
import { useTranslations } from 'next-intl';

gql`
  fragment latestNewsBlockExtras on Query {
    latestArticles: articleEntries(limit: 3) {
      ...articleCard
    }
  }

  fragment latestNewsBlock on blocks_latestArticlesWidget_BlockType {
    __typename
    heading: htmlHeading
    content: htmlContentSimple
  }
`;

const LatestNewsBlock = createBlock<'blocks_latestArticlesWidget_BlockType'>(
  ({ heading, content }) => {
    const indexLink = useAppLink('articleIndex');
    const t = useTranslations('article');

    const latestArticles = useView().viewExtras?.latestArticles ?? [];

    const viewAllLink: undefined | ButtonProps = indexLink && {
      href: indexLink.uri,
      children: t('indexLink'),
    };

    return (
      <Container marginY="2xl">
        <Grid>
          <Grid.Col md={10} lg={8}>
            <BlockHeader
              {...{
                marginBottom: 8,
                heading,
                HeadingProps: { variant: 'h1', bold: false, html: true },
                content,
                ContentProps: { html: true },
              }}>
              <Box marginTop="lg">{viewAllLink && <Button {...viewAllLink} />}</Box>
            </BlockHeader>
          </Grid.Col>
        </Grid>
        <ArticleIndexGrid items={latestArticles} />
      </Container>
    );
  }
);

export default LatestNewsBlock;
