// import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { MuiComponentOverrides } from '.';

const MuiSelectOverrides: MuiComponentOverrides<'MuiSelect', 'MuiSelect'> = {
  defaultProps: {
    // deleteIcon: <Icon name="cancel" />,
    // IconComponent: KeyboardArrowDown,
  },
  styleOverrides: {
    // root: {
    // color: colors.greenDark,
    // backgroundColor: colors.greenLight_50,
    // fontSize: 16,
    // fontFamily: fonts.sansSerifRegular,
    // },
  },
};

export default MuiSelectOverrides;
