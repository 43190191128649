/* eslint-disable @typescript-eslint/no-explicit-any */
import { floatPoint, fmtMoney, fmtPercent } from '@lib/utils/format';
import { cleanHtml } from '@lib/utils/htmlHelpers';
import {
  firstNonNullable,
  makeNonNullableArray,
  toNumberOrNull,
  toString,
  toTypedElementArray,
} from '@liquorice/allsorts-craftcms-nextjs';
import { ColorValue } from '@theme/_vars.css';
import { gql } from 'graphql-request';
import { Labels_TableRow } from '_generated/types';
import { parseColorValue } from './parse/common';
import {
  DatasetMultipleFragment,
  DatasetSingleFragment,
  GraphEntryFragment,
} from './__generated__/parseGraphEntry.generated';

gql`
  fragment datasetSingle on graphData_dataset_BlockType {
    __typename
    label
    color {
      label
      # class
    }
    dataValue
  }

  fragment datasetMultiple on graphData_datasetMultiple_BlockType {
    __typename
    label
    color {
      label
      # class
    }
    dataValues {
      # col1
      values
    }
  }

  fragment graphEntry on graph_default_Entry {
    ...entryBase
    heading
    summary
    content: htmlContentSimple
    valueFormat
    datasetType
    labels {
      # col1
      label
    }
    graphData {
      ...datasetSingle
      ...datasetMultiple
    }
  }
`;

type GraphValueFormat = 'none' | 'money' | 'percentage';

export const parseGraphValueFormat = (data: any): GraphValueFormat => {
  if (['none', 'money', 'percentage'].includes(data)) return data;
  return 'none';
};

export type DatasetType = 'single' | 'multiple';

export const parseDatasetType = (data: any): DatasetType => {
  if (['single', 'multiple'].includes(data)) return data;
  return 'single';
};

export type Dataset<T extends DatasetType> = {
  color: ColorValue | null;
  label: string;
} & (T extends 'single'
  ? {
      value: number | null;
    }
  : {
      values: (number | null)[];
    });

export const parseGraphData = (data: DatasetSingleFragment[]): Dataset<'single'>[] => {
  return data.map((v) => ({
    color: parseColorValue(v.color?.label),
    value: toNumberOrNull(v.dataValue),
    label: toString(v.label),
  }));
};

export const parseGraphDataMultipleTotals = (data: Dataset<'multiple'>[]): number[] => {
  const totals: number[] = [];

  data.forEach(({ values }) => {
    // The values of this metric
    values.forEach((value, i) => {
      totals[i] = floatPoint((totals[i] ?? 0) + (value ?? 0));
    });
  });

  return totals;
};

export const parseGraphDataMultiple = (data: DatasetMultipleFragment[]): Dataset<'multiple'>[] => {
  return data.map((v) => {
    // The values of this metric
    const values = (v.dataValues ?? []).map((j) => toNumberOrNull(j?.values));

    return {
      color: parseColorValue(v.color?.label),
      values,
      label: toString(v.label),
    };
  });
};

export const parseGraphLabels = (maybeData: MaybeArrayOf<Labels_TableRow>) => {
  const data = makeNonNullableArray(maybeData);
  return data.map((v) => toString(v.label));
};

// type Data<T extends DatasetType> = {
//   datasets: Dataset<T>[],
// }

export interface Graph<T extends DatasetType> {
  valueFormat: GraphValueFormat;
  datasetType: T;
  labels: string[];
  data: Dataset<T>[];
}

export type GraphEntry<T extends DatasetType = DatasetType> = Graph<T> & {
  heading: string;
  summary: string;
  content: string;
  show?: boolean;
};

export const parseGraphEntry = (
  maybeGraph: MaybeArrayOf<GraphEntryFragment | any>
): GraphEntry | null => {
  const rawGraph = firstNonNullable(maybeGraph);

  if (!rawGraph) return null;

  const {
    heading,
    summary,
    content,
    valueFormat,
    datasetType: maybeDatasetType,
    labels: maybeLabels,
    graphData: maybeGraphData,
  } = rawGraph;

  const datasetType = parseDatasetType(maybeDatasetType);

  const data =
    datasetType === 'multiple'
      ? parseGraphDataMultiple(
          toTypedElementArray(maybeGraphData, 'graphData_datasetMultiple_BlockType')
        )
      : parseGraphData(toTypedElementArray(maybeGraphData, 'graphData_dataset_BlockType'));

  return {
    heading: toString(heading),
    summary: toString(summary),
    content: cleanHtml(content),
    valueFormat: parseGraphValueFormat(valueFormat),
    datasetType: parseDatasetType(datasetType),
    labels: parseGraphLabels(maybeLabels),
    data,
  } as GraphEntry;
};

export const formatGraphValue = (value?: number | null, format?: GraphValueFormat) => {
  return format === 'percentage' ? fmtPercent(value) : format === 'money' ? fmtMoney(value) : value;
};
