import { createField } from '@formie/parse/formieParser';
import { useFormField } from '@formie/useForm';
import { addToDistinctArray, removeFromDistinctArray } from '@liquorice/allsorts-craftcms-nextjs';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import { gql } from 'graphql-request';
import { FieldErrors } from './FieldErrors';

gql`
  fragment fieldCheckboxes on Field_Checkboxes {
    ...fieldBase
    multi
    layout
    options {
      ...fieldOption
    }
  }
`;

export const Field_Checkboxes = createField<'Field_Checkboxes'>(({ field, ..._props }) => {
  const {
    name,
    handle,
    layout,
    required,
    options,
    multi: _multi,
    instructions,
    defaultValue: _defaultValue,
    labelPosition,
  } = field;

  const { value, setValue, error, errors } = useFormField(handle, 'multi');

  const handleChange =
    (optionValue: string) => (_ev: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      setValue(
        checked
          ? addToDistinctArray(value, optionValue)
          : removeFromDistinctArray(value, optionValue)
      );
    };

  return (
    <FormControl required={required} error={error}>
      <FormLabel component="legend" sx={{ mb: 1 }} hidden={labelPosition === 'hidden'}>
        {name}
      </FormLabel>
      <FormHelperText>{instructions}</FormHelperText>
      <FormGroup row={layout === 'horizontal'} sx={{ mb: 1 }}>
        {options.map((v) => (
          <FormControlLabel
            key={v.value}
            control={
              <Checkbox
                // icon={<Icon name="checkboxEmpty" />}
                // checkedIcon={<Icon name="checkbox" />}
                checked={value.includes(v.value)}
                onChange={handleChange(v.value)}
                name={v.value}
              />
            }
            label={v.label}
          />
        ))}
      </FormGroup>
      <FieldErrors errors={errors} />
    </FormControl>
  );
});
