import { AnimationProps, Target, Transition } from 'framer-motion';

type CreateAnimationProps = {
  visible: Target;
  hidden: Target;
};

type TransitionKey = keyof Transition;

type TransitionDefaults = {
  [P in keyof Transition]?: Record<string, UnionToIntersection<Transition>[P]>;
};

const makeTransitionDefaults = <T extends TransitionDefaults>(defaults: T) => defaults;

const transitionDefaults = {
  duration: {
    fast: 0.15,
    faster: 0.2,
    normal: 0.3,
    slower: 0.4,
    slow: 0.5,
  },
  ease: {
    enter: 'easeOut',
    exit: 'easeIn',
  },
};

const createAppearanceAnimation =
  <T extends CreateAnimationProps>({ visible, hidden }: T) =>
  (isVisible?: boolean) => {
    const animation: AnimationProps = {
      initial: 'hidden',
      animate: isVisible ? 'visible' : 'hidden',
      exit: 'hidden',

      variants: {
        visible: {
          display: '',
          transition: {
            ease: transitionDefaults.ease.enter,
            duration: transitionDefaults.duration.normal,
          },
          transitionEnd: {
            pointerEvents: 'auto',
          },
          ...visible,
        },
        hidden: {
          pointerEvents: 'none',
          transition: {
            ease: transitionDefaults.ease.exit,
            duration: transitionDefaults.duration.faster,
          },
          transitionEnd: {
            display: 'none',
          },
          ...hidden,
        },
      },
    };

    return animation;
  };

export const fade = createAppearanceAnimation({
  visible: {
    opacity: 1,
    // transition: {
    //   duration: transitionDefaults.duration.normal
    // }
  },
  hidden: {
    opacity: 0,
    // transition: {
    //   duration: transitionDefaults.duration.faster
    // }
  },
});

type Anchor = 'top' | 'bottom' | 'left' | 'right';

export const slide = (anchor: Anchor) => {
  const values: Record<Anchor, Target> = {
    top: { y: '-100%' },
    bottom: { y: '100%' },
    left: { x: '-100%' },
    right: { x: '100%' },
  };

  return createAppearanceAnimation({
    hidden: values[anchor],
    visible: { x: 0, y: 0 },
  });
};

export const slideDown = slide('top');
export const slideUp = slide('bottom');
export const slideRight = slide('left');
export const slideLeft = slide('right');

export const collapse = createAppearanceAnimation({
  hidden: {
    height: 0,
  },
  visible: { x: 0, y: 0 },
});
