import BackgroundRibbon, {
  BackgroundRibbonProps,
  backgroundRibbonWrap,
} from '@component/BackgroundRibbon';
import Box from '@component/Box';
import Breadcrumbs from '@component/Breadcrumbs';
import Container from '@component/Container';
import Grid from '@component/Grid';
import Image, { ImageProps } from '@component/Image';
import { useViewBannerImage } from '@lib/store';
import classNames from 'classnames';
import * as styles from './PageHeaderEntry.css';
import { PageTitle } from './PageTitle';

export type PageHeaderEntryProps = {
  hideBreadcrumbs?: boolean;
  BackgroundRibbonProps?: BackgroundRibbonProps;
  ImageProps?: ImageProps;
};

export const PageHeaderEntry = ({
  hideBreadcrumbs,
  BackgroundRibbonProps,
  ImageProps,
}: PageHeaderEntryProps) => {
  const bannerImage = useViewBannerImage();
  const hasImage = !!bannerImage;

  return (
    <>
      <Box className={styles.root} component="header">
        <Box
          className={classNames(hasImage && backgroundRibbonWrap({ size: 'small' }))}
          marginBottom="xl">
          {hasImage && (
            <BackgroundRibbon
              disableAnimation
              color={'yellow'}
              fullHeight
              {...BackgroundRibbonProps}
            />
          )}

          <Container>
            {!hideBreadcrumbs && <Breadcrumbs marginY="lg" />}
            {hasImage && (
              <Box rounded bgcolor="yellow" marginBottom={{ lg: 'xl' }}>
                <Image
                  ratio="landscapeWide"
                  alt=""
                  priority
                  {...{
                    ...bannerImage,
                    transform: { xs: 'landscapeLgCrop', lg: 'bannerXlCrop' },
                    ...ImageProps,
                  }}
                />
              </Box>
            )}
          </Container>
        </Box>
        <Container>
          <Grid>
            <Grid.Col md={6}>
              <PageTitle bold color="primary" />
            </Grid.Col>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
