import Box from '@component/Box';
import Icon from '@component/Icon';
import Text from '@component/Text';
import { useScrollToTop } from '@lib/utils/useScrollToElement';
import { Collapse } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import * as styles from './Accordion.css';

export type AccordionItemType = {
  label: string;
  content: React.ReactNode;
  children?: React.ReactNode;
};

export type AccordionItemProps = AccordionItemType & {
  first?: boolean;
  open?: boolean;
  onChange?: (event: React.SyntheticEvent, open: boolean) => void;
};

export const AccordionItem = ({
  label,
  content,
  children,
  first,
  open: controlledOpen = false,
  onChange,
}: AccordionItemProps) => {
  const ref = useRef<HTMLElement>(null);
  const scrollTo = useScrollToTop(ref, {});
  const [open, setOpen] = useState(controlledOpen);

  useEffect(() => {
    if (controlledOpen !== open) setOpen(controlledOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlledOpen]);

  const handleClick = (event: React.SyntheticEvent) => {
    const newOpen = !open;
    setOpen(newOpen);
    onChange && onChange(event, newOpen);
  };

  return (
    <Box
      ref={ref}
      className={classNames([styles.item, first && styles.itemFirst, open && styles.itemOpen])}>
      <Box className={styles.control} role="button" onClick={handleClick}>
        <Text variant="h5" component="h3" bold className={styles.controlLabel} marginBottom={0}>
          {label}
        </Text>
        <Icon name="expandMore" className={styles.chevron} />
      </Box>

      <Collapse
        timeout={{
          appear: 500,
          enter: 300,
          exit: 0,
        }}
        onExited={() => scrollTo({ behavior: 'auto' })}
        onEntering={() => open && scrollTo({ behavior: 'auto' })}
        onEntered={() => open && scrollTo({ scrollDown: true })}
        className={styles.panel}
        in={open}
        aria-expanded={open ? 'true' : 'false'}>
        <Box className={styles.panelInner}>
          {content}
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};
