import Box, { BoxProps } from '@component/Box';
import { mergePropsClassName, unpackProps } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import { gridColStyle, GridColVariants, gridStyle, GridVariants } from 'theme';

export type GridProps<T extends TagName = 'div'> = BoxProps<
  T,
  GridVariants & { rowGutter?: boolean }
>;

const Grid = <T extends TagName>(props: GridProps<T>) => {
  const { rest, unpacked } = unpackProps(props, [
    'noGutter',
    'rowGutter',
    'columns',
    'columnsXs',
    'columnsSm',
    'columnsMd',
    'columnsLg',
    'columnsXl',
    'columnsMx',
  ]);

  return <Box {...{}} {...mergePropsClassName(rest, gridStyle(unpacked))} />;
};

export type GridColProps<T extends TagName = 'div'> = BoxProps<T, GridColVariants>;

const GridCol = React.forwardRef<HTMLElement, GridColProps<TagName>>(function GridCol(props, ref) {
  const { rest, unpacked } = unpackProps(props, [
    'span',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    'mx',
    'offset',
    'offsetXs',
    'offsetSm',
    'offsetMd',
    'offsetLg',
    'offsetXl',
    'offsetMx',
  ]);

  return <Box ref={ref} {...mergePropsClassName(rest, gridColStyle(unpacked))} />;
});

Grid.Col = GridCol;

export default Grid;
