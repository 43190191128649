// import { fonts } from 'theme/themeTypography';
import { MuiComponentOverrides } from '.';

const MuiFormHelperTextOverrides: MuiComponentOverrides<'MuiFormHelperText', 'MuiFormHelperText'> =
  {
    defaultProps: {},
    styleOverrides: {
      root: {
        fontSize: 14,
        // fontFamily: fonts.sansSerifRegular,
        textTransform: 'none',
      },
    },
  };

export default MuiFormHelperTextOverrides;
