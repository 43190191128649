import BlockHeader from '@component/BlockHeader';
import Box from '@component/Box';
import Container from '@component/Container';
import Grid from '@component/Grid';
import Formie from '@formie/Formie';
import { createBlock } from '@lib/api/fragments/blocks';
import { useGlobals } from '@lib/store';
import { gql } from 'graphql-request';
import React from 'react';
import type { ContactFormVariants } from './ContactForm.css';

gql`
  fragment contactFormBlock on blocks_contactFormWidget_BlockType {
    __typename
  }
`;

export type ContactFormProps = React.PropsWithChildren<
  ContactFormVariants & {
    /**/
  }
>;

const ContactForm = createBlock<'blocks_contactFormWidget_BlockType'>(() => {
  const { formieForm, formTitle: heading, content } = useGlobals('contactForm') ?? {};

  return (
    <Box bgcolor="secondaryLight" paddingY="2xl" paddingX={{ md: '2xl' }}>
      <Container>
        <Grid justifyContent="center">
          <Grid.Col lg={8} xl={6}>
            <Box bgcolor="paper" rounded padding="xl" textAlign="center">
              <BlockHeader
                {...{
                  heading,
                  HeadingProps: {
                    variant: 'h3',
                    textAlign: 'center',
                  },
                  content,
                }}
              />
              {formieForm && <Formie form={formieForm} />}
            </Box>
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
});

export default ContactForm;
