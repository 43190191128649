import IconButton from '@component/IconButton';
import { ToggleButtonProps } from '@component/ToggleButton';
import { useAppLink } from '@lib/store';
import { useTranslations } from 'next-intl';

export type SearchToggleButtonProps = ToggleButtonProps;

export const SearchToggleButton = (/* props: ToggleButtonProps */) => {
  const t = useTranslations('actions');
  const searchLink = useAppLink('search');
  return <IconButton href={searchLink?.uri ?? '/search'} icon="search" aria-label={t('search')} />;
};
