import BlockHeader from '@component/BlockHeader';
import Box from '@component/Box';
import Container from '@component/Container';
import Grid from '@component/Grid';
import Link from '@component/Link';
import { ComplexCircle, ComplexDiamond } from '@component/Shape';
import StyledImage, { StyledImageProps } from '@component/StyledImage';
import Text from '@component/Text';
import { createBlock } from '@lib/api/fragments/blocks';
import { ImageEntry, StyledImageStyle } from '@lib/api/fragments/image';
import { Link as LinkType } from '@lib/api/fragments/linkField';
import { Alignment } from '@lib/api/parse';
import { stripHtmlTags } from '@lib/utils/htmlHelpers';
import { Color } from '@theme/_vars.css';
import { gql } from 'graphql-request';
import { useTranslations } from 'next-intl';
import * as styles from './ImageTextCta.css';
gql`
  fragment imageTextCtaBlock on blocks_imageTextCta_BlockType {
    __typename
    # label
    heading: htmlHeading
    content: htmlContentDefault
    alignment
    image {
      ...image
    }
    imageStyle
    linkCta {
      ...linkField
    }
  }

  fragment imageTextCtaSimpleBlock on blocks_imageTextCtaSimple_BlockType {
    __typename
    heading
    content: htmlContentDefault
    alignment
    image {
      ...image
    }
    imageStyle
    linkCta {
      ...linkField
    }
  }

  fragment imageTextCtaFeatureBlock on blocks_imageTextCtaFeature_BlockType {
    __typename
    heading: htmlHeading
    content: htmlContentDefault
    image {
      ...image
    }
    imageStyle
    linkCta {
      ...linkField
    }
  }
`;

export type ImageTextCtaProps = {
  variant: 'simple' | 'normal';
  label?: Maybe<string>;
  heading?: Maybe<string>;
  content?: Maybe<string>;
  alignment?: Maybe<Alignment>;
  linkCta?: Maybe<LinkType>;
  nthOfType?: number;
  image?: Maybe<ImageEntry>;
  imageStyle?: StyledImageStyle;
};

const ImageTextCta = (props: ImageTextCtaProps) => {
  const t = useTranslations('cta');

  const {
    variant: _variant = 'simple',
    label,
    heading,
    content,
    alignment: maybeAlignment,
    linkCta,
    image,
    imageStyle,
    nthOfType = 0,
  } = props;

  const hasImage = !!image;

  const alignmentVariants: ('left' | 'right')[] = ['right', 'left'];

  const alignment =
    maybeAlignment && maybeAlignment !== 'auto'
      ? maybeAlignment
      : alignmentVariants[nthOfType % alignmentVariants.length];

  let leftOffset: 1 | 2 = 2;
  let rightOffset: 1 | 2 = 1;

  let shapeVariant = <ComplexCircle key="complexCircle" />;

  let featuredColor: Color = 'pink';

  const styledImageProps: Partial<StyledImageProps> = {
    variant: 'alt2',
  };

  switch (alignment) {
    case 'left':
      leftOffset = 1;
      rightOffset = 2;
      shapeVariant = <ComplexDiamond key="complexDiamond" />;
      featuredColor = 'orange';
      styledImageProps.variant = 'alt3';
  }

  const styledImage = hasImage ? (
    <StyledImage key={1} {...styledImageProps} imageStyle={imageStyle} image={image} />
  ) : imageStyle !== 'disableStyle' ? (
    shapeVariant
  ) : null;

  return (
    <Container key={'aa'} className={styles.root({ alignment })} marginY="3xl" paddingY="xl">
      <Grid alignItems="center" rowGap="lg">
        <Grid.Col sm={6} md={3} className={styles.imageCol} offsetMd={leftOffset}>
          {styledImage}
        </Grid.Col>
        <Grid.Col sm={6} md={5} className={styles.contentCol} offsetMd={rightOffset}>
          {label && (
            <Text color={featuredColor} bold uppercase marginBottom={2}>
              {label}
            </Text>
          )}
          <BlockHeader
            {...{
              marginBottom: 2,
              heading: stripHtmlTags(heading),
              HeadingProps: { variant: 'h4', bold: true },
              content,
              ContentProps: { variant: 'medium' },
            }}
          />
          {linkCta && (
            <Box stack="column">
              <Link
                variant="buttonLg"
                {...linkCta}
                linkDirection="forwards"
                // color="primary"
                underline="none"
                // underlineColor={featuredColor}
              >
                {linkCta?.children || t('discover')}
              </Link>
            </Box>
          )}
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export const ImageTextCtaBlock = createBlock<'blocks_imageTextCta_BlockType'>((props) => {
  const { heading, content, image, imageStyle, linkCta, alignment, _blockMeta } = props;

  const empty = !heading && !content;
  return empty ? (
    <></>
  ) : (
    <ImageTextCta
      {...{
        variant: 'normal',
        image,
        imageStyle,

        heading,
        content,
        linkCta,
        alignment,
        nthOfType: _blockMeta?.nthOfType,
      }}
    />
  );
});

export const ImageTextCtaSimpleBlock = createBlock<'blocks_imageTextCtaSimple_BlockType'>(
  (props) => {
    const { heading, content, image, imageStyle, linkCta, alignment, _blockMeta } = props;

    const empty = !heading && !content;

    return empty ? (
      <></>
    ) : (
      <ImageTextCta
        {...{
          variant: 'simple',
          image,
          imageStyle,
          heading,
          content,
          linkCta,
          alignment,
          nthOfType: _blockMeta?.nthOfType,
        }}
      />
    );
  }
);

export default ImageTextCta;
