import { LoginButton } from '@component/Login';
import NavMenu from '@component/NavMenu';
import SocialLinks from '@component/SocialLinks';
import { useRouterChangeStart } from '@lib/utils/useRouter';
import { Divider, Drawer, Stack, useTheme } from '@mui/material';
import { useNavbarState } from '../NavBar/useNavbarState';
import * as styles from './NavBarMenu.css';
import { NavBarMenuToggleButton } from './NavBarMenuToggleButton';

const NavBarMenu = () => {
  const zIndex = useTheme().zIndex.appBar - 10;

  const {
    setNavOpen,
    navOpen,
    height: navBarHeight,
  } = useNavbarState(({ setNavOpen, navOpen, height }) => ({
    setNavOpen,
    navOpen,
    height,
  }));

  const handleClose = () => {
    setNavOpen(false);
  };

  /**
   * Close the menu on navigation
   */
  useRouterChangeStart(handleClose);

  return (
    <Drawer
      {...{
        anchor: 'left',
        open: navOpen,
        onClose: handleClose,
        sx: { zIndex },
        PaperProps: {
          sx: { paddingTop: `${navBarHeight}px` },
        },
      }}>
      <Stack className={styles.inner} padding={0}>
        <NavMenu name="primaryNav" ListProps={{ disablePadding: true }} />
        <Divider />
        <Stack spacing={4} marginY={4}>
          <LoginButton sx={{ marginX: 2 }} />
          <Divider />
          <SocialLinks sx={{ paddingX: 2 }} />
        </Stack>
      </Stack>
    </Drawer>
  );
};

NavBarMenu.Button = NavBarMenuToggleButton;

export default NavBarMenu;
