import { motion } from 'framer-motion';
import { svgDraw } from './animations';
import { createShape, ShapeProps } from './Shape';

export const Squiggle = createShape(({ disableAnimation, className, delay = 0 }: ShapeProps) => {
  return (
    <motion.svg
      className={className}
      initial={disableAnimation ? 'visible' : 'hidden'}
      animate="visible"
      width="390"
      height="36"
      viewBox="0 0 390 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <motion.path
        variants={svgDraw}
        custom={delay}
        d="M388.497 8.50711L362.442 27.255C350.356 35.9311 336 35.9311 323.915 27.255L297.859 8.50711C285.773 -0.169035 271.417 -0.169035 259.332 8.50711L233.276 27.255C221.191 35.9311 206.834 35.9311 194.749 27.255L168.693 8.50711C156.608 -0.169035 142.252 -0.169035 130.166 8.50711L104.11 27.255C92.0247 35.9311 77.6687 35.9311 65.5829 27.255L39.5275 8.50711C27.4418 -0.169035 13.0857 -0.169035 1 8.50711"
        stroke="currentColor"
        strokeWidth="3"
        pathLength="1"
        strokeMiterlimit="10"
      />
    </motion.svg>
  );
});
