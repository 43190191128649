import IconButton from '@component/IconButton';
import { useEntryIndex } from '@lib/store/createEntryIndexStore';
import { FormControl, InputAdornment, InputLabel, OutlinedInput, Stack } from '@mui/material';

import { useTranslations } from 'next-intl';
import React, { useState } from 'react';

const SearchForm = () => {
  const t = useTranslations('search');
  const [term, setTerm] = useState('');
  const triggerSearch = useEntryIndex((s) => s.trigger);

  const handleSubmit = (ev: React.SyntheticEvent<HTMLFormElement>) => {
    ev.preventDefault();

    if (term) triggerSearch({ query: term });
  };

  return (
    <Stack width={'100%'} onSubmit={handleSubmit} component="form" noValidate>
      <FormControl
        {...{
          variant: 'outlined',
          fullWidth: true,
        }}>
        <InputLabel>{t('inputPlaceholder')}</InputLabel>
        <OutlinedInput
          label={t('inputPlaceholder')}
          type="search"
          value={term}
          onChange={(ev) => {
            setTerm(ev.target.value);
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton type="submit" variant="contained" icon="search" color="secondary" />
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );
};

export default SearchForm;
