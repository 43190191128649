import Link, { LinkProps } from '@component/Link';
import { NavMenuItem as NavMenuItemType } from '@lib/api';
import { useIsCurrentHref } from '@lib/store';
import { Menu, MenuItem, MenuItemProps, PaperProps, Stack } from '@mui/material';
import { colorVar, vars } from '@theme/index';
import NextLink from 'next/link';
import { useState } from 'react';

export type NavMenuItemProps = {
  depth?: number;
  item?: NavMenuItemType;
};

export const NavMenuItem = ({ item, depth = 0 }: NavMenuItemProps) => {
  const { type, href, title, subItems } = item ?? {};

  const isRoot = !depth;

  const isActive = useIsCurrentHref(href);

  const hasChildren = !!subItems?.length;

  const hasLink = !hasChildren && type !== 'passive' && !!href;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  let button = <></>;
  let inner = <></>;
  let after = <></>;

  const label = <>{title}</>;

  const menuItemStyle: MenuItemProps['sx'] = {
    ':hover': {
      backgroundColor: 'transparent',
    },
  };

  const linkProps: LinkProps<'span'> = {
    // component: 'span',
    color: 'primary',
    disableLink: true,
    underline: isActive ? 'always' : 'hover',
    underlineColor: 'yellow',
    variant: isRoot ? 'button' : 'medium',
  };

  const dropdownProps: PaperProps = {
    sx: {
      bgcolor: colorVar('greyLighter'),
      borderRadius: vars.borderRadius.md,
      padding: 1,
    },
  };

  if (hasChildren) {
    inner = (
      <>
        <MenuItem component="button" onClick={handleClick} sx={menuItemStyle}>
          <Link {...linkProps}>{label}</Link>
          {/* <Icon name={open ? 'expandLess' : 'expandMore'} /> */}
        </MenuItem>
      </>
    );
    after = (
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClick={handleClose}
        elevation={0}
        PaperProps={dropdownProps}
        sx={{}}>
        {subItems.map((v, i) => (
          <NavMenuItem key={i} item={v} depth={depth + 1} />
        ))}
      </Menu>
    );
  } else if (hasLink) {
    button = (
      <NextLink href={href} passHref>
        <MenuItem component="a" sx={menuItemStyle}>
          <Link {...linkProps}>{label}</Link>
        </MenuItem>
      </NextLink>
    );
    inner = button;
  }

  return (
    <Stack>
      {inner}
      {after}
    </Stack>
  );
};
