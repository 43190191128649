import Container from '@component/Container';
import Graph from '@component/Graph';
import Grid from '@component/Grid';
import * as infographicStyles from '@component/Infographic/Infographic.css';
import { InfographicContent } from '@component/Infographic/InfographicStat';
import { Semicircle } from '@component/Shape';
import StyledImage from '@component/StyledImage';
import { createBlock } from '@lib/api/fragments/blocks';
import { GraphEntry } from '@lib/api/parseGraphEntry';
import * as styles from './FeaturedGraph.css';

const FeaturedGraph = createBlock<'infographicData_featuredGraph_BlockType'>(
  ({ graphSingle, image, content, infographicColor, _blockMeta }) => {
    const { inView = true } = _blockMeta ?? {};
    return (
      <Container className={styles.root({})}>
        <Grid alignItems="center">
          <Grid.Col lg={6} className={infographicStyles.item({ color: infographicColor })}>
            <InfographicContent variant="h2">{content}</InfographicContent>
          </Grid.Col>
          <Grid.Col lg={4} className={infographicStyles.item({ color: infographicColor })}>
            {/* {inView && <InfographicArrow className={infographicStyles.featuredGraphArrow} />} */}
            {/* <Shim ratio="square"></Shim> */}
          </Grid.Col>

          <Grid.Col md={5} className={styles.imageCol}>
            <StyledImage variant="alt8" image={image} />
            <Semicircle initial={['fade']} className={styles.semicircle1} />
            <Semicircle initial={['fade']} className={styles.semicircle2} />
          </Grid.Col>
          <Grid.Col md={6} offsetMd={1} className={styles.graphCol}>
            <Graph show={inView} {...(graphSingle as GraphEntry)} />
          </Grid.Col>
        </Grid>
      </Container>
    );
  }
);

export default FeaturedGraph;
