import Text from '@component/Text';
import { FieldOption } from '@formie/parse/formieParser';
import { MenuItem, OutlinedTextFieldProps, SelectProps, TextField } from '@mui/material';

export type DefaultSelectFieldProps = Partial<OutlinedTextFieldProps> & {
  options?: FieldOption[];
};

export const DefaultSelectField = ({
  options = [],
  SelectProps: maybeSelectProps,
  ...props
}: DefaultSelectFieldProps) => {
  const selectProps: SelectProps = {
    ...maybeSelectProps,
  };

  const isNative = !!selectProps.native;

  return (
    <TextField value="" select SelectProps={selectProps} {...props}>
      {options?.map(({ value, label }) =>
        isNative ? (
          <option key={value} value={value}>
            {label}
          </option>
        ) : (
          <MenuItem key={value} value={value}>
            <Text variant="large">{label}</Text>
          </MenuItem>
        )
      )}
    </TextField>
  );
};
