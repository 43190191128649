/* eslint-disable @typescript-eslint/no-explicit-any */

import { Typename } from '@liquorice/allsorts-craftcms-nextjs';
import { GlobalsFragment } from '../__generated__/getGlobals.generated';

// import { ContactLinksGlobalFragment, SocialLinksGlobalFragment } from '../__generated__/getWidgets.generated';
import { sanitiseAnything, SanitisedElement } from './sanitiseElements';

export type RawGlobals = GlobalsFragment;

export type GlobalTypename = Typename<RawGlobals>;

// ----------------------------------------------------------------------------------------------------
// --- Extracted sanitised types ---

export type SanitisedGlobal<T extends GlobalTypename = GlobalTypename> = SanitisedElement<T>;
export type AppGlobal<T extends GlobalTypename = GlobalTypename> = SanitisedElement<T>;

// ----------------------------------------------------------------------------------------------------

/**
 * Create a {@link AppGlobal} consumer React Component by providing
 * the `__typename` of `GlobalType` as T
 */
export const createGlobal = <T extends GlobalTypename, P = NoProps, R = JSX.Element>(
  fn: (props: Partial<AppGlobal<T>> & P) => R
) => fn;

export const sanitiseGlobals = (maybeGlobals: MaybeArrayOf<GlobalsFragment>) => {
  return sanitiseAnything.many(maybeGlobals) as SanitisedGlobal[];
};

export const parseSanitisedGlobals = (sanitisedGlobals: SanitisedGlobal[]): AppGlobal[] => {
  return sanitisedGlobals;
};

export const parseGlobals = (maybeGlobals: MaybeArrayOf<GlobalsFragment>): AppGlobal[] => {
  return parseSanitisedGlobals(sanitiseGlobals(maybeGlobals));
};

// ----------------------------------------------------------------------------------------------------
// --- Type guards ---

export const isGlobalType = <T extends GlobalTypename>(x: any, typename: T): x is AppGlobal<T> => {
  return !!x && x.__typename === typename;
};
