export const ENTRIES_PER_PAGE = 9;

export const ENTRY_TYPES = {
  article: 'article',
  insight: 'insight',
  page: 'page',
  // profile: 'profile',
} as const;

export const PUBLIC_ENTRY_TYPES = {
  page: 'page',
  article: 'article',
  insight: 'insight',
} as const;

export const ENTRY_INDEX_TYPES = {
  article: 'articleIndex',
} as const;

export const ENTRY_SINGLES = {
  search: 'search',
} as const;

export const PUBLIC_CATEGORY_TYPES = {
  // serviceAgeGroup: 'serviceAgeGroup',
};

export const SEPARATOR = `•`;

export enum IMG_RATIO {
  SQUARE = 1,
  PORTRAIT = 1.2,
  LANDSCAPE = 496 / 744, // 0.715,
  LANDSCAPE_TALL = 0.8, // ~4:3
  LANDSCAPE_WIDE = 0.5625, // 16:9
  VIDEO = 0.5625, // 16:9
}

export type ImgRatios = typeof IMG_RATIO;
export type ImgRatio = keyof ImgRatios;
