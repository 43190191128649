import Box from '@component/Box';
import Link from '@component/Link';
import Text from '@component/Text';

import { useViewBlocks } from '@lib/store';
import { useTranslations } from 'next-intl';

export const BlocksAnchorMenu = () => {
  const t = useTranslations('common');

  const { anchors } = useViewBlocks();

  return anchors.length ? (
    <Box
      component="nav"
      display="flex"
      direction="column"
      marginBottom="2xl"
      spacing="md"
      rounded
      alignItems="start"
      paper
      padding="xl">
      <Text variant="h4" component="h2" marginBottom="md">
        {t(`onThisPage`)}
        {`:`}
      </Text>
      {anchors.map(([id, label], i) => (
        <Link key={i} href={`#${id}`} underline="always">
          {label}
        </Link>
      ))}
    </Box>
  ) : (
    <></>
  );
};
