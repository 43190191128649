import { trimSlashes } from '@liquorice/allsorts-craftcms-nextjs';
import { EntriesFragment } from 'gql/__generated__/entries.generated';
import { gql } from 'graphql-request';
import { Redirect } from 'next';
import { getBlockExtras } from './blockExtras';
import { getClient } from './client';

import { Entry, parseEntry } from './fragments/entries';
import { getRelatedEntries } from './getRelatedEntries';
// import { getRelatedEntries } from './getRelatedEntries';

import { ContentByUriQueryVariables, getSdk } from './__generated__/getViewByUri.generated';

gql`
  query contentByUri($uri: [String], $slug: [String], $section: [String]) {
    entry(uri: $uri, slug: $slug, section: $section) {
      ...entries
    }
    # category(uri: $uri) {
    #   __typename
    #   id
    #   title
    # }
  }
  query uriRedirect($uri: String) {
    retour(uri: $uri) {
      redirectDestUrl
      redirectHttpCode
    }
  }
`;

export const getViewByUri = async (
  variables?: ContentByUriQueryVariables,
  options?: { isPreview?: boolean; previewToken?: string }
) => {
  const args = {
    ...(options?.isPreview && options?.previewToken && { token: options.previewToken }),
  };

  const client = getClient(args);

  const sdk = getSdk(client);
  const { entry /* category */ } = await sdk.contentByUri(variables);

  const view: Entry | null = entry ? parseEntry(entry as EntriesFragment) : null;
  const viewExtras = view ? await getBlockExtras(view) : null;
  const relatedEntries = await getRelatedEntries(view);

  return view ? { ...view, relatedEntries, viewExtras } : null;
};

export type View = NonNullable<ReturnOrPromiseType<typeof getViewByUri>>;

/**
 * Query the CMS for a redirect
 */
export const getRedirect = async (uri: string): Promise<Redirect | null> => {
  const formattedUri = `/${trimSlashes(uri)}/`.toLowerCase();

  const client = getClient();

  const sdk = getSdk(client);
  const { retour } = await sdk.uriRedirect({ uri: formattedUri });

  const { redirectDestUrl: destination, redirectHttpCode } = retour ?? {};

  if (!destination) {
    // console.log(uri);

    return null;
  }

  type RedirectStatusCode = 301 | 302 | 303 | 307 | 308;

  const statusCode =
    redirectHttpCode && [301, 302, 303, 307, 308].includes(redirectHttpCode)
      ? (redirectHttpCode as RedirectStatusCode)
      : 302;

  const redirect: Redirect = {
    statusCode,
    destination,
  };
  // console.log(redirect);

  return redirect;
};
