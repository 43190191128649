import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import { unpackProps } from '@liquorice/allsorts-craftcms-nextjs';

export type SlotNames<T extends string> = Uncapitalize<T>;

export type SlotNodes<T extends string> = { [P in SlotNames<T>]?: React.ReactNode };
export type SlotClassNames<T extends string> = { [P in SlotNames<T>]?: string | string[] };
export type SlotProps<T extends string> = { [P in SlotNames<T>]?: Props };
export type SlotComponents<T extends string, U extends SlotProps<T>> = {
  [P in SlotNames<T>]?: (props: U[P]) => JSX.Element;
};

type SlotIncludes<T extends string> = {
  slots: SlotNodes<T>;
  classNames: SlotClassNames<T>;
  ComponentProps: SlotProps<T>;
  Components: SlotComponents<T, SlotProps<T>>;
};

type SlotIncludeKey = keyof SlotIncludes<string>;

export type Slots<T extends string, U extends SlotIncludeKey> = {
  [Part in U]?: SlotIncludes<T>[Part];
};

export const createLayout =
  <
    P,
    Names extends string,
    SlotParts extends SlotIncludeKey = 'slots',
    Props extends Slots<Names, SlotParts> & P = Slots<Names, SlotParts> & P
  >(
    slots: Names | Names[],
    Fn: (props: P, slots: Slots<Names, SlotParts>) => JSX.Element,
    parts?: SlotParts | SlotParts[]
  ) =>
  (props: Props) => {
    type UnpackCallback = (props: Props) => [props: P, slots: Slots<Names, SlotParts>];

    const unpackSlots: UnpackCallback = (allProps: Props) => {
      const { rest, unpacked } = unpackProps(
        allProps,
        makeNonNullableArray(parts ?? ('slots' as SlotParts))
      );

      return [rest as unknown as P, unpacked as Slots<Names, SlotParts>];
    };

    return Fn(...unpackSlots(props));
  };

// ------------------------------------------------------------------------------------------------
// ---- Example ----

const Layout = createLayout(['header', 'sidebar', 'footer'], (props) => <></>, [
  'classNames',
  'slots',
  'Components',
  'ComponentProps',
]);

const T = () => {
  return (
    <Layout
      {...{
        slots: {
          header: <></>,
        },
        classNames: {
          header: 'someClassName',
        },
      }}
    />
  );
};
