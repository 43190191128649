import { motion } from 'framer-motion';
import { svgDraw } from './animations';
import { createShape, ShapeProps } from './Shape';

export const CirclesOutline = createShape(
  ({ disableAnimation, className, delay = 0 }: ShapeProps) => {
    const height = 130;
    const width = 196;
    const stroke = 3;
    const outerRadius = height / 2;
    const radius = outerRadius - stroke / 2;
    const cy = outerRadius;
    const leftCx = outerRadius;
    const rightCx = width - outerRadius;

    const circleProps = {
      stroke: 'currentColor',
      fill: 'none',

      strokeWidth: stroke,
      cy: cy,
      r: radius,
    };

    return (
      <motion.svg
        className={className}
        initial={disableAnimation ? 'visible' : 'hidden'}
        animate="visible"
        width="196"
        height="130"
        viewBox="0 0 196 130">
        <motion.circle
          strokeLinecap="square"
          {...circleProps}
          variants={svgDraw}
          custom={0 + delay}
          cx={leftCx}
        />
        <motion.circle
          strokeLinecap="square"
          {...circleProps}
          variants={svgDraw}
          custom={0.5 + delay}
          cx={rightCx}
        />
      </motion.svg>
    );
  }
);
