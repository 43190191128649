import Image, { ImageProps } from '@component/Image';
import { circleReveal } from '@component/Shape/animations';
import Shim from '@component/Shim';
import { ImageEntry } from '@lib/api/fragments/image';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { Box, BoxProps } from '@mui/material';
import { sprinkles } from '@theme/_sprinkles.css';
import { Color } from '@theme/_vars.css';
import { motion } from 'framer-motion';
import React from 'react';
import * as styles from './StyledImage.css';

export type CircularImageProps = BoxProps<
  'div',
  {
    image?: Maybe<ImageEntry>;
    ImageProps?: ImageProps;
    imageBgColor?: Color;
    disableAnimation?: boolean;
    onAnimationComplete?: () => void;
  }
>;

export const CircularImage = React.forwardRef<HTMLDivElement, CircularImageProps>(
  function CircularImage(
    {
      image,
      ImageProps,
      disableAnimation,
      imageBgColor = 'primary',
      onAnimationComplete,
      children,
      ...props
    },
    ref
  ) {
    // useEffect()
    // if(disableAnimation) onAnimationComplete && onAnimationComplete()

    return (
      <Box ref={ref} {...mergePropsClassName(props, styles.root)}>
        <Box
          component={motion.div}
          {...{
            initial: disableAnimation ? 'visible' : 'hidden',
            animate: 'visible',
            variants: circleReveal,
            custom: 0,
            className: sprinkles({ bgcolor: imageBgColor }),
            sx: {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              zIndex: 9,
            },
          }}>
          <Shim ratio="square" />
        </Box>
        <Box
          component={motion.div}
          {...{
            initial: disableAnimation ? 'visible' : 'hidden',
            animate: 'visible',
            variants: circleReveal,
            custom: 1,
            onAnimationComplete,
            sx: {
              position: 'relative',
              zIndex: 10,
            },
          }}>
          <Image sizes="50w" ratio="square" alt="" {...image} {...ImageProps} />
        </Box>
        {children}
      </Box>
    );
  }
);
