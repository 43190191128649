import BlockHeader from '@component/BlockHeader';
import { BoxProps } from '@component/Box';
import Container from '@component/Container';
import Text from '@component/Text';
import { useState } from 'react';
import { AccordionBlock } from './AccordionBlock';
import { AccordionItem, AccordionItemType } from './AccordionItem';

export type AccordionProps = BoxProps<
  'div',
  {
    heading?: string;
    content?: string;
    items: AccordionItemType[];
  }
>;
/**
 * Accordion with introduction heading and body copy
 */
const Accordion = ({ heading, content, items = [], ...props }: AccordionProps) => {
  const [expanded, setExpanded] = useState<number | null>(null);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <Container {...props}>
      <BlockHeader {...{ heading, content }} />

      {items.map(({ label, content }, i) => (
        <AccordionItem
          onChange={handleChange(i)}
          open={expanded === i}
          first={i === 0}
          key={i}
          label={label}
          content={<Text html>{content}</Text>}
        />
      ))}
    </Container>
  );
};

Accordion.Item = AccordionItem;
Accordion.Block = AccordionBlock;

export default Accordion;
