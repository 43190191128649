import { motion } from 'framer-motion';
import { svgDraw } from './animations';
import { createShape, ShapeProps } from './Shape';

export const Sun = createShape(({ disableAnimation, className, delay = 0 }: ShapeProps) => {
  const stepSize = 0;
  const pause = 1;

  return (
    <motion.svg
      className={className}
      initial={disableAnimation ? 'visible' : 'hidden'}
      animate="visible"
      //
      width="183"
      height="183"
      viewBox="0 0 183 183"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <motion.path
        variants={svgDraw}
        custom={delay + 0}
        d="M62.0813 120.954C69.8882 128.748 80.4692 133.126 91.5003 133.126C102.531 133.126 113.111 128.748 120.918 120.954C128.722 113.146 133.106 102.558 133.106 91.5178C133.106 80.4778 128.722 69.8895 120.918 62.0811C117.057 58.2135 112.472 55.1457 107.425 53.0522C102.377 50.9587 96.9652 49.8818 91.5003 49.8818C86.0355 49.8818 80.6241 50.9587 75.5762 53.0522C70.5282 55.1457 65.9422 58.2135 62.0813 62.0811C54.2768 69.8895 49.8926 80.4778 49.8926 91.5178C49.8926 102.558 54.2768 113.146 62.0813 120.954V120.954Z"
        stroke="currentColor"
        strokeWidth="3"
      />
      <motion.path
        variants={svgDraw}
        custom={delay + (pause + 1 * stepSize)}
        d="M91.5 26.5343V0"
        stroke="currentColor"
        strokeWidth="3"
      />
      <motion.path
        variants={svgDraw}
        custom={delay + (pause + 2 * stepSize)}
        d="M156.214 26.7869L137.431 45.57"
        stroke="currentColor"
        strokeWidth="3"
      />
      <motion.path
        variants={svgDraw}
        custom={delay + (pause + 3 * stepSize)}
        d="M156.466 91.5H183"
        stroke="currentColor"
        strokeWidth="3"
      />
      <motion.path
        variants={svgDraw}
        custom={delay + (pause + 4 * stepSize)}
        d="M137.431 137.43L156.214 156.213"
        stroke="currentColor"
        strokeWidth="3"
      />
      <motion.path
        variants={svgDraw}
        custom={delay + (pause + 5 * stepSize)}
        d="M91.5 183V156.466"
        stroke="currentColor"
        strokeWidth="3"
      />
      <motion.path
        variants={svgDraw}
        custom={delay + (pause + 6 * stepSize)}
        d="M45.5693 137.43L26.7861 156.213"
        stroke="currentColor"
        strokeWidth="3"
      />
      <motion.path
        variants={svgDraw}
        custom={delay + (pause + 7 * stepSize)}
        d="M0 91.5H26.5343"
        stroke="currentColor"
        strokeWidth="3"
      />
      <motion.path
        variants={svgDraw}
        custom={delay + (pause + 8 * stepSize)}
        d="M26.7861 26.7869L45.5693 45.57"
        stroke="currentColor"
        strokeWidth="3"
      />
    </motion.svg>
  );
});
