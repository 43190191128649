import Container from '@component/Container';
import Grid from '@component/Grid';
import Image from '@component/Image';
import Text from '@component/Text';
import { createBlock } from '@lib/api/fragments/blocks';
import { Box } from '@mui/material';

export const ProfileBlock = createBlock<'blocks_profile_BlockType'>(({ profileSingle }) => {
  if (!profileSingle) return <></>;

  const { image, title, label: role, subheading: credentials, content } = profileSingle;

  const titleStr = (
    <>
      {title}{' '}
      {role && (
        <>
          {` `}&mdash;{` ${role}`}
        </>
      )}
    </>
  );

  return (
    <Container>
      <Grid rowGap="lg">
        <Grid.Col span={8} md={6} lg={6}>
          <Box maxWidth={360}>
            <Image
              ratio="landscape"
              transform={{ xs: 'landscapeSmFit', md: 'landscapeLgFit' }}
              rounded
              contain
              alt={title ?? ''}
              ShimProps={{ bgcolor: 'green' }}
              {...image}
            />
          </Box>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text variant="h5" component="h3" color="primary" weight="medium">
            {titleStr}
          </Text>
          <Text variant="h5" component="h4" color="primary" weight="medium">
            {credentials}
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text html>{content}</Text>
        </Grid.Col>
      </Grid>
    </Container>
  );
});
