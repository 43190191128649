import { CirclesOutline, Diamond, Squiggle, Sun } from '@component/Shape';
import classNames from 'classnames';
import * as styles from './StyledImage.css';

export const StyledImageShapes_Home = () => (
  <>
    <CirclesOutline
      delay={1}
      animate
      color="green"
      className={styles.shapesLayoutHome.shapeCirclesOutline}
    />
    <Sun delay={3} animate color="yellow" className={styles.shapesLayoutHome.shapeSun} />
    <Diamond
      initial={['up25', 'fade']}
      animate
      transitionProps={{ duration: 'lg' }}
      color="pink"
      className={styles.shapesLayoutHome.shapeDiamond}
    />
    <Squiggle
      animate
      color="pink"
      className={classNames([
        styles.shapesLayoutHome.shapeSquiggle,
        styles.shapesLayoutHome.shapeSquiggle1,
      ])}
    />
    <Squiggle
      delay={1}
      animate
      color="pink"
      className={classNames([
        styles.shapesLayoutHome.shapeSquiggle,
        styles.shapesLayoutHome.shapeSquiggle2,
      ])}
    />
  </>
);
