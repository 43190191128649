import { Entry, isEntryType, parseEntries } from './fragments/entries';

import { maybeGet, toNumber } from '@liquorice/allsorts-craftcms-nextjs';
import { EntriesFragment } from 'gql/__generated__/entries.generated';
import { gql } from 'graphql-request';
import { getClient } from './client';
import { getSdk, RelatedEntriesQueryVariables } from './__generated__/getRelatedEntries.generated';
// import { getSdk } from './__generated__/getRelatedEntries.generated';

gql`
  query relatedEntries(
    $exclude: QueryArgument
    $section: [String]
    $categoryId: [QueryArgument]
    $parentId: Int
  ) {
    entries(
      limit: 3
      id: ["not", $exclude]
      relatedTo: $categoryId
      section: $section
      descendantOf: $parentId
    ) {
      ...entryCard
    }
    # nextSibling {

    # }
  }
`;

export const getRelatedEntries = async (entry?: Maybe<Entry>): Promise<Entry[] | null> => {
  if (!entry) return null;

  const disableRelatedEntries = !!maybeGet(entry, 'disableRelatedEntries');
  if (disableRelatedEntries) return null;

  const manualEntries = maybeGet(entry, 'relatedEntries');
  if (manualEntries?.length) return manualEntries as Entry[];

  const client = getClient();

  const variables: RelatedEntriesQueryVariables = {
    exclude: entry.id,
  };

  if (isEntryType(entry, 'article')) {
    variables.categoryId = entry['articleCategory']?.map((v) => v.id);
    variables.section = 'article';
  } else if (isEntryType(entry, 'caseStudy')) {
    variables.categoryId = entry['caseStudyCategory']?.map((v) => v.id);
    variables.section = 'caseStudy';
  } else if (isEntryType(entry, 'insight')) {
    variables.categoryId = entry['insightCategory']?.map((v) => v.id);
    variables.section = 'insight';
  } else {
    const parentId = toNumber(maybeGet(entry, 'parent')?.id) || null;

    // Avoid unrelated top-level pages
    if (!parentId) return null;

    variables.section = 'page';
    variables.parentId = parentId;
  }

  const sdk = getSdk(client);
  const { entries } = await sdk.relatedEntries(variables);

  const relatedEntries: Entry[] = parseEntries(entries as EntriesFragment[]);

  return relatedEntries || null;
};
