import Accordion from '@component/Accordion';
import { ArticleCard } from '@component/ArticleIndex/ArticleCard';
import ContactForm from '@component/ContactForm';
import { ColouredBoxCtaBlock, LargeCtaBlock, SimpleCtaBlock } from '@component/CtaBlock';
import Downloads, { DownloadCtaBlock } from '@component/Downloads';
import FeaturedGraph from '@component/FeaturedGraph';
import FeatureList from '@component/FeatureList';
import FeatureListItem from '@component/FeatureList/FeatureListItem';
import FormBlock from '@component/FormBlock';
import Graph from '@component/Graph';
import ImageCarousel from '@component/ImageCarousel';
import {
  ImageTextCtaBlock,
  ImageTextCtaFeatureBlock,
  ImageTextCtaSimpleBlock,
} from '@component/ImageTextCta';
import ImageWithCaption from '@component/ImageWithCaption';
import { InfographicBlock } from '@component/Infographic';
import LatestNews from '@component/LatestNews';
import LinkCtaBlock from '@component/LinkCtaBlock';
import LogoGrid from '@component/LogoGrid';
import LogoGridItem from '@component/LogoGrid/LogoGridItem';
import { ProfileBlock, ProfilesBlock } from '@component/Profile';
import Quote from '@component/Quote';
import RichContent from '@component/RichContent';
import { animationVariants } from '@component/Shape/animations';
import TableBlock from '@component/TableBlock';
import Video from '@component/Video';
import { YourIrAnnouncementsBlock, YourIrBlock } from '@component/YourIrWidget';
import { Block, BlockView as BlockViewProps, filterBlocks } from '@lib/api/fragments/blocks';
import { useViewBlocks } from '@lib/store';
import { assertUnreachable } from '@liquorice/allsorts-craftcms-nextjs';
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { BlocksAnchorMenu } from './BlocksAnchorMenu';
// type BlockProps<T extends BlockTypename> = {
//   blockProps: BlockType<T>;
// };

export const BlockInner = (block: Block) => {
  switch (block.__typename) {
    case 'blocks_accordion_BlockType':
      return <Accordion.Block {...block} />;
    case 'blocks_contactFormWidget_BlockType':
      return <ContactForm {...block} />;
    case 'blocks_downloads_BlockType':
      return <Downloads {...block} />;
    case 'blocks_imageCarousel_BlockType':
      return <ImageCarousel {...block} />;
    case 'blocks_featureList_BlockType':
      return <FeatureList {...block} />;
    case 'blocks_logoGrid_BlockType':
      return <LogoGrid {...block} />;
    case 'blocks_image_BlockType':
      return <ImageWithCaption {...block} />;
    case 'blocks_latestArticlesWidget_BlockType':
      return <LatestNews {...block} />;
    case 'blocks_form_BlockType':
      return <FormBlock {...block} />;
    case 'blocks_profiles_BlockType':
      return <ProfilesBlock {...block} />;
    case 'blocks_profile_BlockType':
      return <ProfileBlock {...block} />;
    case 'blocks_quote_BlockType':
      return <Quote {...block} />;
    case 'blocks_richContent_BlockType':
      return <RichContent {...block} />;
    case 'blocks_video_BlockType':
      return <Video.Block {...block} />;
    case 'blocks_table_BlockType':
      return <TableBlock {...block} />;
    case 'blocks_yourirData_BlockType':
      return <YourIrBlock {...block} />;
    case 'blocks_yourirAnnouncements_BlockType':
      return <YourIrAnnouncementsBlock {...block} />;

    // ---- CTAs ----

    case 'blocks_imageTextCta_BlockType':
      return <ImageTextCtaBlock {...block} />;
    case 'blocks_imageTextCtaSimple_BlockType':
      return <ImageTextCtaSimpleBlock {...block} />;
    case 'blocks_imageTextCtaFeature_BlockType':
      return <ImageTextCtaFeatureBlock {...block} />;
    case 'blocks_simpleCta_BlockType':
      return <SimpleCtaBlock {...block} />;
    case 'blocks_colouredBoxCta_BlockType':
      return <ColouredBoxCtaBlock {...block} />;
    case 'blocks_largeCta_BlockType':
      return <LargeCtaBlock {...block} />;

    // ---- Child Blocks ----
    case 'blocks_linkCta_BlockType':
      return <LinkCtaBlock {...block} />;
    case 'blocks_downloadCta_BlockType':
      return <DownloadCtaBlock {...block} />;
    case 'blocks_articleCard_BlockType':
      return <ArticleCard.Block {...block} />;
    case 'blocks_featureListItem_BlockType':
      return <FeatureListItem {...block} />;
    case 'blocks_logoGridItem_BlockType':
      return <LogoGridItem {...block} />;

    // ---- Infographic Blocks ----
    case 'blocks_infographic_BlockType':
      return <InfographicBlock {...block} />;

    case 'infographicData_featuredGraph_BlockType':
      return <FeaturedGraph {...block} />;
    case 'infographicData_graph_BlockType':
      return <Graph.Block {...block} />;
    case 'infographicData_item_BlockType':
      return <></>;

    default:
      return assertUnreachable(block);
  }
};

export const BlockView = ({ anchor, block }: BlockViewProps) => {
  const ref = useRef(null);

  const inView = useInView(ref, {
    once: true,
    margin: '-100px',
  });

  if (block._blockMeta) block._blockMeta.inView = inView;

  return (
    <motion.section
      ref={ref}
      initial={{ opacity: 0 }}
      animate={inView ? { opacity: 1 } : undefined}
      transition={{ duration: 0.3 }}
      variants={animationVariants}>
      {anchor && <a id={anchor} />}
      <BlockInner {...block} />
    </motion.section>
  );
};

type BlocksProps = {
  showAnchorMenu?: boolean;
  blocks?: Block[];
};

const Blocks = ({ showAnchorMenu, blocks: maybeBlocks }: BlocksProps) => {
  const viewBlocks = useViewBlocks();

  const blocks = maybeBlocks ? filterBlocks(maybeBlocks).blocks : viewBlocks.blocks;

  const blocksOutput = blocks.map((v, i) => <BlockView key={i + 1} {...v} />);

  const hasAnchorMenu = showAnchorMenu;

  return blocks.length ? (
    <>
      {hasAnchorMenu && <BlocksAnchorMenu />}
      {blocksOutput}
    </>
  ) : (
    <></>
  );
};

Blocks.AnchorMenu = BlocksAnchorMenu;

export default Blocks;
