import { CardProps as CardPropsType } from '@component/Card';
import SimpleGrid, { SimpleGridProps } from '@component/SimpleGrid';

export type CardGridProps<T extends { id: ID }> = Omit<SimpleGridProps, 'children'> & {
  items?: T[];
  CardProps?: CardPropsType;
  Component: React.FC<CardPropsType<T>>;
};

const CardGrid = <T extends { id: ID }>({
  items = [],
  CardProps,
  Component,
  ...props
}: CardGridProps<T>) => {
  return (
    <SimpleGrid {...props}>
      {items.map((v) => (
        <Component key={v.id} {...CardProps} item={v} />
      ))}
    </SimpleGrid>
  );
};

export default CardGrid;
