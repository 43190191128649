import Text, { TextProps } from '@component/Text';
import { NavMenuItem as NavMenuItemType } from '@lib/api';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import NextLink from 'next/link';
import * as styles from './NavMenu.css';

export type NavMenuItemProps = {
  item?: NavMenuItemType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TextProps?: TextProps<any>;
};

export const NavMenuItemSimple = ({ item, TextProps = {} }: NavMenuItemProps) => {
  const { title, href, newWindow, type } = item ?? {};

  const hasLink = !!href && type === 'link';

  const linkProps = hasLink
    ? {
        component: 'a',
        href: href,
        target: newWindow ? '_blank' : undefined,
      }
    : null;

  if (hasLink) {
    TextProps.component = 'a';
  }

  const label = (
    <Text color="inherit" {...mergePropsClassName(TextProps, styles.simpleLink)}>
      {title}
    </Text>
  );

  let output = label;

  if (linkProps) {
    output = (
      <NextLink {...linkProps} passHref>
        {output}
      </NextLink>
    );
  }

  return output;
};
