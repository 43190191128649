import { isString } from '@liquorice/allsorts-craftcms-nextjs';
import { Variants, Transition } from 'framer-motion';

const createAnimationVariants = <T extends Variants>(variants: T) => variants;

export const animationVariants = createAnimationVariants({
  visible: {
    x: 0,
    y: 0,
    scale: 1,
    rotate: 0,
    opacity: 1,
    width: '100%',
    height: '100%',
  },

  // up
  up: {
    y: '-100%',
  },
  up100: {
    y: '-100%',
  },
  up50: {
    y: '-50%',
  },
  up200: {
    y: '-200%',
  },
  up25: {
    y: '-25%',
  },
  up10: {
    y: '-10%',
  },

  // down
  down: {
    y: '100%',
  },
  down100: {
    y: '100%',
  },
  down50: {
    y: '50%',
  },
  down200: {
    y: '200%',
  },
  down25: {
    y: '25%',
  },
  down10: {
    y: '10%',
  },

  // left
  left: {
    x: '-100%',
  },
  left100: {
    x: '-100%',
  },
  left50: {
    x: '-50%',
  },
  left200: {
    x: '-200%',
  },
  left25: {
    x: '-25%',
  },
  left10: {
    x: '-10%',
  },

  // right
  right: {
    x: '100%',
  },
  right100: {
    x: '100%',
  },
  right50: {
    x: '50%',
  },
  right200: {
    x: '200%',
  },
  right25: {
    x: '25%',
  },
  right10: {
    x: '10%',
  },

  // shrink
  shrink10: {
    scale: 0.9,
  },
  shrink25: {
    scale: 0.75,
  },
  shrink50: {
    scale: 0.5,
  },
  shrink100: {
    scale: 0,
  },

  // grow
  grow10: {
    scale: 1.1,
  },
  grow25: {
    scale: 1.25,
  },
  grow50: {
    scale: 1.5,
  },
  grow100: {
    scale: 2,
  },

  // turnLeft
  turnLeft: {
    rotate: -90,
  },
  turnLeft360: {
    rotate: -360,
  },
  turnLeft180: {
    rotate: -180,
  },
  turnLeft90: {
    rotate: -90,
  },
  turnLeft45: {
    rotate: -45,
  },

  // turnRight
  turnRight: {
    rotate: 90,
  },
  turnRight360: {
    rotate: 360,
  },
  turnRight180: {
    rotate: 180,
  },
  turnRight90: {
    rotate: 90,
  },
  turnRight45: {
    rotate: 45,
  },

  // fade
  fade: {
    opacity: 0,
  },

  // width0: {
  //   width: 0,
  // },

  // height0: {
  //   height: 0,
  // },
});

export type AppAnimations = typeof animationVariants;
export type AppAnimation = keyof AppAnimations;

export const animationDurations = {
  xs: 0.1,
  sm: 0.2,
  md: 0.4,
  lg: 0.5,
  xl: 1,
};
export type AppAnimationDurations = typeof animationDurations;
export type AppAnimationDuration = keyof AppAnimationDurations;

export const animationSteps = {
  xs: 0.05,
  sm: 0.1,
  md: 0.2,
  lg: 0.4,
  xl: 1,
};

export type AppAnimationSteps = typeof animationSteps;
export type AppAnimationStep = keyof AppAnimationSteps;

export type AnimationTransitionOptions = Transition & {
  duration?: AppAnimationDuration | number;
  order?: number;
  stepSize?: AppAnimationStep | number;
};

export const getAnimationTransition = (options?: AnimationTransitionOptions): Transition => {
  const { order = 0, stepSize = 'md', duration = 'md', ...props } = options ?? {};
  const stepSizeNum = isString(stepSize) ? animationSteps[stepSize] : stepSize;
  const durationNum = isString(duration) ? animationDurations[duration] : duration;

  return {
    duration: durationNum,
    delay: stepSizeNum * order,
    ...props,
  } as Transition;
};

export const svgDraw: Variants = {
  show: {
    pathLength: 1,
    opacity: 1,
  },
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const delay = i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
  visibleFast: (i) => {
    const delay = i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 0.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

export const circleReveal: Variants = {
  hidden: { clipPath: 'circle(0% at 50% 50%)' },
  visible: (i) => {
    const delay = i * 0.5;
    const duration = 1;
    return {
      clipPath: 'circle(50% at 50% 50%)',
      transition: {
        clipPath: { delay, type: 'spring', duration, bounce: 0 },
      },
    };
  },
};

export const svgFill: Variants = {
  hidden: { fillOpacity: 0 },
  visible: (i) => {
    const fillDuration = 1;
    const delay = i * 0.5;

    return {
      fillOpacity: 1,
      transition: {
        fillOpacity: { delay, type: 'spring', duration: fillDuration, bounce: 0 },
      },
    };
  },
};

export const svgDrawThenFill: Variants = {
  hidden: { pathLength: 0, opacity: 0, fillOpacity: 0 },
  visible: (i) => {
    const pathDuration = 1.5;
    const fillDuration = 1;
    const delay = i * 0.5;

    const pathDelay = delay;
    const fillDelay = pathDuration + pathDelay;

    return {
      pathLength: 1,
      opacity: 1,
      fillOpacity: 1,
      transition: {
        pathLength: { delay: pathDelay, type: 'spring', duration: pathDuration, bounce: 0 },
        fillOpacity: { delay: fillDelay, type: 'spring', duration: fillDuration, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

export const textReveal: Variants = {
  hidden: {
    opacity: 0,
    clipPath: 'inset(0 100% 0 0)',
  },
  visible: (i) => {
    const duration = 1;
    const delay = i * 0.5;

    return {
      clipPath: 'inset(0 0% 0 0)',
      opacity: 1,
      transition: {
        clipPath: { delay, type: 'spring', duration, bounce: 0 },

        opacity: { delay, duration: 0.05 },
      },
    };
  },
};

export const popUp: Variants = {
  hidden: {
    opacity: 0,
    y: '100px',
  },
  visible: (i) => {
    const duration = 0.7;
    const delay = i * 0.5;

    return {
      y: 0,
      opacity: 1,
      transition: {
        y: { delay, type: 'spring', duration, bounce: 0.4 },

        opacity: { delay, duration: duration * 0.5 },
      },
    };
  },
  invisible: {
    opacity: 0,
  },
};

export const popLeft: Variants = {
  hidden: {
    opacity: 0,
    x: '100px',
  },
  visible: (i) => {
    const duration = 0.7;
    const delay = i * 0.5;

    return {
      x: 0,
      opacity: 1,
      transition: {
        x: { delay, type: 'spring', duration, bounce: 0.4 },

        opacity: { delay, duration: duration * 0.5 },
      },
    };
  },
  invisible: {
    opacity: 0,
  },
};
