// import createStore from 'zustand';
import client from '@lib/api/client';
import { Category, CategoryTypeId, parseCategories } from '@lib/api/fragments/categories';
import { CategoryFragment } from '@lib/api/fragments/__generated__/categoryBase.generated';
import { Entry, EntryTypeId, parseEntries, parseEntry } from '@lib/api/fragments/entries';
import { ENTRIES_PER_PAGE } from '@lib/constants';
import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import { EntriesFragment } from 'gql/__generated__/entries.generated';
import { gql } from 'graphql-request';
import { EntryIndexQueryVariables, getSdk } from './__generated__/getEntryIndexData.generated';
import { parseApiError } from './parseApiError';

gql`
  query entryIndex(
    $query: String
    $includeCategories: Boolean = false
    $entryLimit: Int = 12
    $entryOffset: Int = 0
    $categoryLimit: Int = 99
    $entryType: [String]
    $categoryType: [String]
    $entryCategoryIds: [QueryArgument]
    $entryOrderBy: String
    $categoryOrderBy: String
  ) {
    entries: entries(
      search: $query
      section: $entryType
      relatedTo: $entryCategoryIds
      limit: $entryLimit
      offset: $entryOffset
      orderBy: $entryOrderBy
    ) {
      ...entryCard
    }

    featuredEntry: entry(
      isFeatured: true
      search: $query
      section: $entryType
      relatedTo: $entryCategoryIds
      orderBy: $entryOrderBy
    ) {
      ...entryCard
    }

    categories: categories(
      search: $query
      group: $categoryType
      limit: $categoryLimit
      orderBy: $categoryOrderBy
    ) @include(if: $includeCategories) {
      ...category
    }

    entryCount(search: $query, section: $entryType, relatedTo: $entryCategoryIds)
  }
`;

export enum ORDER_BY_FIELD_ENUM {
  SEARCH_SCORE = 'score',
  DATE = 'postDate',
  TITLE = 'title',
}
export enum ORDER_BY_ORDER_ENUM {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type OrderByQueryString =
  | `${ORDER_BY_FIELD_ENUM}`
  | `${ORDER_BY_FIELD_ENUM} ${ORDER_BY_ORDER_ENUM}`;

export const getOrderByOptions = () => {
  // <T extends keyof ORDER_BY_FIELD_ENUM>(exclude?: T[]) => {
  return {
    // [`${ORDER_BY_FIELD_ENUM.SEARCH_SCORE} ${ORDER_BY_ORDER_ENUM.DESC}`]: 'Relevance',
    [`${ORDER_BY_FIELD_ENUM.DATE} ${ORDER_BY_ORDER_ENUM.DESC}`]: 'Newest',
    [`${ORDER_BY_FIELD_ENUM.TITLE} ${ORDER_BY_ORDER_ENUM.ASC}`]: 'Title (A-Z)',
    [`${ORDER_BY_FIELD_ENUM.TITLE} ${ORDER_BY_ORDER_ENUM.DESC}`]: 'Title (Z-A)',
  } as Record<OrderByQueryString, string>;
};

export const getOrderByDefault = () => {
  return `${ORDER_BY_FIELD_ENUM.DATE} ${ORDER_BY_ORDER_ENUM.DESC}` as OrderByQueryString;
};

export interface PaginationQuery {
  /** The current page for paginated results */
  page?: number;
  /** The number of results per page */
  perPage?: number;
}

export type EntryIndexQueryProps<
  E extends EntryTypeId,
  C extends CategoryTypeId
> = PaginationQuery & {
  query?: string;
  /** The "section" of the entries to include */
  entryType?: E | E[];
  /** The "groups" of the categories to include */
  categoryType?: C | C[];
  /** The "ids" of categories to which entries should be related */
  categoryIds?: ID[];
  /** Query for category results */
  includeCategories?: boolean;
  /** The order of results */
  orderBy?: OrderByQueryString;
};

export type EntryIndexQueryPropsState<
  E extends EntryTypeId,
  C extends CategoryTypeId
> = EntryIndexQueryProps<E, C> & {
  entryType?: E[];
  /** The "groups" of the categories to include */
  categoryType?: C[];
};

export type EntryIndexQueryResult<E extends EntryTypeId, C extends CategoryTypeId> = {
  args: EntryIndexQueryProps<E, C>;
  entries: Entry<E>[];
  featuredEntry: Maybe<Entry<E>>;
  categories: { [P in C]?: Category<C>[] };
  entryCount: number;
  /** The current page */
  page: number;
  /** Total number of pages available */
  pages: number;
};

export const parseEntryIndexArgs = <E extends EntryTypeId, C extends CategoryTypeId>(
  maybeArgs: Partial<EntryIndexQueryProps<E, C>>
) => {
  const page = Math.max(maybeArgs.page ?? 1, 1);
  const entryLimit = Math.abs(maybeArgs.perPage ?? ENTRIES_PER_PAGE);

  const entryType: E[] = makeNonNullableArray(maybeArgs.entryType);
  const categoryType: C[] = makeNonNullableArray(maybeArgs.categoryType);

  const args: EntryIndexQueryPropsState<E, C> = {
    includeCategories: true,
    orderBy: getOrderByDefault(),
    ...maybeArgs,
    categoryType,
    entryType,
    perPage: entryLimit,
    page,
  };

  return args;
};

export const getEntryIndexData = async <E extends EntryTypeId, C extends CategoryTypeId>(
  maybeArgs: EntryIndexQueryProps<E, C>
) => {
  const args = parseEntryIndexArgs(maybeArgs);

  const {
    entryType,
    categoryType,
    query,
    orderBy,
    categoryIds,
    includeCategories,
    page: maybePage,
    perPage: entryLimit,
  } = args;

  // console.log({ includeCategories });

  // ----------------------------------------------------------------------------------------------
  // ---- Calculate pagination ----
  const page = maybePage || 1;
  const entryOffset = page > 1 ? (page - 1) * (entryLimit ?? ENTRIES_PER_PAGE) : 0;

  // ----------------------------------------------------------------------------------------------
  // ---- Build the query ----
  const {} = args;

  const isSearch = !!query;

  const variables: EntryIndexQueryVariables = {
    entryLimit,
    entryOffset,
    entryType,
    categoryType,
    entryCategoryIds: categoryIds ? categoryIds : undefined,
    includeCategories: includeCategories || !categoryType,
    entryOrderBy: orderBy,
    // categoryOrderBy: orderBy,
  };

  if (isSearch) {
    const searchOrderBy = `${ORDER_BY_FIELD_ENUM.SEARCH_SCORE} ${ORDER_BY_ORDER_ENUM.DESC}`;
    variables.query = query;
    variables.entryOrderBy = orderBy ?? searchOrderBy;
    variables.categoryOrderBy = orderBy ?? searchOrderBy;
  }

  const sdk = getSdk(client);

  const response = await sdk.entryIndex(variables).catch((err) => {
    console.error(err);
    console.warn(parseApiError(err));
  });

  // console.log(response);

  const {
    entries: maybeEntries,
    categories: maybeCategories,
    featuredEntry: maybeFeaturedEntry,
    entryCount = 0,
  } = response ?? {};
  // ----------------------------------------------------------------------------------------------
  // ---- Parse the entries ----

  const entries = parseEntries(maybeEntries as EntriesFragment[], entryType) as Entry<E>[];
  const featuredEntry = parseEntry(maybeFeaturedEntry as EntriesFragment, entryType) as Maybe<
    Entry<E>
  >;

  // ----------------------------------------------------------------------------------------------
  // ---- Split the categories and parse ----

  const categories = (categoryType ?? []).reduce((results, catId) => {
    return {
      ...results,
      [catId]: parseCategories(maybeCategories as CategoryFragment[], catId),
    };
  }, {} as { [P in C]: Category<C>[] });

  const result: EntryIndexQueryResult<E, C> = {
    args,
    entries,
    featuredEntry,
    categories,
    entryCount,
    page,
    pages: entryLimit ? Math.ceil(entryCount / entryLimit) : 1,
  };

  return result;
};
