import Box, { BoxProps } from '@component/Box';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import * as styles from './Shim.css';
export type ShimProps<T extends TagName> = BoxProps<
  T,
  {
    InnerProps?: BoxProps;
  } /* & styles.ShimVariants */
>;

const Shim = <T extends TagName>({ InnerProps, ratio, children, ...props }: ShimProps<T>) => {
  children = children && <Box {...mergePropsClassName(InnerProps, styles.inner)}>{children}</Box>;
  return (
    <Box ratio={ratio} {...mergePropsClassName(props, styles.root({}))}>
      {children}
    </Box>
  );
};

export default Shim;
