import AcknowledgementOfCountry from '@component/AcknowledgementOfCountry';
import Box from '@component/Box';
import Container from '@component/Container';
import Divider from '@component/Divider';
import Grid from '@component/Grid';
import { NavMenuSimple } from '@component/NavMenu';
import SocialLinks from '@component/SocialLinks';
import Text from '@component/Text';
import TopButton from '@component/TopButton';
import { useGlobals, useView } from '@lib/store';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import { colorVar } from '@theme/index';
import { useTranslations } from 'next-intl';
import * as style from './Footer.css';
import { RecaptchaPolicy } from '@component/Recaptcha';

const Footer = () => {
  const t = useTranslations('common');

  // const disclaimer = useGlobals('disclaimer');
  const { globalCopyrightEntity, globalCopyrightInfo, globalDisclaimer } =
    useGlobals('globalContent') ?? {};

  const view = useView();

  const pageNotices = maybeGet(view, 'pageNotices');

  return (
    <Box className={style.root} component="footer">
      <Container paddingY="2xl">
        <Grid rowGap="lg" alignItems="center">
          <Grid.Col>
            <AcknowledgementOfCountry />
          </Grid.Col>

          <Grid.Col lg={8}>
            <NavMenuSimple
              name="footerNav"
              direction={{ lg: 'row' }}
              flexWrap="wrap"
              spacing={4}
              TextProps={{ variant: 'h4', bold: true }}
            />
          </Grid.Col>
          <Grid.Col lg={4} textAlign={{ lg: 'right' }} stack="row" justifyContent={{ lg: 'end' }}>
            <SocialLinks
              sx={{ paddingX: 2 }}
              IconButtonProps={{ color: 'primary', variant: 'invert' }}
            />
            <TopButton
              sx={{
                bgcolor: 'white',
                color: colorVar('primary'),
                [':hover']: {
                  bgcolor: colorVar('primaryLight'),
                },
              }}
            />
          </Grid.Col>

          <Grid.Col>
            <Divider size="lg" />
          </Grid.Col>

          <Grid.Col lg={8}>
            <NavMenuSimple
              name="policiesNav"
              direction={{ lg: 'row' }}
              flexWrap="wrap"
              spacing={2}
              TextProps={{ variant: 'normal' }}
            />
          </Grid.Col>

          <Grid.Col lg={4} textAlign={{ lg: 'right' }}>
            <Text variant="normal">
              {`${t('copyrightSymbol')} ${
                globalCopyrightEntity ?? t('siteName')
              } ${new Date().getFullYear()}. ${globalCopyrightInfo}`}
            </Text>
          </Grid.Col>

          <Grid.Col stack spacing="md">
            <Text variant="tiny" html>
              {globalDisclaimer}
            </Text>
            {pageNotices?.map((v, i) => (
              <Text key={i} variant="tiny" html>
                {v.content}
              </Text>
            ))}
            <RecaptchaPolicy variant="tiny" />
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
