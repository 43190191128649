import BlockHeader from '@component/BlockHeader';
import Container from '@component/Container';
import { createBlock } from '@lib/api/fragments/blocks';
import { Stack } from '@mui/material';
import { gql } from 'graphql-request';
import { ProfileBlock } from './Profile';

gql`
  fragment profileBlock on blocks_profile_BlockType {
    __typename
    profileSingle {
      ...profileCard
    }
  }

  fragment profilesBlock on blocks_profiles_BlockType {
    __typename
    heading
    children {
      ...profileBlock
    }
  }
`;

export const ProfilesBlock = createBlock<'blocks_profiles_BlockType'>(({ heading, children }) => {
  return (
    <Container marginTop="2xl" marginBottom="2xl">
      <BlockHeader heading={heading} />
      <Stack spacing={6}>
        {children?.map((v, i) => (
          <ProfileBlock key={i} {...v} />
        ))}
      </Stack>
    </Container>
  );
});

export default ProfilesBlock;
