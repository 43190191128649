import Button, { ButtonProps } from '@component/Button';
import Icon from '@component/Icon';
import { useTranslations } from 'next-intl';

export type TopButtonProps = ButtonProps;

const TopButton = (props: TopButtonProps) => {
  const t = useTranslations('common');

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Button
      onClick={handleScrollToTop}
      endIcon={<Icon name="arrowUp" />}
      variant="contained"
      {...props}>
      {t('top')}
    </Button>
  );
};

export default TopButton;
