/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import * as Typed from '@liquorice/allsorts-craftcms-nextjs/lib/sanitiser/sanitiserTypes';
import { EntriesFragment } from 'gql/__generated__/entries.generated';
import { sanitiseAnything, SanitisedElement } from './sanitiseElements';

/**
 * Union of top level "Entries" defined with a '__typename'
 */
type RawEntries = EntriesFragment;

/**
 * __typename of top level Entry
 */
type EntryTypename = Typed.Typename<RawEntries>;

export const typenameMap: { [P in EntryTypeId]: EntryTypenameFromTypeId<P> } = {
  article: 'article_default_Entry',
  articleIndex: 'articleIndex_articleIndex_Entry',
  caseStudy: 'caseStudy_default_Entry',
  caseStudyIndex: 'caseStudyIndex_caseStudyIndex_Entry',
  // document: 'document_default_Entry',
  // documentIndex: 'documentIndex_documentIndex_Entry',
  insight: 'insight_default_Entry',
  insightIndex: 'insightIndex_insightIndex_Entry',
  page: 'page_default_Entry',
  // profile: 'profile_default_Entry',
  search: 'search_search_Entry',
};

// ----------------------------------------------------------------------------------------------------
// --- Extracted Type Ids (section handles) ---

export type EntryTypeId<T extends EntryTypename | string = EntryTypename> = T extends EntryTypename
  ? T extends `${infer R}_${string}_Entry`
    ? R
    : never
  : never;

export type EntryTypenameFromTypeId<T extends EntryTypeId> = Extract<
  EntryTypename,
  `${T}_${string}_Entry`
>;

export const extractEntryTypenameTypeId = <T extends EntryTypename>(name: T) => {
  const re = /(.+)_.+_Entry/;
  const result = re.exec(name) ?? [];

  return result[0] ? (result[1] as EntryTypeId<T>) : null;
};

export const makeEntryTypenameFromTypeId = <T extends EntryTypeId>(name: T) => {
  return typenameMap[name] as EntryTypenameFromTypeId<T>;
  // `${name}_default_Entry` as EntryTypenameFromTypeId<T>;
};

// ----------------------------------------------------------------------------------------------------
// --- Extracted sanitised types ---

export type SanitisedEntry<T extends EntryTypename = EntryTypename> = SanitisedElement<T>;

// ----------------------------------------------------------------------------------------------------

/**
 * Create a {@link Entry} consumer React Component by providing
 * the `__typename` of `EntryType` as T
 */
export const createEntry = <T extends EntryTypeId>(fn: (props: Entry<T>) => JSX.Element) => fn;

export const sanitiseEntry = <
  T extends EntriesFragment,
  Name extends EntryTypename = Typed.Typename<T>
>(
  maybeEntry?: T | null,
  typeNames?: MaybeArrayOf<Name>
) => (maybeEntry ? (sanitiseAnything.one(maybeEntry, typeNames) as SanitisedEntry<Name>) : null);

export const parseSanitisedEntry = <T extends EntryTypename>(
  sanitisedEntry: SanitisedEntry | null
) => {
  if (!sanitisedEntry) return null;

  return { ...(sanitisedEntry as SanitisedEntry<T>) };
};

export const parseEntries = <
  T extends EntriesFragment,
  Name extends EntryTypename = Typed.Typename<T>,
  TypeId extends EntryTypeId = EntryTypeId<Name>
>(
  maybeEntries: MaybeArrayOf<T>,
  typeIds?: MaybeArrayOf<TypeId>
) => {
  const rawEntriesArr = makeNonNullableArray(maybeEntries);
  const parsedEntries = rawEntriesArr.map((e) => parseEntry(e, typeIds));
  return makeNonNullableArray(parsedEntries);
};

export const parseEntry = <
  T extends EntriesFragment,
  Name extends EntryTypename = Typed.Typename<T>,
  TypeId extends EntryTypeId = EntryTypeId<Name>
>(
  maybeEntry?: T | null,
  typeIds?: MaybeArrayOf<TypeId>
) => {
  const typeNames = (makeNonNullableArray(typeIds) as EntryTypeId[]).map(
    makeEntryTypenameFromTypeId
  ) as Name[];
  const sanitisedEntry = sanitiseEntry(maybeEntry, typeNames.length ? typeNames : undefined);
  return sanitisedEntry ? parseSanitisedEntry<Name>(sanitisedEntry) : null;
};

export type Entry<T extends EntryTypeId = EntryTypeId> = Exact<
  Typed.ExtractByTypename<ReturnType<typeof parseEntry>, EntryTypenameFromTypeId<T>>
>;

// ----------------------------------------------------------------------------------------------------
// --- Type guards ---

export const isEntryType = <T extends EntryTypeId>(x: any, typeId: T): x is Entry<T> => {
  return !!x && x.__typename === makeEntryTypenameFromTypeId(typeId);
};
