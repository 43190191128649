import { BlockInner } from '@component/Blocks';
import Text from '@component/Text';
import { Block, createBlock } from '@lib/api/fragments/blocks';
import { Stack } from '@mui/material';
import { gql } from 'graphql-request';
import Accordion, { AccordionProps } from './Accordion';
import { AccordionItemType } from './AccordionItem';

gql`
  fragment accordionItemBlock on blocks_accordionItem_BlockType {
    __typename
    label: accordionItemLabel
    content: htmlContentDefault
    children {
      # __typename
      # ...linkCtaBlock
      ...downloadCtaBlock
    }
  }

  fragment accordionBlock on blocks_accordion_BlockType {
    __typename
    heading
    content: htmlContentSimple
    children {
      ...accordionItemBlock
    }
  }
`;

export const useAccordionBlock = createBlock<'blocks_accordion_BlockType', NoProps, AccordionProps>(
  ({ heading, content, children = [] }) => {
    const items = children?.reduce((results, child) => {
      // const subBlocks = <Blocks blocks={child.children as Block[]} />;

      const subBlocks = !!child.children?.length && (
        <Stack spacing={2} mt={4}>
          {child.children?.map((v, i) => (
            <BlockInner key={i} {...(v as Block)} />
          ))}
        </Stack>
      );

      const item: AccordionItemType = {
        label: child.label ?? '',
        content: (
          <>
            <Text html>{child.content}</Text>
            {subBlocks}
          </>
        ),
        children: subBlocks,
      };

      results.push(item);

      return results;
    }, [] as AccordionItemType[]);

    return {
      heading: heading ?? undefined,
      content: content ?? undefined,
      items,
    };
  }
);

export const AccordionBlock = createBlock<'blocks_accordion_BlockType'>((props) => (
  <Accordion {...useAccordionBlock(props)} marginY="2xl" />
));
