import { motion } from 'framer-motion';
import { svgDraw, svgFill } from './animations';
import { createShape, ShapeProps } from './Shape';

export const ComplexAustralia = createShape(
  ({ disableAnimation, className, delay = 0 }: ShapeProps) => {
    const stepSize = 0.5;

    const mapOutlineDelay = delay + 0;
    const mapFillDelay = mapOutlineDelay;

    const semiCircleDelay = mapOutlineDelay + 2 * stepSize;
    const squiggleDelay = mapOutlineDelay + 4 * stepSize;
    const sunDelay = mapOutlineDelay + 3 * stepSize;

    return (
      <motion.svg
        className={className}
        initial={disableAnimation ? 'visible' : 'hidden'}
        animate="visible"
        //
        viewBox="0 0 535.89 455.02"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="Layer_1-2">
          <motion.path
            variants={svgDraw}
            custom={sunDelay}
            className="cls-3"
            stroke="#5e7774"
            fill="none"
            strokeWidth="3px"
            d="M78.4,131.53c8.49,8.48,20,13.24,31.99,13.24s23.5-4.76,31.99-13.24c8.49-8.49,13.25-20,13.25-32.01s-4.77-23.52-13.25-32.01c-4.2-4.21-9.18-7.54-14.67-9.82-5.49-2.28-11.37-3.45-17.32-3.45s-11.83,1.17-17.32,3.45c-5.49,2.28-10.48,5.61-14.67,9.82-8.49,8.49-13.25,20.01-13.25,32.01s4.77,23.52,13.25,32.01h0Z"
          />
          <motion.path
            variants={svgDraw}
            custom={sunDelay + 1}
            className="cls-3"
            stroke="#5e7774"
            fill="none"
            strokeWidth="3px"
            d="M110.39,199v-28.85"
          />
          <motion.path
            variants={svgDraw}
            custom={sunDelay + 1}
            className="cls-3"
            stroke="#5e7774"
            fill="none"
            strokeWidth="3px"
            d="M110.39,28.85V0"
          />
          <motion.path
            variants={svgDraw}
            custom={sunDelay + 1}
            className="cls-3"
            stroke="#5e7774"
            fill="none"
            strokeWidth="3px"
            d="M10.89,99.5h28.85"
          />
          <motion.path
            variants={svgDraw}
            custom={sunDelay + 1}
            className="cls-3"
            stroke="#5e7774"
            fill="none"
            strokeWidth="3px"
            d="M181.04,99.5h28.85"
          />
          <motion.path
            variants={svgDraw}
            custom={sunDelay + 1}
            className="cls-3"
            stroke="#5e7774"
            fill="none"
            strokeWidth="3px"
            d="M40.02,29.13l20.43,20.43"
          />
          <motion.path
            variants={svgDraw}
            custom={sunDelay + 1}
            className="cls-3"
            stroke="#5e7774"
            fill="none"
            strokeWidth="3px"
            d="M160.34,149.45l20.43,20.43"
          />
          <motion.path
            variants={svgDraw}
            custom={sunDelay + 1}
            className="cls-3"
            stroke="#5e7774"
            fill="none"
            strokeWidth="3px"
            d="M180.76,29.13l-20.43,20.43"
          />
          <motion.path
            variants={svgDraw}
            custom={sunDelay + 1}
            className="cls-3"
            stroke="#5e7774"
            fill="none"
            strokeWidth="3px"
            d="M60.44,149.45l-20.43,20.43"
          />
          <motion.path
            variants={svgFill}
            custom={mapFillDelay}
            className="cls-4"
            fill="#fae0c8"
            d="M430.39,222.7c-11.74-18.03-24.42-35.45-36.39-53.32-2.33-3.52-4.02-7.42-5-11.52-3.09-12.85-5.65-25.82-8.5-38.74-1.87-8.42-5.88-11.93-14.46-12.02-4.46,0-6.74-1.76-7.22-6.07-.2-1.55-.66-3.07-.96-4.61-1.94-9.89-3.68-19.84-5.94-29.65-.37-1.07-.97-2.05-1.75-2.87-.78-.82-1.73-1.46-2.78-1.88-.94-.32-3.21,2.32-4.43,3.96-.75,1.38-1.29,2.86-1.6,4.39-5.31,15.72-10.53,31.46-15.97,47.17-3.95,11.38-13.96,14.83-24.07,8.32-6.41-4.11-12.7-8.45-18.96-12.79-7.24-5.03-8.19-7.98-5.69-16.54,3.4-11.58-1.12-20.96-12.36-25.5-4.37-1.75-8.87-3.19-13.21-5-9.21-3.87-17.34-2.52-24.93,4.04-8.79,7.67-17.95,14.83-26.85,22.44-5.86,4.97-11.97,6.12-19.27,3.05-12.13-5.07-21.47-2.37-29.78,7.95-14.2,17.64-28.3,35.41-42.65,52.89-2.72,3.2-6.08,5.79-9.87,7.6-14.74,7.08-29.76,13.57-44.54,20.6-4.49,2.01-8.68,4.65-12.41,7.86-13,11.78-16.29,28.47-9.23,45.52,8.74,21.12,17.6,42.2,26.58,63.23,3.1,7.29,3.29,13.98-.98,21.08-6.41,10.65-1.35,23.13,10.53,27.08,5.54,1.89,11.4,2.61,17.23,2.12,5.83-.49,11.49-2.17,16.64-4.95,32.45-16.52,66.53-28.9,101.17-39.92,16.67-5.3,31.32,.27,40.2,15.42,2.49,4.25,4.92,8.55,7.45,12.78,4.78,7.95,13.06,9.08,19.7,2.7,1.13-1.08,2.13-2.31,3.19-3.46,4.8-5.19,7.1-4.8,9.92,1.91,3.02,7.23,5.61,14.65,8.84,21.8,7.37,16.17,19.97,25.51,37.83,27.24,5.39,.51,10.78,.61,16.17,.89v.11c4.26,0,8.52,.15,12.78,0,19.93-.81,36.01-9.17,46.98-25.84,9.85-14.96,19.26-30.27,28.02-45.91,17.3-31.03,15.93-61.85-3.41-91.54Z"
          />
          <motion.path
            variants={svgFill}
            custom={mapFillDelay}
            className="cls-4"
            fill="#fae0c8"
            d="M364.48,415.13c-3.96-.65-7.99-.82-11.99-1.2,0,.04,0,.07,0,.11-.66,0-1.35-.11-2.01-.13-11.93-.51-16.9,5.65-13.88,17.27,1.63,6.25,3.23,12.53,5.08,18.75,1.74,5.81,4.93,6.74,9.06,2.35,6.74-7.14,13.37-14.43,19.72-21.92,5.43-6.38,2.45-13.84-5.97-15.22Z"
          />
          <g>
            <motion.path
              variants={svgDraw}
              custom={mapOutlineDelay}
              className="cls-1"
              stroke="#f5811f"
              strokeMiterlimit="10"
              fill="none"
              strokeWidth="3px"
              d="M451.4,213.7c-11.74-18.03-24.42-35.45-36.39-53.32-2.33-3.52-4.02-7.42-5-11.52-3.09-12.85-5.65-25.82-8.5-38.74-1.87-8.42-5.88-11.93-14.46-12.02-4.46,0-6.74-1.76-7.22-6.07-.2-1.55-.66-3.07-.96-4.61-1.94-9.89-3.68-19.84-5.94-29.65-.37-1.07-.97-2.05-1.75-2.87-.78-.82-1.73-1.46-2.78-1.88-.94-.32-3.21,2.32-4.43,3.96-.75,1.38-1.29,2.86-1.6,4.39-5.31,15.72-10.53,31.46-15.97,47.17-3.95,11.38-13.96,14.83-24.07,8.32-6.41-4.11-12.7-8.45-18.96-12.79-7.24-5.03-8.19-7.98-5.69-16.54,3.4-11.58-1.12-20.96-12.36-25.5-4.37-1.75-8.87-3.19-13.21-5-9.21-3.87-17.34-2.52-24.93,4.04-8.79,7.67-17.95,14.83-26.85,22.44-5.86,4.97-11.97,6.12-19.27,3.05-12.13-5.07-21.47-2.37-29.78,7.95-14.2,17.64-28.3,35.41-42.65,52.89-2.72,3.2-6.08,5.79-9.87,7.6-14.74,7.08-29.76,13.57-44.54,20.6-4.49,2.01-8.68,4.65-12.41,7.86-13,11.78-16.29,28.47-9.23,45.52,8.74,21.12,17.6,42.2,26.58,63.23,3.1,7.29,3.29,13.98-.98,21.08-6.41,10.65-1.35,23.13,10.53,27.08,5.54,1.89,11.4,2.61,17.23,2.12,5.83-.49,11.49-2.17,16.64-4.95,32.45-16.52,66.53-28.9,101.17-39.92,16.67-5.3,31.32,.27,40.2,15.42,2.49,4.25,4.92,8.55,7.45,12.78,4.78,7.95,13.06,9.08,19.7,2.7,1.13-1.08,2.13-2.31,3.19-3.46,4.8-5.19,7.1-4.8,9.92,1.91,3.02,7.23,5.61,14.65,8.84,21.8,7.37,16.17,19.97,25.51,37.83,27.24,5.39,.51,10.78,.61,16.17,.89v.11c4.26,0,8.52,.15,12.78,0,19.93-.81,36.01-9.17,46.98-25.84,9.85-14.96,19.26-30.27,28.02-45.91,17.3-31.03,15.93-61.85-3.41-91.54Z"
            />
            <motion.path
              variants={svgDraw}
              custom={mapOutlineDelay}
              className="cls-1"
              stroke="#f5811f"
              strokeMiterlimit="10"
              fill="none"
              strokeWidth="3px"
              d="M385.49,406.13c-3.96-.65-7.99-.82-11.99-1.2,0,.04,0,.07,0,.11-.66,0-1.35-.11-2.01-.13-11.93-.51-16.9,5.65-13.88,17.27,1.63,6.25,3.23,12.53,5.08,18.75,1.74,5.81,4.93,6.74,9.06,2.35,6.74-7.14,13.37-14.43,19.72-21.92,5.43-6.38,2.45-13.84-5.97-15.22Z"
            />
          </g>
          <motion.path
            variants={svgDraw}
            custom={squiggleDelay}
            className="cls-2"
            stroke="#dd1d8d"
            strokeMiterlimit="10"
            fill="none"
            strokeWidth="3px"
            d="M273.89,381.71l-18.36,13.58c-8.51,6.28-18.63,6.28-27.14,0l-18.36-13.58c-8.51-6.28-18.63-6.28-27.14,0l-18.36,13.58c-8.51,6.28-18.63,6.28-27.14,0l-18.36-13.58c-8.51-6.28-18.63-6.28-27.14,0l-18.36,13.58c-8.51,6.28-18.63,6.28-27.14,0l-18.36-13.58c-8.51-6.28-18.63-6.28-27.14,0"
          />
          <motion.path
            variants={svgFill}
            custom={semiCircleDelay}
            className="cls-5"
            fill="#f6c55d"
            fillRule="evenodd"
            d="M535.89,207h-142c0,18.84,7.49,36.9,20.81,50.21,13.32,13.31,31.38,20.79,50.2,20.79,18.82,0,36.88-7.49,50.19-20.8,13.31-13.31,20.79-31.36,20.8-50.19h0Z"
          />
        </g>
      </motion.svg>
    );
  }
);
