import Box, { BoxProps } from '@component/Box';
import Grid from '@component/Grid';
import Text from '@component/Text';
import { formatGraphValue, GraphEntry } from '@lib/api/parseGraphEntry';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import Circle from '@mui/icons-material/Circle';
import { Table, TableBody, TableCell, TableFooter, TableRow } from '@mui/material';
import { Color } from '@theme/_vars.css';

export type GraphLegendLabelProps = BoxProps<'span'> & { legendColor: Color };
export const GraphLegendLabel = ({ legendColor, children, ...props }: GraphLegendLabelProps) => {
  return (
    <Box component="span" stack="row" spacing="md" {...props} color={legendColor ?? 'inherit'}>
      <Circle color="inherit" />
      <Text component="span" color="greyDark">
        {children}
      </Text>
    </Box>
  );
};
export type GraphLegendProps = BoxProps & { graph: GraphEntry };

export const GraphLegend = ({ graph, ...props }: GraphLegendProps) => {
  const { datasetType, data = [], valueFormat, content } = graph;

  const cellSx = { border: 0, paddingBottom: 2 };

  return (
    <Box {...props}>
      {datasetType === 'single' ? (
        <Table padding="none">
          <TableBody>
            {data.map((row, i) => (
              <TableRow key={i}>
                <TableCell
                  {...{
                    sx: { ...cellSx, textAlign: 'left' },
                    component: 'th',
                    scope: 'row',
                  }}>
                  <GraphLegendLabel legendColor={row.color ?? 'inherit'}>
                    {row.label}
                  </GraphLegendLabel>
                </TableCell>
                <TableCell sx={{ ...cellSx, textAlign: 'right' }}>
                  <Text component="span">
                    {formatGraphValue(maybeGet(row, 'value'), valueFormat)}
                  </Text>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={2} sx={{ paddingTop: 4, border: 0 }}>
                <Text variant="tiny" html>
                  {content}
                </Text>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      ) : (
        <>
          <Grid>
            {data.map((row, i) => (
              <Grid.Col key={i} xs={12} lg={6}>
                <GraphLegendLabel legendColor={row.color ?? 'inherit'}>
                  {row.label}
                </GraphLegendLabel>
              </Grid.Col>
            ))}
          </Grid>
          <Text variant="tiny" html>
            {content}
          </Text>
        </>
      )}
    </Box>
  );
};
