import Text from '@component/Text';
import { Button, ButtonGroup, Stack } from '@mui/material';
import YourIrWidget from './YourIrWidget';

export const YourIRPriceChart = () => {
  return (
    <YourIrWidget>
      <Text component="p" html variant="h6" mb={4}>
        {`Share price from `}
        <strong data-yourir="priceComparisonChart1.displayedRange.from" />
        {` to `}
        <strong data-yourir="priceComparisonChart1.displayedRange.to" />
      </Text>

      <div
        data-yourir={
          'priceComparisonChart1 range=3m volume.visible=false announcements.visible=false'
        }
      />
      <Stack
        {...{
          mt: 4,

          direction: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          spacing: 2,
        }}>
        <Text variant="small" className="yourir-price-range">
          Range
        </Text>
        <ButtonGroup size="small" color="primary" variant="outlined">
          <Button data-yourir="priceComparisonChart1.range" value="1m">
            1 MTH
          </Button>
          <Button data-yourir="priceComparisonChart1.range" value="3m">
            3 MTHS
          </Button>
          <Button data-yourir="priceComparisonChart1.range" value="6m">
            6 MTHS
          </Button>
          <Button data-yourir="priceComparisonChart1.range" value="1y">
            1 YR
          </Button>
          <Button data-yourir="priceComparisonChart1.range" value="2y">
            2 YRS
          </Button>
          <Button data-yourir="priceComparisonChart1.range" value="5y">
            5 YRS
          </Button>
          <Button data-yourir="priceComparisonChart1.range" value="99y">
            MAX
          </Button>
        </ButtonGroup>
      </Stack>
    </YourIrWidget>
  );
};
