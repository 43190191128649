import IconButton from '@component/IconButton';
import Logo from '@component/Logo';
import NavMenu from '@component/NavMenu';
import { createToggleStore } from '@lib/utils/createToggleStore';
import { Dialog, DialogContent, Stack } from '@mui/material';
import { useState } from 'react';

/**
 * A hook providing global state for the Login Dialog
 */
export const useLoginDialog = createToggleStore(false);

/**
 * The login navigation modal
 */
const Login = () => {
  /* Dialog open state */
  const { toggle: toggleOpen, value: open } = useLoginDialog();
  const [openId, setOpenId] = useState<string>('')

  /** Dialog click handler */
  const handleClose = () => toggleOpen(false);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'xs'}>
      <DialogContent>
        <Stack direction="row" justifyContent="space-between" p={2}>
          <Logo />
          <IconButton icon="close" onClick={handleClose} />
        </Stack>
        <NavMenu name="loginNav" openId={openId} setOpenId={setOpenId} />
      </DialogContent>
    </Dialog>
  );
};

export default Login;
