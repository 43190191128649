// export const fmtArticleLength = (article?: Entry<'article'>) => {
//   const articleReadTime = article?.readTime;
//   return articleReadTime ? `${articleReadTime} min read` : '';
// };

import { Category } from '@lib/api/fragments/categories';
import { makeNonNullableArray, toNumber } from '@liquorice/allsorts-craftcms-nextjs';

export const fmtCategoryNames = (
  maybeCats?: MaybeArrayOf<Category>,
  join = ', ',
  exclude?: MaybeArrayOf<Category>
) => {
  const excludeIds = makeNonNullableArray(exclude).map((v) => v.id);
  const cats = makeNonNullableArray(maybeCats).filter((v) => !excludeIds.includes(v.id));

  return cats.map((v) => v.title).join(join);
};

export const fmtDate = (n?: string | number | null) => {
  if (!n) return undefined;
  const date = new Date(n);
  return `${date.getDate()} ${date.toLocaleString(process.env.NEXT_PUBLIC_LOCALE ?? 'en-AU', {
    month: 'short',
  })}, ${date.getFullYear()}`;
};

export const fmtPercent = (n?: string | number | null) => {
  return `${toNumber(n)}%`;
};

export const fmtMoney = (n?: string | number | null) => {
  return `$${toNumber(n)}`;
};

export const floatPoint = (n: string | number | null) => {
  return toNumber(parseFloat(`${toNumber(n)}`).toPrecision(12));
};

// export const fmtCategoryCount = (category?: Category) => {
//   if (!category) return '';

//   const { __typeId, relatedEntryCount } = category;

//   const { labels } = getEntryTypeSettings(__typeId) ?? {};
//   const { name = 'Items', singularName = 'Item' } = labels ?? {};

//   return `${relatedEntryCount} ${numberedLabel(relatedEntryCount ?? 0, name, singularName)}`;
// };

export const numberedLabel = (n: number | undefined, name: string, nameSingular: string) =>
  (Math.abs(n ?? 0) === 1 ? nameSingular : name).replace('%n', `${n ?? 0}`);

// export const CategoryTypeLabel = (typeId: CategoryTypeId, labelType?: LabelType) => {
//   const { labels } = getCategoryTypeSettings(typeId);

//   return labels[labelType ?? 'name'];
// };

// export const EntryTypeLabel = (typeId: EntryTypeId, labelType?: LabelType) => {
//   const settings = getEntryTypeSettings(typeId);

//   const labels = settings?.labels ?? {
//     name: 'Items',
//     singularName: 'Item',
//   };

//   return labels[labelType ?? 'name'];
// };

export const removeOrphan = (str: string, charLim = 10) => {
  const lastIndex = str.lastIndexOf(' ');

  if (lastIndex < 1) return str;

  const before = str.slice(0, lastIndex);
  const after = str.slice(lastIndex + 1);

  if (after.length > charLim) return str;

  return (
    <>
      {before}&nbsp;{after}
    </>
  );
};
