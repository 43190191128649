import { createShape, ShapeProps } from './Shape';

export const SwoopInvert = createShape(({ strokeWidth, className }: ShapeProps) => (
  <svg
    width="1254"
    height="320"
    viewBox="0 0 1254 320"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...{ strokeWidth, className }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1253.62 0H0V320H517L704.141 193.187C747.052 165.173 782.041 141.127 812.047 120.506C961.653 17.6916 987.396 0 1253.62 0Z"
      fill="currentColor"
    />
  </svg>
));
