import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { Box } from '@mui/material';
import { useInView } from 'framer-motion';
import { useRef } from 'react';
import { Circle } from './Circle.svg';
import * as styles from './ComplexDiamond.css';
import { Diamond } from './Diamond.svg';
import { DiamondOutline } from './DiamondOutline.svg';
import { ComplexShapeProps } from './Shape';

export type ComplexDiamondProps = ComplexShapeProps<
  'diamond' | 'diamondOutline1' | 'diamondOutline2' | 'circle'
>;

export const ComplexDiamond = ({
  disableAnimation,
  diamondColor = 'orange',
  diamondProps,
  diamondOutline1Color = 'orangeMid',
  diamondOutline1Props,
  diamondOutline2Color = 'orangeMid',
  diamondOutline2Props,
  circleColor = 'yellow',
  circleProps,
  ...props
}: ComplexDiamondProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const inView = useInView(ref, {
    amount: 1,
    once: true,
  });

  const inner = (
    <>
      <Diamond
        disableAnimation={disableAnimation}
        key={1}
        initial={['shrink100', 'fade']}
        animate
        transitionProps={{
          type: 'spring',
          duration: 0.6,
        }}
        color={diamondColor}
        {...mergePropsClassName(diamondProps, styles.diamond)}
      />

      <Circle
        disableAnimation={disableAnimation}
        initial={['down50', 'fade']}
        transitionProps={{ delay: 0.3, duration: 0.5 }}
        color={circleColor}
        {...mergePropsClassName(circleProps, styles.circle)}
      />
      <DiamondOutline
        disableAnimation={disableAnimation}
        delay={1}
        color={diamondOutline1Color}
        {...mergePropsClassName(diamondOutline1Props, styles.diamondOutline1)}
      />
      <DiamondOutline
        disableAnimation={disableAnimation}
        delay={2}
        color={diamondOutline2Color}
        {...mergePropsClassName(diamondOutline2Props, styles.diamondOutline2)}
      />
    </>
  );

  return (
    <Box ref={ref} {...mergePropsClassName(props, styles.root)}>
      {inView && inner}
    </Box>
  );
};
