/**
 * Defines the EntryTypes for the App.
 * @param {string} typeId This needs to match the 'sectionHandle' in Craft CMS
 * @param {'single' | 'singleIndex' | 'entry'} sectionType
 * @param {'uri' | 'slug' | 'id'} queryType
 * @param {string[]} categoryTypeIds Category 'groupHandle' that relate to this type in Craft CMS
 * @param {string} indexUri
 */
export default [
  {
    typeId: 'article',
    queryType: 'slug',
    categoryTypeIds: ['articleCategory'],
  },
  {
    typeId: 'caseStudy',
    queryType: 'slug',
    categoryTypeIds: ['caseStudyCategory'],
  },
  {
    typeId: 'insight',
    queryType: 'slug',
    categoryTypeIds: ['insightCategory'],
  },
  {
    typeId: 'page',
    queryType: 'uri',
  },
  // {
  //   typeId: 'profile',
  //   queryType: 'id',
  // },
  {
    typeId: 'search',
    sectionType: 'single',
    queryType: 'slug',
  },
  {
    typeId: 'articleIndex',
    sectionType: 'singleIndex',
    queryType: 'slug',
  },
  {
    typeId: 'insightIndex',
    sectionType: 'singleIndex',
    queryType: 'slug',
  },
  {
    typeId: 'caseStudyIndex',
    sectionType: 'singleIndex',
    queryType: 'slug',
  },
] as const;
