import { PublicEntryTypeId } from '@lib/config';
import { getMessages } from '@lib/l10n';
import { AppIndexData, getAppContext, getIndexDataCallback, isEntryIndexKey } from '@lib/store';
import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import { isString } from '@liquorice/allsorts-craftcms-nextjs';
import type {
  GetStaticPropsContext as NextGetStaticPropsContext,
  GetStaticPropsResult,
} from 'next';
// import { getRelatedEntries } from './getRelatedEntries';
import { getRedirect, getViewByUri } from './getViewByUri';

interface GetStaticPropsOptions {
  uriBase?: string;
  entryTypeId?: PublicEntryTypeId;
  slug?: string;
  uri?: string;
  indexDataCallback?: () => ReturnOrPromiseType<unknown>;
  revalidate?: number | false;
}

export const getStaticPropsWithOptions = async (
  context: NextGetStaticPropsContext,
  { revalidate = 30, ...options }: GetStaticPropsOptions
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { params, locale } = context;

  // ---------------------------------------------------------------------------------------------- //
  // ---- Process the preview data ----

  const isPreview = context.preview;
  const previewToken = (context?.previewData as Props)?.token;

  // ---------------------------------------------------------------------------------------------- //
  // ---- Fetch the page data ----

  const section = options.entryTypeId;
  const slug = options.slug;

  // Create a backup for the home page
  const uri = options.uri?.length
    ? options.uri
    : section === 'page' && !slug
    ? '__home__'
    : undefined;

  const variables = {
    section,
    slug,
    uri,
  };

  // ----------------------------------------------------------------------------------------------
  // ---- Get the View
  const view = await getViewByUri(variables, { isPreview, previewToken });

  // ----------------------------------------------------------------------------------------------
  // ---- If no View, get Redirect

  const redirect = uri && !view ? await getRedirect(uri) : null;
  console.log({ redirect, uri });

  if (redirect) {
    return {
      redirect,
      revalidate,
    };
  }

  const is404 = !view;

  if (is404) {
    // console.warn(`NOT FOUND: "${uri}"`);

    return {
      notFound: true,
    };
  }

  const indexData: AppIndexData = {};

  if (isEntryIndexKey(section)) {
    const indexDataCallback = getIndexDataCallback(section);
    const sectionIndexData = await indexDataCallback({});

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    indexData[section] = sectionIndexData as any;
  }

  // const relatedEntries = await getRelatedEntries( view );

  const appContext = await getAppContext({ view, indexData });

  // get the localisation messages
  const messages = await getMessages(locale);

  return {
    props: {
      appContext,
      indexData,
      messages,
    },
    ...(revalidate && { revalidate }), // In seconds
  };
};

export const createStaticPropsFetcher =
  <T extends PublicEntryTypeId | '404'>(entryTypeId: T) =>
  async (context: NextGetStaticPropsContext) => {
    const { params } = context;

    const uriArr = makeNonNullableArray(params?.uri);

    const slug = isString(params?.slug) ? params?.slug : undefined;
    const uri = uriArr.length ? uriArr.join('/') : undefined;

    const args: GetStaticPropsOptions =
      entryTypeId === '404'
        ? {
            entryTypeId: 'page',
            uri: '404',
          }
        : {
            entryTypeId,
            slug,
            uri,
          };

    return (await getStaticPropsWithOptions(context, args)) as
      | Promise<GetStaticPropsResult<Props>>
      | GetStaticPropsResult<Props>;
  };

/**
 * Get the global props for a 404 page
 */
export const get404Props = createStaticPropsFetcher('404');
