import Text, { TextProps } from '@component/Text';
import { useView } from '@lib/store';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';

export const usePageTitle = (): string => {
  const { title, ...view } = useView();
  const maybePageTitle = maybeGet(view, 'pageTitle');

  return (maybePageTitle || title) ?? '';
};

export type PageTitleProps = TextProps<
  'h1',
  {
    pageTitle?: string;
  }
>;

export const PageTitle = ({ pageTitle: maybePageTitle, ...props }: PageTitleProps) => {
  const text = usePageTitle();
  const pageTitle = maybePageTitle ?? text;

  return (
    <Text variant="h1" component="h1" html {...props}>
      {pageTitle}
    </Text>
  );
};
