// import { fontSizes } from 'theme/themeTypography';
import { MuiComponentOverrides } from '.';

declare module '@mui/material/InputBase' {
  interface InputBasePropsSizeOverrides {
    large: true;
  }
}

const MuiInputOverrides: MuiComponentOverrides<'MuiInput', 'MuiInput'> = {
  styleOverrides: {
    input: ({ ownerState }) => ({
      ...(ownerState.size === 'large' && {
        padding: 16,
        // fontSize: fontSizes.large,
      }),
    }),
  },
};

export default MuiInputOverrides;
