import Footer from '@component/Footer';
import NavBar, { useNavbarState } from '@component/NavBar';
import { navbarVars } from '@component/NavBar/NavBar.css';
import LayoutSeo from '@layout/LayoutSeo';
import { SeoScripts } from '@layout/LayoutSeo/SeoScripts';

import { assignInlineVars } from '@vanilla-extract/dynamic';
import classNames from 'classnames';
import Head from 'next/head';
import { forwardRef } from 'react';
import * as style from './Layout.css';

export type LayoutProps = PropsWith<
  'children',
  {
    id?: string;
  }
>;

const Layout = forwardRef<HTMLDivElement, LayoutProps>(function Layout(
  { children, ...props },
  ref
) {
  const navbarHeight = useNavbarState((state) => state.height);

  return (
    <>
      <LayoutSeo />
      <SeoScripts />
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>
      <div
        ref={ref}
        style={assignInlineVars({
          [navbarVars.height]: `${navbarHeight}px` ?? '',
        })}
        className={classNames(style.root)}
        {...props}>
        <NavBar />
        <main className={style.main}>{children}</main>
        <Footer />

        {/* <DevTestGrid /> */}
      </div>
    </>
  );
});

export default Layout;
