import { createShape, ShapeProps } from './Shape';

export const Rhombus = createShape(({ strokeWidth, className }: ShapeProps) => (
  <svg
    width="1062"
    height="502"
    viewBox="0 0 1062 502"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...{ strokeWidth, className }}>
    <path
      d="M549.861 -317.495L418.676 -393.272C257.887 -483.988 206.147 -503 106.468 -503H0V-0.126617L549.725 317.242L600.243 346.439C827.168 477.216 878.365 502.339 992.981 502.339H1099.45V0.00921631L549.725 -317.359L549.861 -317.495Z"
      fill="currentColor"
    />
  </svg>
));
