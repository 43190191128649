import Box, { BoxProps } from '@component/Box';
import Icon from '@component/Icon';
import Link, { LinkProps } from '@component/Link';
import { typenameMap } from '@lib/api/fragments/entries';
import { useAppLink, useView } from '@lib/store';
import { maybeGet, mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'next-intl';
import React from 'react';
import * as styles from './Breadcrumbs.css';

export type BreadcrumbsProps = BoxProps & {
  includeCurrent?: boolean;
};

const Breadcrumbs = ({ includeCurrent, ...props }: BreadcrumbsProps) => {
  const t = useTranslations('common');
  const view = useView();

  const articleIndex = useAppLink('articleIndex');
  const caseStudyIndex = useAppLink('caseStudyIndex');
  const insightIndex = useAppLink('insightIndex');

  let ancestors: { title?: string; uri?: string }[] = maybeGet(view, 'ancestors') ?? [];

  switch (view.__typename) {
    case typenameMap.article:
      if (articleIndex) ancestors = [articleIndex];
      break;
    case typenameMap.caseStudy:
      if (caseStudyIndex) ancestors = [caseStudyIndex];
      break;
    case typenameMap.insight:
      if (insightIndex) ancestors = [insightIndex];
      break;
  }

  const sep = <Icon color="primary" name={'chevronLeft'} />;

  const linkProps: LinkProps = {
    underline: 'hover',
    variant: 'small',
    color: 'primary',
  };

  return (
    <Box {...mergePropsClassName(props, styles.root)}>
      {sep}
      <Link key={'home'} href={'/'} {...linkProps}>
        {t('homePage')}
      </Link>
      {ancestors?.map((v, i) => (
        <React.Fragment key={i}>
          {sep}
          <Link href={v.uri} {...linkProps}>
            {v.title}
          </Link>
        </React.Fragment>
      ))}
      {includeCurrent && (
        <>
          {sep}
          <Link key={view.id} disableLink underline="none">
            {view.title}
          </Link>
        </>
      )}
    </Box>
  );
};

export default Breadcrumbs;
