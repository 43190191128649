import { createField } from '@formie/parse/formieParser';
import { useFormField } from '@formie/useForm';
import { gql } from 'graphql-request';
import { DefaultTextField, DefaultTextFieldProps } from './DefaultTextField';
import { FieldErrors } from './FieldErrors';

gql`
  fragment fieldSingleLineText on Field_SingleLineText {
    ...fieldBase
  }

  fragment fieldMultiLineText on Field_MultiLineText {
    ...fieldBase
  }

  fragment fieldEmail on Field_Email {
    ...fieldBase
  }

  fragment fieldNumber on Field_Number {
    ...fieldBase
  }

  fragment fieldPhone on Field_Phone {
    ...fieldBase
  }

  fragment fieldDate on Field_Date {
    ...fieldBase
  }
`;

export const Field_Text = createField(({ field, ..._props }) => {
  const {
    defaultValue: _defaultValue,

    handle,
    name,
    placeholder,
    required,
    instructions,
  } = field;

  const { value, setValue, error, errors } = useFormField(handle);

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(ev.target.value);
  };

  const fieldProps: DefaultTextFieldProps = {
    value,
    error,
    name: handle,
    helperText: instructions,
    label: name,
    placeholder,
    required,
    onChange: handleChange,
  };

  switch (field.__typename) {
    case 'Field_MultiLineText':
      fieldProps.multiline = true;
      fieldProps.minRows = 3;
      break;

    case 'Field_Email':
      fieldProps.type = 'email';
      break;

    case 'Field_Number':
      fieldProps.type = 'number';
      break;

    case 'Field_Phone':
      fieldProps.type = 'tel';
      break;

    case 'Field_Date':
      fieldProps.type = 'date';
      fieldProps.InputLabelProps = { ...fieldProps.InputLabelProps, shrink: true };
      break;
  }

  return (
    <>
      <DefaultTextField {...fieldProps} />
      <FieldErrors errors={errors} />
    </>
  );
});
