import {
  FieldAttributeFragment,
  FieldOptionFragment,
} from '@formie/gql/__generated__/fieldCommon.generated';
import { FormieFieldFragment } from '@formie/gql/__generated__/fields.generated';
import { FormieFormFragment } from '@formie/gql/__generated__/form.generated';
import { parseFormieFieldCondition } from '@formie/fieldConditions';
import { makeNonNullableArray, toString } from '@liquorice/allsorts-craftcms-nextjs';

import {
  createPropSanitiser,
  deleteUndefined,
  firstNonNullable,
  SanitiserTypes,
  toBoolean,
  Typename,
} from '@liquorice/allsorts-craftcms-nextjs';

type RawElements = FormieFieldFragment;

/**
 * __typename of top level Elements
 */
type FieldTypename = Typename<RawElements>;

// ----------------------------------------------------------------------------------------------------
// ---- Label Options ----

export type LabelPosition = 'default' | 'hidden';

export const parseLabelPosition = (data: Maybe<string>): LabelPosition => {
  if (!data) return 'default';

  const type = toString(data).split('\\').reverse()[0].toLowerCase();

  if (type === 'hidden') return type;

  return 'default';
};
// ----------------------------------------------------------------------------------------------------
// ---- Field Options ----

export type FieldOption = {
  value: string;
  label: string;
};

export const parseFieldOptions = (
  data: MaybeArrayOf<FieldOptionFragment | FieldAttributeFragment>
): FieldOption[] => {
  return makeNonNullableArray(data).map(({ value, label }) => ({
    value: toString(value),
    label: toString(label),
  }));
};

// ----------------------------------------------------------------------------------------------------
// --- Define the callbacks ---

export const sanitiseFormieField = createPropSanitiser((): RawElements | null => null, {
  errorMessage: toString,
  instructions: toString,
  handle: toString,
  name: toString,
  placeholder: toString,
  required: toBoolean,
  options: parseFieldOptions,
  multi: toBoolean,
  conditions: parseFormieFieldCondition,
  enableConditions: toBoolean,
  labelPosition: parseLabelPosition,
  //Agree
  defaultState: toBoolean,
  checkedValue: toString,
  uncheckedValue: toString,
  //
  //Address
  address1Placeholder: toString,
  address1Hidden: toBoolean,
  address1Required: toBoolean,
  address2Placeholder: toString,
  address2Hidden: toBoolean,
  address2Required: toBoolean,
  address3Placeholder: toString,
  address3Hidden: toBoolean,
  address3Required: toBoolean,
  cityPlaceholder: toString,
  cityHidden: toBoolean,
  cityRequired: toBoolean,
  countryPlaceholder: toString,
  countryHidden: toBoolean,
  countryRequired: toBoolean,
  countryOptions: parseFieldOptions,
  statePlaceholder: toString,
  stateHidden: toBoolean,
  stateRequired: toBoolean,
  zipPlaceholder: toString,
  zipHidden: toBoolean,
  zipRequired: toBoolean,
  //
  // Name
  firstNamePlaceholder: toString,
  firstNameRequired: toBoolean,
  middleNamePlaceholder: toString,
  middleNameRequired: toBoolean,
  lastNamePlaceholder: toString,
  lastNameRequired: toBoolean,
  prefixPlaceholder: toString,
  prefixRequired: toBoolean,
  prefixOptions: parseFieldOptions,
});

// ----------------------------------------------------------------------------------------------------
// --- Extracted sanitised types ---

type SanitiserReturnMap = SanitiserTypes<typeof sanitiseFormieField, 'ReturnMap'>;

export type SanitisedField<T extends FieldTypename = FieldTypename> = SanitiserReturnMap[T];
export type Field<T extends FieldTypename = FieldTypename> = SanitisedField<T> & {
  _fieldMeta?: FieldMeta;
  handle: string;
};

export type FieldMeta = {
  index: number;
  first: boolean;
  firstOfType: boolean;
  nthOfType: number;
  last: boolean;
  previousField?: Field;
};

// ----------------------------------------------------------------------------------------------------

export type FieldComponentProps<T extends FieldTypename, P = NoProps> = {
  field: Field<T>;
  enabled?: boolean;
} & P;

/**
 * Create a {@link Field} consumer React Component by providing
 * the `__typename` of `FieldType` as T
 */
export const createField = <T extends FieldTypename = FieldTypename, P = NoProps, R = JSX.Element>(
  fn: (props: FieldComponentProps<T, P>) => R
) => fn;

export const sanitiseFields = (maybeFields: MaybeArrayOf<RawElements>) => {
  return sanitiseFormieField.many(maybeFields) as SanitisedField[];
};

export const parseSanitisedFields = (sanitisedFields: SanitisedField[]): Field[] => {
  const nthOfTypeCount: Record<string, number> = {};

  return sanitisedFields.map((v, i): Field => {
    const type = v.__typename;

    nthOfTypeCount[type] = type in nthOfTypeCount ? nthOfTypeCount[type] + 1 : 0;
    return deleteUndefined({
      ...v,
      _fieldMeta: {
        nthOfType: nthOfTypeCount[type],
        index: i,
        first: i === 0,
        firstOfType: !!sanitisedFields.find((x, j) => j < i && x.__typename === v.__typename),
        last: i === sanitisedFields.length - 1,
        previousField: sanitisedFields[i - 1],
      },
    });
  });
};

export const parseFields = (maybeFields: MaybeArrayOf<RawElements>): Field[] => {
  return parseSanitisedFields(sanitiseFields(maybeFields));
};

// ----------------------------------------------------------------------------------------------------
// --- Type guards ---

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFieldType = <T extends FieldTypename>(x: any, typename: T): x is Field<T> => {
  return !!x && x.__typename === typename;
};

// ----------------------------------------------------------------------------------------------------
// ---- Form ----

export const parseFormieForm = (data: MaybeArrayOf<FormieFormFragment>) => {
  const form = firstNonNullable(data);

  if (!form) return null;

  const { formFields = [], ...rest } = form;

  return {
    ...rest,
    formFields: parseFields(formFields),
  };
};

export type ParsedForm = NonNullable<ReturnType<typeof parseFormieForm>>;
