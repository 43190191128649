import { motion } from 'framer-motion';
import { svgDraw } from './animations';
import { createShape, ShapeProps } from './Shape';

export const DiamondOutline = createShape(({ className, strokeWidth, delay = 0 }: ShapeProps) => (
  <motion.svg
    className={className}
    initial="hidden"
    animate="visible"
    width="266"
    height="79"
    viewBox="0 0 266 79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <motion.path
      variants={svgDraw}
      custom={delay}
      d="M1 2.26562L132.992 77.0653L264.999 2.26567"
      stroke="currentColor"
      strokeWidth={strokeWidth}
    />
  </motion.svg>
));
