import { BoxProps } from '@component/Box';
import Shim, { ShimProps } from '@component/Shim';
import { ImageEntry } from '@lib/api/fragments/image';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import NextImage, { ImageProps as NextImageProps } from 'next/future/image';
import * as styles from './Image.css';
import { ResponsiveImageTransform, useImageTransform } from './useImageTransform';

export type ImageProps<T extends TagName = 'img'> = PropsOverride<
  NextImageProps,
  Partial<ImageEntry> & {
    ShimProps?: ShimProps<T>;
    ratio?: BoxProps['ratio'];
    rounded?: boolean;
    transform?: ResponsiveImageTransform;
  } & styles.ImageVariants
>;

const Image = <T extends TagName>(props: ImageProps<T>) => {
  const {
    ratio,
    ShimProps: maybeShimProps,
    fixedRatio: maybeFixedRatio,
    title,
    contain,
    rounded,
    mimeType: _mimeType,
    noCrop: _noCrop,
    transform,
    transforms: _transforms,
    ...rest
  } = props;

  const { url: src, ...responsiveValues } = useImageTransform(props, transform) ?? {};

  /**
   * Create the Shim
   */
  const ShimProps: ShimProps<T> | undefined =
    maybeShimProps || ratio || rounded
      ? (mergePropsClassName({ ratio, rounded, ...maybeShimProps }, styles.shim) as ShimProps<T>)
      : undefined;

  const fixedRatio = maybeFixedRatio ?? !!ShimProps;

  const img = !src ? (
    <></>
  ) : (
    <NextImage
      {...{
        'aria-label': title,
        ...mergePropsClassName(
          rest,
          styles.root({
            fixedRatio,
            contain,
          })
        ),
        src,
        ...responsiveValues,
      }}
    />
  );

  if (ShimProps) return <Shim {...ShimProps}>{img}</Shim>;

  return img;
};

Image.styles = styles;

export default Image;
