import Box, { BoxProps } from '@component/Box';
import { mergeProps } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';
import * as styles from './Logo.css';

export type LogoProps = BoxProps<
  'a',
  {
    homeLink?: boolean;
    href?: string;
  }
>;

const Logo = ({ homeLink, href = homeLink ? '/' : undefined, ...props }: LogoProps) => {
  const t = useTranslations('common');

  const label = href ? t('homePage') : undefined;

  const inner = (
    <Box component="a" aria-label={label} {...mergeProps(props, { className: styles.root })}>
      <Image
        loading="eager"
        alt="logo"
        width="243"
        height="47"
        src="/clearview-logo.svg"
        className={styles.image}
      />
    </Box>
  );

  return href ? (
    <Link href={href} passHref>
      {inner}
    </Link>
  ) : (
    inner
  );
};

export default Logo;
