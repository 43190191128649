/* eslint-disable @typescript-eslint/no-explicit-any */
import { useGlobals } from '@lib/store';
import React from 'react';
import { initialised as YOURIR_initialised, load as YOURIR_load } from 'yourir-client';
import createStore, { StoreApi } from 'zustand';
import createContext from 'zustand/context';

export type YourIR = Record<string, (...args: any[]) => unknown>;

export type YourIRContext = {
  yourIR: YourIR | null;
  loaded: boolean;
  ready: boolean;
  handleLoad: () => void;
  // load: (yourIR: YourIR) => void
};

export const { Provider: YourIRProvider, useStore: useYourIR } =
  createContext<StoreApi<YourIRContext>>();

export const createYourIRStore = () =>
  createStore<YourIRContext>((set) => ({
    yourIR: null,
    loaded: false,
    ready: false,
    handleLoad: () => {
      // const ready = YOURIR_initialised();

      set({ loaded: true, ready: YOURIR_initialised() });

      const maybeYourIR: YourIR | null =
        'yourir' in window ? window['yourir' as keyof typeof window] ?? null : null;

      if (maybeYourIR) set({ yourIR: maybeYourIR });
    },
  }));

export const YourIRScriptLoader = () => {
  const appID = useGlobals('globalYourIr')?.apiKey;

  const { handleLoad, loaded: _loaded, ready: _ready } = useYourIR();

  React.useEffect(() => {
    const loaded =
      appID &&
      YOURIR_load({
        quiet: true,
        appID,
        isReady: (v: any) => {
          console.log({ isReady: v });
        },
      });
    if (loaded) handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appID]);

  React.useEffect(() => {
    const loadedClassName = 'yourir-loaded';

    const checkLoaded = () => document.body.classList.contains(loadedClassName);

    const callback: MutationCallback = (mutationList, observer) => {
      mutationList.forEach(function (mutation) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class' && checkLoaded()) {
          handleLoad();
          observer.disconnect();
        }
      });
    };

    const observer = new MutationObserver(callback);
    observer.observe(document.body, { attributes: true });

    return () => observer.disconnect();
  }, [handleLoad]);

  return (
    <></>
    // <>{hydrated && <Script src={`https://yourir_info/${yourIrAppId}.js`} onLoad={handleLoad} />}</>
  );
};

export type YourIRProps = React.PropsWithChildren;

export const YourIR = ({ children }: YourIRProps) => {
  return (
    <YourIRProvider createStore={createYourIRStore}>
      <YourIRScriptLoader />
      {children}
    </YourIRProvider>
  );
};
