import ErrorBoundary from '@component/ErrorBoundary';
import { PropsWithChildren } from 'react';

import { YourIR } from './useYourIR';

export type YourIrWidgetProps = PropsWithChildren<{
  code?: string;
  id?: string;
}>;

const YourIrWidget = ({ children, code, id }: YourIrWidgetProps) => {
  return (
    <ErrorBoundary>
      <YourIR>
        <div
          {...{
            id,
            ['data-yourir']: code,
          }}>
          {children}
        </div>
      </YourIR>
    </ErrorBoundary>
  );
};

export default YourIrWidget;
