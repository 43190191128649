import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import createState from 'zustand';

export interface BodyClassesState {
  classNames: string[];
  setClassNames: (value: string[]) => void;
}

export const useBodyClassList = createState<BodyClassesState>((set) => ({
  classNames: [],
  setClassNames: (newClassNames) => {
    const body = document.querySelector('body');
    if (!body) return;

    set(({ classNames }) => {
      /** Remove */
      makeNonNullableArray(classNames).forEach((item) => body.classList.remove(item));

      /** Add */
      makeNonNullableArray(newClassNames).forEach((item) => body.classList.add(item));

      return {
        classNames: newClassNames,
      };
    });
  },
}));
