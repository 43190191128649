import IconButton, { IconButtonProps } from '@component/IconButton';
import { usePageTitle } from '@component/PageHeader';
import { NavMenuItem } from '@lib/api';
import { useNavMenu, useView } from '@lib/store';

import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Share from '@mui/icons-material/Share';
import Twitter from '@mui/icons-material/Twitter';
import YouTube from '@mui/icons-material/YouTube';

import { Stack, StackProps } from '@mui/material';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'next-share';
// import Link from 'next/link';

const SocialIcon = ({ name }: { name: string }) => {
  switch (name.toLowerCase()) {
    case 'facebook':
      return <Facebook />;
    case 'instagram':
      return <Instagram />;
    case 'linkedin':
      return <LinkedIn />;
    case 'twitter':
      return <Twitter />;
    case 'youtube':
      return <YouTube />;

    default:
      return <Share />;
  }
};

export type SocialButtonsProps = StackProps & {
  IconButtonProps?: IconButtonProps;
  items?: NavMenuItem[];
  size?: 'small' | 'normal';
};

export const SocialButtons = ({
  size = 'normal',
  IconButtonProps = {},
  items,
  ...props
}: SocialButtonsProps) => {
  // const secondary = useTheme().palette.secondary;

  const { sx: customButtonSx } = IconButtonProps;

  IconButtonProps.sx = {
    ...(size === 'small'
      ? {
          fontSize: '20px',
          padding: '6px',
        }
      : {
          fontSize: '24px',
          padding: '8px',
        }),
    ...customButtonSx,
  };

  return items?.length ? (
    <Stack direction="row" spacing={1} {...props}>
      {items.map((item, i) => (
        // <Link key={i} href={item.href ?? '#'} target="_blank">
        <IconButton
          key={i}
          href={item.href ?? '#'}
          target="_blank"
          color="secondary"
          variant="contained"
          size="large"
          {...IconButtonProps}>
          <SocialIcon name={item.title} />
        </IconButton>
        // </Link>
      ))}
    </Stack>
  ) : (
    <></>
  );
};

export const ShareLinks = () => {
  const view = useView();
  const title = usePageTitle();

  if (!view || !view.url) return <></>;

  const defaults = {
    url: view.url,
    // blankTarget: true,
  };
  const buttonProps: IconButtonProps = {
    size: 'small',
    color: 'secondary',
    variant: 'contained',
  };
  return (
    <Stack direction="row" spacing={1}>
      <TwitterShareButton {...defaults} title={title}>
        <IconButton {...buttonProps}>
          <SocialIcon name="twitter" />
        </IconButton>

        {/* <TwitterIcon size={32} round /> */}
      </TwitterShareButton>
      <LinkedinShareButton {...defaults} title={title}>
        <IconButton {...buttonProps}>
          <SocialIcon name="linkedin" />
        </IconButton>

        {/* <LinkedinIcon size={32} round /> */}
      </LinkedinShareButton>
      <FacebookShareButton {...defaults} quote={title}>
        <IconButton {...buttonProps}>
          <SocialIcon name="facebook" />
        </IconButton>

        {/* <FacebookIcon size={32} round /> */}
      </FacebookShareButton>
    </Stack>
  );
};

export type SocialLinksProps = Omit<SocialButtonsProps, 'items'>;

const SocialLinks = (props: SocialLinksProps) => {
  const { items } = useNavMenu('socialAccountsNav');

  return <SocialButtons items={items} {...props} />;
};

export default SocialLinks;
