import { AppNavigationMenuName } from '@lib/api';
import { useNavMenu } from '@lib/store';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { Box, BoxProps, Divider, List, ListProps } from '@mui/material';
import React from 'react';
import * as style from './NavMenu.css';
import { NavMenuItem, NavMenuItemProps } from './NavMenuItem';

export type NavMenuProps = BoxProps<
  'nav',
  {
    name: AppNavigationMenuName;
    separator?: JSX.Element | null;
    ListProps?: ListProps;
    ListItemProps?: Omit<NavMenuItemProps, 'item'>;
    openId?: string;
    setOpenId?: (openId: string) => void;
  }
>;

/**
 * Display an App NavMenu
 */
const NavMenu = ({
  name,
  openId,
  setOpenId,
  separator = <Divider />,
  ListProps,
  ListItemProps,
  ...props
}: NavMenuProps) => {
  const { items = [] } = useNavMenu(name);

  return (
    <Box component="nav" {...mergePropsClassName(props, style.root())}>
      <List {...ListProps}>
        {items.map((item, i) => (
          <React.Fragment key={i}>
            {!!i && separator}
            <NavMenuItem
              {...ListItemProps}
              openState={openId === undefined ? undefined : openId === item.id}
              setOpenState={openId === undefined ? undefined : (open) => {
                if (!setOpenId) return
                setOpenId(open ? item.id : '') 
              }}
              item={item}
            />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default NavMenu;
