import { keys } from '@liquorice/allsorts-craftcms-nextjs';
import { colorwayVariants, invertColors, paper } from '@theme/_styles.css';
import { Color, Colorway, ColorwayVar } from '@theme/_vars.css';
import { colorwayContract, colorways, vars } from '@theme/_vars.css';
import { StyleRule } from '@vanilla-extract/css';

/** Retrieve a specific {@link ColorwayVar} */
export const colorVar = <T extends Color>(name: T | string) => {
  return name in vars.palette.colors ? vars.palette.colors[name as T] : name;
};
/** Retrieve a specific {@link ColorwayVar} */
export const colorwayVar = <T extends ColorwayVar>(name: T) => {
  return colorwayContract[name];
};

/**
 * Retrieve the `vars` for a selected {@link ColorwayKey}
 */
export const getColorwayVars = (key: ColorwayKey | null) => ({
  [colorwayVar('contrast')]: key ? vars.palette.colorways[key].contrast : 'inherit',
  [colorwayVar('contrastTint')]: key ? vars.palette.colorways[key].contrastTint : 'inherit',
  [colorwayVar('main')]: key ? vars.palette.colorways[key].main : 'inherit',
  [colorwayVar('mainTint')]: key ? vars.palette.colorways[key].mainTint : 'inherit',
  [colorwayVar('mode')]: key ? vars.palette.colorways[key].mode : 'inherit',
});

export const getColorwayInvertedVars = (key: ColorwayKey | null) => ({
  [colorwayVar('contrast')]: key ? vars.palette.colorways[key].main : 'inherit',
  [colorwayVar('contrastTint')]: key ? vars.palette.colorways[key].mainTint : 'inherit',
  [colorwayVar('main')]: key ? vars.palette.colorways[key].contrast : 'inherit',
  [colorwayVar('mainTint')]: key ? vars.palette.colorways[key].contrastTint : 'inherit',
  [colorwayVar('mode')]: key ? vars.palette.colorways[key].mode : 'inherit',
});

export type PartialColorway = Exact<
  Partial<Record<Exclude<ColorwayVar, 'mode'>, Color>> & Pick<Colorway, 'mode'>
>;

export const setColorwayVars = (settings: PartialColorway) => ({
  ...keys(settings).reduce((results, key) => {
    return {
      [colorwayVar('mode')]: settings[key],
      ...results,
    };
  }, {} as Props),
  [colorwayVar('mode')]: settings.mode,
});

export const getColorwayPaperStyle = (tint?: boolean): StyleRule => ({
  backgroundColor: colorwayVar(tint ? 'contrastTint' : 'contrast'),
});

type ColorwayVariants = typeof colorways;
export type ColorwayKey = keyof ColorwayVariants;

export const withColorway = (key: ColorwayKey | null, invert?: boolean): string[] => {
  const result: string[] = [];

  if (invert) result.push(invertColors);

  if (key) result.push(colorwayVariants[key]);

  return result;
};

export const withColorwayPaper = (key: ColorwayKey | null, invert?: boolean): string[] => [
  ...withColorway(key, invert),
  paper,
];
