import { toString } from '@liquorice/allsorts-craftcms-nextjs';
import { parseUri } from '@liquorice/allsorts-craftcms-nextjs';
import { gql } from 'graphql-request';
import client from './client';
import { CommonLinkFragment } from './__generated__/getCommonLinks.generated';
import { getSdk } from './__generated__/getCommonLinks.generated';

// ---------------------------------------------------------------------------------------------- //
// ---- Common Links ----

gql`
  fragment commonLink on EntryInterface {
    id
    uri
    title
  }
  query commonLinks {
    home: entry(uri: "__home__") {
      ...commonLink
    }
    search: entry(section: "search") {
      ...commonLink
    }
    articleIndex: entry(section: "articleIndex") {
      ...commonLink
    }
    caseStudyIndex: entry(section: "caseStudyIndex") {
      ...commonLink
    }
    insightIndex: entry(section: "insightIndex") {
      ...commonLink
    }
  }
`;

export const parseCommonLink = (data: Maybe<CommonLinkFragment>) => {
  return {
    id: toString(data?.id),
    title: toString(data?.title),
    uri: parseUri(data?.uri),
  };
};

export const getCommonLinks = async () => {
  const sdk = getSdk(client);
  const commonLinks = (await sdk.commonLinks()) ?? {};

  return {
    home: parseCommonLink(commonLinks.home),
    search: parseCommonLink(commonLinks.search),
    articleIndex: parseCommonLink(commonLinks.articleIndex),
    caseStudyIndex: parseCommonLink(commonLinks.caseStudyIndex),
    insightIndex: parseCommonLink(commonLinks.insightIndex)
  };
};

export type CommonLinks = ReturnOrPromiseType<typeof getCommonLinks>;
export type CommonLinkId = keyof CommonLinks;
