import { MuiComponentOverrides } from '.';

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

const MuiTextFieldOverrides: MuiComponentOverrides<'MuiTextField', 'MuiTextField'> = {
  defaultProps: {
    variant: 'outlined',
    fullWidth: true,
  },
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...(ownerState.variant === 'outlined' && {
        backgroundColor: '#FFF',
      }),
      // ...(ownerState.size === 'large' && {
      // padding: 4,
      // }),
    }),

    // root: ({ ownerState }) => ({
    // })
  },
  // sx: {
  //   color: 'currentcolor',
  //   borderColor: 'currentcolor',
  //   ':hover': {
  //     ':not(.Mui-disabled)': {
  //       '::before': {
  //           borderBottomColor: 'currentcolor'
  //       }
  //     }
  //   }

  // },
  // InputLabelProps: {
  //   sx: {
  //     color: 'currentcolor'
  //   }
  // },
  // InputProps: {
  //   sx: {
  //     color: 'currentcolor',
  //     borderColor: 'currentcolor',
  //     '::before': {
  //       borderBottomColor: 'currentcolor'
  //     }
  //   }
  // }
};

export default MuiTextFieldOverrides;
