import * as Types from '../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type ListStaticPathsQueryVariables = Types.Exact<{
  entrySection?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  categoryGroup?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type ListStaticPathsQuery = { __typename?: 'Query', categories?: Array<{ __typename: 'articleCategory_Category', uri?: string | null } | { __typename: 'caseStudyCategory_Category', uri?: string | null } | { __typename: 'insightCategory_Category', uri?: string | null } | null> | null, entries?: Array<{ __typename: 'articleIndex_articleIndex_Entry', uri?: string | null } | { __typename: 'article_default_Entry', uri?: string | null } | { __typename: 'caseStudyIndex_caseStudyIndex_Entry', uri?: string | null } | { __typename: 'caseStudy_default_Entry', uri?: string | null } | { __typename: 'graph_default_Entry', uri?: string | null } | { __typename: 'infographic_default_Entry', uri?: string | null } | { __typename: 'insightIndex_insightIndex_Entry', uri?: string | null } | { __typename: 'insight_default_Entry', uri?: string | null } | { __typename: 'notice_default_Entry', uri?: string | null } | { __typename: 'page_default_Entry', uri?: string | null } | { __typename: 'page_overview_Entry', uri?: string | null } | { __typename: 'profile_default_Entry', uri?: string | null } | { __typename: 'search_search_Entry', uri?: string | null } | null> | null };


export const ListStaticPathsDocument = gql`
    query listStaticPaths($entrySection: [String] = "", $categoryGroup: [String] = "") {
  categories(group: $categoryGroup, limit: 1000) {
    __typename
    uri
  }
  entries(section: $entrySection, limit: 1000) {
    __typename
    uri
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    listStaticPaths(variables?: ListStaticPathsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ListStaticPathsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ListStaticPathsQuery>(ListStaticPathsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'listStaticPaths', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;