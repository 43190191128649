import { motion } from 'framer-motion';
import { svgDraw } from './animations';
import { createShape } from './Shape';

export const Arrow = createShape<{
  arrowWidth?: number;
  arrowHeight?: number;
  curve?: number;
  flipX?: boolean;
  flipY?: boolean;
  rotate?: number;
}>(({ className, strokeWidth, delay = 0, shapeProps }) => {
  const {
    arrowWidth = 100,
    arrowHeight = 100,
    curve = 0.6,
    // flipX = false,
    // flipY = false,
    // rotate = 0,
  } = shapeProps ?? {};

  const arrowEndX = arrowWidth;
  const arrowEndY = arrowHeight;
  const arrowEndCoords = `${arrowEndX} ${arrowEndY}`;

  const curvePoint1 = `0 ${arrowEndY * curve}`;
  const curvePoint2 = `${arrowEndX - arrowEndX * curve} ${arrowEndY}`;

  return (
    <motion.svg
      key={4}
      // style={{
      // transform: `rotate(${rotate * 360}) scaleX(${flipX ? -1 : 0}) scaleY(${flipY ? -1 : 0})`,
      // }}
      className={className}
      overflow="visible"
      initial="hidden"
      animate="visibleFast"
      // width={100}
      // height={100}
      viewBox={`0 0 ${arrowEndCoords}`}
      fill="red"
      stroke="blue"
      strokeLinecap="round"
      xmlns="http://www.w3.org/2000/svg">
      <motion.path
        variants={svgDraw}
        custom={delay}
        stroke="currentColor"
        strokeWidth={strokeWidth}
        d={`M0 0 C${curvePoint1}, ${curvePoint2}, ${arrowEndCoords}`}
        fill="none"
      />
      <motion.path
        variants={svgDraw}
        custom={delay + 0.5}
        stroke="currentColor"
        strokeWidth={strokeWidth}
        d={`M${arrowEndCoords} l-10 -8`}
        fill="none"
      />
      <motion.path
        variants={svgDraw}
        custom={delay + 0.8}
        stroke="currentColor"
        strokeWidth={strokeWidth}
        d={`M${arrowEndCoords} l-10 7`}
        fill="none"
      />
      {}
    </motion.svg>
  );
});
